import ApiConstants from '../Constants/ApiConstants';
import AppManager from './AppManager';

export const PAGE_ROUTES = {
  DASHBOARD: '/',
  PRODUCTS: '/products',
  CONTACTS: '/contacts',
  CONTACT_WITH_ID: '/contacts/:id',
  QUOTES: '/quotes',
  QUOTE_WITH_ID: '/quotes/:id',
  JOB_CARD_SUMMARY: '/mrp/reports/job-card-summary',
  RFQ: '/rfq',
  RFQ_WITH_ID: '/rfq/:id',
  PURCHASE_INWARD_QUOTATION: '/purchase-inward-quotation',
  QUOTE_WITH_ID_AND_CONTACT: '/quotes/:id/contact/:contactId',
  QUOTE_WITH_ID_AND_ACTION: '/quotes/:id/:action',
  INVOICES: '/invoices',
  INVOICE_WITH_ID: '/invoices/:id',
  INVOICE_WITH_ID_AND_CONTACT: '/invoices/:id/contact/:contactId',
  ORDERS: '/purchase-orders',
  REQUISITIONS: '/requisitions',
  FIXED_ASSET: '/fixed-asset',
  FIXED_ASSET_WITH_ID: '/fixed-asset/:id',
  ASSET_GROUP: '/asset-group',
  ASSET_GROUP_WITH_ID: '/asset-group/:id',
  DEPRECIATION_SCHEDULE: '/depreciation-schedule',
  DEPRECIATION_SCHEDULE_WITH_ID: '/depreciation-schedule/:id',
  ORDER_WITH_ID: '/purchase-orders/:id',
  REQUISITION_WITH_ID: '/requisitions/:id',
  ORDER_WITH_ID_AND_CONTACT: '/purchase-orders/:id/contact/:contactId',
  BILLS: '/bills',
  BILL_WITH_ID: '/bills/:id',
  BILL_WITH_ID_AND_CONTACT: '/bills/:id/contact/:contactId',
  DRAFTS: '/drafts',
  MONEY: '/kredx/solutions',
  PUBLIC_PAGE: '/public/:id',
  PUBLIC_PAGE_PATH: '/public/',
  JOURNAL_ENTRY: '/accounting/journal-entry',
  FINANCIAL_YEAL_CLOSING: '/accounting/fyclosing',
  DEBIT_NOTES: '/accounting/debitnotes',
  CHART_OF_ACCOUNTS: '/accounting/coa',
  ACCOUNTING: '/accounting',
  DEPOSIT: '/accounting/deposit',
  EXPENSE: '/accounting/expense',
  PREPARE_1099: '/prepare-1099',
  FINANCIAL_YEAR_CLOSING: '/accounting/fyclosing',
  CREDIT_NOTES: '/accounting/creditnotes',
  OPENING_BALANCE: '/accounting/openingbalance',
  WAREHOUSE: '/warehouse',
  IMPORT: '/import-portal',
  PRICE_LIST: '/products/price-list',
  PRICE_BOOK: '/products/price-book',
  BANKS: '/banks',
  APPS: '/apps',
  EWAY_BILL: '/ewaybill',
  BANK_DETAILS: '/banks/details',
  ORGANISTION_PROFILE: '/company/details',
  RULES: '/banks/rules',
  SETTINGS_AUDIT_LOG: '/settings/auditlog',
  SETTINGS_LOGS: '/settings/logs',
  SETTINGS_AUTOMATION_LOG: '/settings/automationlog',
  BANK_CREATE: '/bank/create',
  SETTINGS_IMPORT_LOG: '/settings/import/logs',
  SETTINGS_MODULES: '/settings/modules',
  SETTINGS_NEGATIVE_INVENTORY: '/settings/negative-inventory',
  SETTINGS_CUSTOM_FIELDS_LIST: '/settings/custom-fields-list',
  SETTINGS_ADVANCED_INVENTORY: '/settings/advanced-inventory',
  SETTINGS_FILTER_ACCOUNTS: '/settings/filter-accounts',
  RECONCILE_ACCOUNT: '/bank/reconciliation',
  ONBOARDING: '/onboarding',
  GETTING_STARTED: '/onboarding/getting-started',
  PRODUCT_DASHBOARD: '/product-dashboard',
  MRP_PRODUCT_DASHBOARD: '/mrp/product-dashboard',
  REPORTS: '/reports',
  REPORT_PNL: '/reports/profitandloss',
  REPORT_PNL_BY_DEPARTMENT: '/reports/pnl-by-department',
  REPORT_PNL_BY_DEPARTMENT_DETAILS: '/reports/pnl-by-department-details',
  REPORT_TRIAL_BALANCE_BY_DEPARTMENT: '/reports/trial-balance-by-department',
  REPORT_TRIAL_BALANCE_BY_DEPARTMENT_DETAILS:
    '/reports/trial-balance-by-department-details',
  BR_REPORT_PNL: '/reports/profitandloss-br',
  REPORT_PNL_DETAILS: '/reports/profitandlossDetails',
  REPORT_BS_DETAILS: '/reports/balanceSheetDetails',
  REPORT_BS: '/reports/balancesheet',
  DPL_REPORT: '/reports/dplReport',
  BR_REPORT_BS: '/reports/balancesheet-br',
  REPORT_TB: '/reports/trialbalance',
  REPORT_TBD: '/reports/trialbalancedetail',
  REPORT_SOA_CUSTOMER: '/reports/soa-customer',
  REPORT_RECONCILIATION_LOG: '/reports/reconciliation-log-report',
  REPORT_BANK_REC_SUMMARY: '/reports/bank-reconciliation-summary',
  REPORT_SOA_VENDOR: '/reports/soa-vendor',
  REPORT_GL: '/reports/generalledger',
  BR_REPORT_GL: '/reports/generalledger-br',
  REPORT_CASHFLOW: '/reports/cashflow',
  REPORT_PURCHASE_DN: `/reports/debitNotes`,
  REPORT_PURCHASE_CN: `/reports/creditNotes`,
  REPORT_SALES_INVOICE: '/reports/invoices',
  REPORT_SALES_EWB_REPORT: '/reports/ewb',
  REPORT_FULFILLMENT_REPORT: '/reports/fulfillmentReport',
  REPORT_PURCHASE_BILL_LIST: '/reports/billList',
  REPORT_PURCHASE_ORDER_LIST: '/reports/orderList',
  REPORT_SALES_ORDER_DETAILS: '/reports/salesOrderDetails',
  STOCK_MOVEMENT_REPORT: '/reports/stockMovement',
  QUOTATION_LIST_REPORT: '/reports/quotationList',
  OUTSTANDING_SALES_FULLFILLMENT_REPORT: '/reports/outstandingsalesdo',
  SALES_BY_PRODUCT: `/reports/salesByProduct`,
  PURCHASE_BY_PRODUCT: `/reports/purchaseByProduct`,
  PURCHASE_RETURN_REPORT: `/reports/purchase-return-report`,
  STOCK_VALUATION: '/reports/inventory/stock-valuation',
  TALLY_RECEIVABLE: `/reports/advanced/receivable`,
  TALLY_RECEIPT_SUMMARY: `/reports/advanced/receiptSummary`,
  TALLY_CASHFLOW: `/reports/advanced/cashFlow`,
  TALLY_DETAILED_SALES_REPORT: `/reports/advanced/detailedSalesReport`,
  TALLY_VENDOR_SOA: `/reports/advanced/vendorSOA`,
  TALLY_DETAILED_PURCHASE_REPORT: `/reports/advanced/detailed-purchase-report`,
  TALLY_CUSTOMER_SOA: `/reports/advanced/customer-statement-of-account`,
  TALLY_DAY_BOOK: `/reports/advanced/dayBook`,
  TALLY_STOCK_SUMMARY: `/reports/advanced/stockSummary`,
  TALLY_STOCK_SUMMARY2: `/reports/advanced/stockSummary2`,
  TALLY_STOCK_SUMMARY3: `/reports/advanced/stockSummary3`,
  TALLY_SALES_REGISTER_SUMMARY: `/reports/advanced/sales-register-summary`,
  CONSOLIDATED_FINANCIAL_REPORT: '/reports/financial/consolidated-report',
  CONSOLIDATED_FINANCIAL_REPORT_LIST:
    '/reports/financial/consolidated-report-list',
  DOCUMENTS_EMAILED: `/reports/documents-emailed`,
  SALES_RETURN_REPORT: `/reports/sales-return-report`,
  SALES_BY_CONTACT: '/reports/salesByContact',
  PURCHASE_BY_CONTACT: '/reports/purchaseByContact',
  RECEIPT_LIST: '/reports/ReceiptList',
  PAYMENT_LIST: '/reports/PaymentList',
  REPORT_OPGR: '/reports/outstandingpurchasegrn/outstandingpurchasegrn',
  SALES_BY_CLASS: `/reports/custom/pronto-dashboard`,
  SALES_BY_CLASS_DETAIL: '/reports/custom/pronto-dashboard-detail',
  JOB_BRIEF_DASHBOARD: '/reports/custom/job-brief-dashboard',
  REPORT_ITEM_LANDED_COST: '/reports/ItemLandedCost',
  STOCK_WAREHOUSE_REPORT:
    '/reports/stockavailabilitybywarehouse/stockavailabilitybywarehouse',
  REORDER_LEVEL_REPORT: '/reports/inventory/reorderLevelReport',
  STOCK_STATUS_REPORT: '/reports/inventory/stockStatusReport',
  RESERVED_STOCK_REPORT: '/reports/reservestock/reservestock',
  BATCH_STATUS: '/reports/BatchStatus',
  STOCK_LEDGER_REPORT: '/reports/stockledger/stockledger',
  BOM_ASSEMBLY: '/reports/bomAssembly',
  AGED_RECEIVABLES_REPORT: '/reports/agedReceivables',
  RATE_ANALYSIS_REPORT: '/reports/rateAnalysis',
  REV_REC_JE: '/reports/revRecJe',
  RECLASSIFICATION_JE: '/reports/reClassificationJe',
  RECLASSIFICATION_JE_WITH_PROCESSED_ID:
    '/reports/reClassificationJe/:processedId',

  AGED_PAYABLES_REPORT: '/reports/agedPayables',
  PAYMENT_MILESTONE_TRACKER: '/reports/paymentmilestonetracker',
  GOODS_RECEIVED_NOTES: '/reports/goodsReceivedNotes',
  PURCHASE_ORDER_DETAIL_REPORT: '/reports/purchaseOrderDetail',
  GSTR1: '/reports/india-gst/gstr1',
  SSTO2_REPORT_MY: '/reports/sst02report/sst02report',
  SALES_TAX_US: `/reports/salesTaxReport`,
  SALES_TAX_AGENCY: `/reports/salesTaxReportAgency`,
  GSTR_DETAILS: '/reports/india-gst/gstr1/B2B',
  GSTR_DETAILS_CDNR: '/reports/india-gst/gstr1/CDNR',
  GSTR_EXEMPT: '/reports/india-gst/gstr1/EXEMPT',
  GSTR_EXEMPT_DETAILS: '/reports/india-gst/gstr1/EXEMPT/details',
  GSTR_HSN: '/reports/india-gst/gstr1/hsn',
  GSTR_B2CL: '/reports/india-gst/gstr1/b2cl',
  GSTR_B2CS: '/reports/india-gst/gstr1/b2cs',
  GSTR_CDNUR: '/reports/india-gst/gstr1/CDNUR',
  GSTR_EXPORT_INVOICE: '/reports/india-gst/gstr1/EXP',
  GSTR_AT: '/reports/india-gst/gstr1/AT',
  STOCK_AGEING: '/reports/stockageingreport/stockageingreport',
  TDS_CUSTOMER: '/reports/india-gst/tds-customer-report',
  FAILED_INVOICES: '/reports/india-gst/failuree-invoices',
  GST5FORM_EDIT: '/reports/tax/gst5form-edit',
  GST5FORM_VIEW: '/reports/tax/gst5form-details',
  GST7FORM_EDIT: '/reports/tax/gst7form-edit',
  GST7FORM_VIEW: '/reports/tax/gst7form-details',
  GST_REPORT_LOG: '/reports/sg-gst/gst-forms',
  GSTR3B_REPORT: '/reports/india-gst/gstr3',
  GSTR2B_REPORT: '/reports/india-gst/gstr2B',
  GSTR2B_REPORT_NEW: '/reports/india-gst/gstr2B_new',
  GSTR2B_DOWNLOAD_REPORT: '/reports/india-gst/gstr2B/download',
  GSTR2B_VIEW_SUMMARY_REPORT: '/reports/india-gst/gstr2B/view_summary',
  GSTR2B_LINK_DOCUMENT: '/reports/india-gst/gstr2B/link-doc',
  GST_THREE_ONE_A: '/reports/india-gst/gstr3/THREE_1_A',
  GST_THREE_ONE_B: '/reports/india-gst/gstr3/THREE_1_B',
  GST_THREE_ONE_D: '/reports/india-gst/gstr3/THREE_1_D',
  GST_THREE_TWO_A: '/reports/india-gst/gstr3/THREE_2_A',
  GST_THREE_TWO_B: '/reports/india-gst/gstr3/THREE_2_B',
  GST_FOUR_A_ONE: '/reports/india-gst/gstr3/FOUR_A_1',
  GST_FOUR_A_TWO: '/reports/india-gst/gstr3/FOUR_A_2',
  GST_FOUR_A_THREE: '/reports/india-gst/gstr3/FOUR_A_3',
  GST_FOUR_A_FIVE: '/reports/india-gst/gstr3/FOUR_A_5',
  GST_FOUR_B_ONE: '/reports/india-gst/gstr3/FOUR_B_1',
  GST_FOUR_D_ONE: '/reports/india-gst/gstr3/FOUR_D_1',
  GST_FOUR_D_TWO: '/reports/india-gst/gstr3/FOUR_D_2',
  GST_FIVE_TWO: '/reports/india-gst/gstr3/FIVE_2',
  GST_FIVE_THREE: '/reports/india-gst/gstr3/FIVE_3',
  GST_FIVE_FOUR: '/reports/india-gst/gstr3/FIVE_4',
  GST_FOUR_B_TWO: '/reports/india-gst/gstr3/FOUR_B_2',
  TDS_DETAILS: '/reports/india-gst/tds-details',
  GST_RETURN: '/reports/india-gst/gst-summary',
  TDS_NOT_DED: '/reports/india-gst/pending-tds',
  VAT_RETURN: '/reports/tax/vat-return-log',
  VAT_RETURN_DETAIL: '/reports/tax/vat-return-details',
  SALES_TAX_RETURN: '/reports/sst02report/salestaxreturn',
  PICK_PACK_SHIP: `/pps/pick-pack-ship`,
  SERVICE_TAX_RETURN: `/reports/sst02report/servicetaxreturn`,
  AUTOMATIONS: '/automation',
  AUTOMATION_BUILDER: '/automation-builder',
  GSTR1_RETURN_FILE: '/reports/india-gst/gstr1-e-Filing',
  CUSTOM_REPORT_BUILDER: '/reports/custom-report-builder',
  PRINT_CHECKS: '/checks',
  BK_CLIENT: '/book-keeper/client',
  BK_TEAM: '/book-keeper/team',
  BUDGETING: '/budgeting',
  SALES_ORDERS: '/sales-orders',
  SALES_ORDER_WITH_ID: '/sales-orders/:id',
  SALES_ORDER_WITH_ID_AND_CONTACT: '/sales-orders/:id/contact/:contactId',
  BATCH_SERIAL_LADGER_REPORT: '/reports/batch-serial-ledger',
  QUALITY_CHECK: '/quality-check',
  MRP_ROUTE_PREFIX: '/mrp/',
  MRP_OPERATIONS: '/mrp/operations',
  MRP_MACHINE_TOOL_MASTER: '/mrp/machine-tool-master',
  MRP_ASSET_MAINTENANCE_SCHEDULE: '/mrp/machine-maintenance-schedule',
  MRP_MACHINE_SCHEDULING: '/mrp/machine/scheduling',
  MRP_WORK_STATIONS: '/mrp/work-stations',
  MRP_HOLIDAY_LIST: '/mrp/holiday-list',
  MRP_FINISHED_GOODS: '/mrp/finished-goods',
  MRP_RAW_MATERIAL: '/mrp/raw-material',
  MRP_BILL_OF_MATERIAL: '/mrp/bill-of-material',
  MRP_WORK_ORDER: '/mrp/workorder',
  MRP_PRODUCTION_PLAN: '/mrp/production-plan',
  MRP_DASHBOARD: '/mrp/dashboard',
  MRP_JOB_CARD: '/mrp/job-card',
  MRP_FORECASTING: '/mrp/forecasting',
  MRP_FORECASTING_DETAIL: '/mrp/forecasting/details',
  MRP_FORECASTING_DETAIL_BY_ID: '/mrp/forecasting/details/:id',
  MRP_MASTER_FORECAST: '/mrp/master-forecast',
  MRP_MASTER_FORECAST_CREATE: '/mrp/master-forecast/add',
  MRP_MASTER_FORECASTING_BY_ID: '/mrp/master-forecast',
  MRP_MASTER_FORECASTING_EDIT_BY_ID: '/mrp/master-forecast/:id',
  MRP_MASTER_FORECASTING_DETAIL: '/mrp/master-forecast/details',
  MRP_MASTER_FORECASTING_DETAIL_BY_ID: '/mrp/master-forecast/details/:id',
  MRP_JOB_CARD_BY_WORK_ORDER_ID: '/mrp/job-card/:workOrderId',
  MRP_PRODUCTS: '/mrp/products',
  MRP_LOCATIONS: '/mrp/location',
  MRP_OPERATOR_LIST: '/mrp/operator-list',
  MRP_WORK_OUT: '/mrp/job-work-out',
  MRP_WORK_OUT_WITH_ID: '/mrp/job-work-out/:id',
  MRP_REPORTS_LIST: '/mrp/reports',
  MRP_ROUTING_TEMPLATES: '/mrp/routing-templates',
  MRP_BOM_EXPLOSION: '/mrp/bom-explosion',
  MRP_SCHEDULING: '/mrp/scheduling',
  MRP_REPORT_PRODUCTION_SUMMARY: '/mrp/reports/production-summary',
  MRP_REPORT_FG_COMPONENT_TRACKER: '/mrp/reports/fg-component-tracker',
  MRP_REPORT_SHORTFALL_SUMMARY: '/mrp/reports/shortfall-summary',
  MRP_REPORT_SHORTFALL_DETAIL: '/mrp/reports/shortfall-detail',
  MRP_REPORT_BIN_ALLOCATION_REPORT: '/mrp/reports/bin-allocation',
  MRP_REPORT_PRODUCTION: '/mrp/reports/production',
  MRP_REPORT_PRODUCTION_COSTING: '/mrp/reports/production-costing',
  MRP_REPORT_YIELD_SUMMARY: '/mrp/reports/yield-summary',
  MRP_REPORT_YIELD_SUMMARY_DETAILED: '/mrp/reports/yield-summary-detailed',
  MRP_REPORT_DAILY_CONSUMPTION:
    '/mrp/reports/daily-consumption-detailed-report',
  MRP_REPORT_DAILY_CONSUMPTION_SUMMARY:
    '/mrp/reports/daily-consumption-summary-report',
  MRP_OPERATIONWISE_SUMMARY_REPORT: `/mrp/reports/operationwise-summary`,
  MRP_REPORT_WOWISE_OPERATION_DETAILS_REPORT: `/mrp/reports/WOwise-operation-details`,
  MRP_REPORT_RESTOCKING_LEVELS_REPORT: `/mrp/reports/restocking-levels-report`,
  MRP_REPORT_JOB_WORKOUT_REPORT: `/mrp/reports/job-workout-report`,
  MRP_REPORT_SO_TO_WO_REPORT: `/mrp/reports/sotowo`,
  STOCK_ADJUSTMENT: '/stock-management/stock-adjustment',
  STOCK_REQUEST: '/stock-management/stock-request',
  STOCK_TRANSFER: '/stock-management/stock-transfer',
  STOCK_ISSUE: '/stock-management/stock-issue',
  TABLET_DASHBOARD: '/tablet-dashboard',
  REVALUATION: '/revaluation',
  MULTI_COMPANY_CONSOLIDATED_FINANCIAL_REPORT:
    '/reports/financial/multi-company-consolidated-report',
  MULTI_COMPANY_CONSOLIDATED_FINANCIAL_REPORT_LIST:
    '/reports/financial/multi-company-consolidated-report-list',
  INTER_COMPANY_TRANSACTION_REPORT:
    '/reports/financial/inter-company-transaction-report',
  SETTINGS_REVENUE_CATEGORY: '/settings/revenue-category',
  SETTINGS_FAIR_VALUE: '/settings/fair-value-formulae',
  SETTINGS_REV_REC: '/settings/revenue-recognition-rules',
  REVENUE_ARRANGEMENT: '/revenue-arrangement',
  REVENUE_ARRANGEMENT_WITH_ID: '/revenue-arrangement/:id',
  REVENUE_ARRANGEMENT_JOB: '/revenue-arrangement-job',
  REVENUE_ARRANGEMENT_JOB_WITH_ID: '/revenue-arrangement-job/:id',
  UPDATE_REVENUE_ARRANGEMENT: '/manual-revenue-arrangement',
  IRAS_CALL_BACK: '/reports/sg-gst/gst-forms',
  QUOTATION_FORM: '/public/quotation-form/:id',
  AMORTIZATION_TEMPLATES: '/amortization/templates',
  AMORTIZATION_SCHEDULES: '/amortization/schedules',
  AMORTIZATION_JE: '/amortization/je',
  EXPENSE_ALLOCATION_TEMPLATES: '/expense-allocation/templates',
  AMORTIZATION_FORECAST_REPORT: '/reports/amortization/forecast-report',
  DEFERRED_EXPENSE_WATERFALL_REPORT:
    '/reports/amortization/deferred-expense-waterfall-report',
  DEFERRED_EXPENSE_WATERFALL_DETAILS_REPORT:
    '/reports/amortization/deferred-expense-waterfall-details-report',
  DEFERRED_EXPENSE_ROLLFORWARD_SUMMARY_REPORT:
    '/reports/amortization/deferred-expense-rollforward-summary-report',
  REV_REC_WATERFALL: '/settings/rev-rec-waterfall',
  REV_REC_DEFERRED_REV_BY_ITEM: '/settings/rev-rec/Deferred-rev-by-item',
  REV_REC_DEFERRED_REV_BY_CUSTOMER:
    '/settings/rev-rec/Deferred-rev-by-customer',
  MASTER_FORECAST: '',
  QC_SUMMARY: `/reports/qualityCheck/qcsummery`,
  QC_DETAILED_REPORT: `/reports/qualityCheck/qcdetail`,
  PRODUCTWISE_QC_REPORT: `/reports/qualityCheck/productwise-qc`,
  VENDORWISE_QC_REPORT: `/reports/qualityCheck/vendorwise-qc`,
  BOM_REPORT: `/reports/bom-report`,
  VALUATION_BY_WAREHOUSE: '/reports/valuation-by-warehouse',
  SKU_WISE_ORDER_TRACKING: '/mrp/reports/sku-wise-order-tracking',
  VALUATION_BY_WAREHOUSE_DETAILS: '/reports/valuation-by-warehouse/:code',
  GOODS_RECEIPT_MASTER_LIST: '/goods-receipt-master',
  FULFILLMENT_MASTER_LIST: '/fulfillment-master',
  SECURITY_GATE_ENTRY_LIST: '/security-gate-entry',
  GOODS_RECEIPT_TOLERANCE_LEVEL_REPORT:
    '/reports/goods-receipt-tolerance-level-report',
  FULFILLMENT_TOLERANCE_LEVEL_REPORT:
    '/reports/fulfillment-tolerance-level-report',
  TOLERANCE_LEVEL_REJECTION_STOCK_REPORT:
    '/reports/tolerance-level-rejection-stock-report',
  ORDER_LEVEL_REPORT: `/reports/orderLevelReport`,
  BANK_BOOK_REPORT: '/bank-book-report',
  GENERATE_BANK_FILE_INVOICE: `/generate-bank-file-invoice`,
  GENERATE_BANK_FILE_BILL: `/generate-bank-file-bill`,
  PURCHASE_QUOTATION_COMPARISON_REPORT: `/purchase-quotation-comparison-report`,
  FORECAST_DRIVEN_PO_REPORT: `/mrp/reports/forecast-driven-purchase-order-report`
};
export default class RouteManager {
  static presenter: any = null;
  static leftMenus: any[] = [];
  static setPresenter(presenter: any) {
    this.presenter = presenter;
    // IntercomManager.trackEvent("page", { url: window.location.pathname });
  }
  static navigateToPage(
    pageRoute: string,
    param: any = null,
    module?: any,
    action?: any
  ) {
    RouteManager.presenter?.props?.history?.push(
      pageRoute + (param ? '?' + param : '')
    );
    AppManager.scrollToTop();
    // IntercomManager.trackEvent("page", { url: window.location.pathname });
  }

  static navigateToHome() {
    RouteManager.navigateToPage(PAGE_ROUTES.DASHBOARD);
  }

  static navigateToBack() {
    RouteManager.presenter?.props?.history.goBack();
  }

  static setLeftMenus(menus: any) {
    RouteManager.leftMenus = menus;
  }

  static getLeftMenus() {
    return RouteManager.leftMenus;
  }
}
export const isAccessingMRPURL = (): boolean => {
  return !!window?.location?.origin?.includes(
    ApiConstants.URL.APP_MRP_URL || ''
  );
};

import {
  DKButton,
  DKDataGrid,
  DKIcons,
  DKLabel,
  DKLine,
  DKSpinner,
  INPUT_TYPE,
  removeLoader,
  showAlert,
  showLoader
} from 'deskera-ui-library';
import { useEffect, useState } from 'react';
import ic_barcode_green from '../../../Assets/Icons/ic_barcode_green.svg';
import ic_barcode_red from '../../../Assets/Icons/ic_barcode_red.svg';
import {
  BOOKS_DATE_FORMAT,
  MODULE_TYPE,
  PAGE_SIZE,
  QTY_ROUNDOFF_PRECISION,
  WIP_CONSUMPTION_TYPES
} from '../../../Constants/Constant';
import { ADVANCE_TRACKING } from '../../../Constants/Enum';
import { useAppDispatch, useAppSelector } from '../../../Redux/Hooks';
import { fetchBatchTrackingProducts } from '../../../Redux/Slices/BatchTrackingSlice';
import { fetchSerialTrackingProducts } from '../../../Redux/Slices/SerialTrackingSlice';
import { fetchProductInventoryByID } from '../../../Redux/Slices/WarehouseProductSlice';
import { selectWarehouse } from '../../../Redux/Slices/WarehouseSlice';
import DateFormatService from '../../../Services/DateFormat';
import WorkOrderService from '../../../Services/MRP/WorkOrder';
import NumberFormatService from '../../../Services/NumberFormat';
import WarehouseService, {
  WarehouseAPIConfig
} from '../../../Services/Warehouse';
import BatchTrackingAssignment from '../../../SharedComponents/AdvancedTrackingPopup/BatchTrackingAssignment';
import SerialTrackedAssignment from '../../../SharedComponents/AdvancedTrackingPopup/SerialTrackedAssignment';
import { DynamicPopupWrapper } from '../../../SharedComponents/PopupWrapper';
import NoneTrackedAssignment from '../../../SharedComponents/WarehouseManagement/NoneTrackedAssignment';
import Utility, { deepClone } from '../../../Utility/Utility';
import { WORK_ORDER_STATUS } from '../Constants/MRPColumnConfigs';
import { WorkOrderHelper } from '../WorkOrder/WorkOrderHelper';
import ConsumeProduceHelper from '../WorkOrder/InnerComponents/ConsumptionProduction/ConsumeProduceHelper';

const ConsumptionPopup = (props: any) => {
  //common states
  const [updating, setUpdating] = useState(false);
  const [saveApiLoading, setSaveApiLoading] = useState(false);

  //consumption states
  const [consumptionColumnConfigs, setConsumptionColumnConfigs] = useState(
    ConsumeProduceHelper.ENTER_CONSUMPTION_COLUMNS?.filter((col: any) => {
      return (
        col.id !== 'wastageQty' &&
        col.id !== 'productionQty' &&
        col.id !== 'qcStatus'
      );
    })
  );
  const [consumptionLineItems, setConsumptionLineItems] = useState<any[]>([]);
  const [totalConsumptionPageCount, setTotalConsumptionPageCount] = useState(0);
  const [currentConsumptionPage, setCurrentConsumptionPage] = useState(0);

  //wastage states
  const [wastageColumnConfigs, setWastageColumnConfigs] = useState(
    ConsumeProduceHelper.ENTER_CONSUMPTION_COLUMNS?.filter(
      (col: any) =>
        col.id !== 'consumptionQty' &&
        col.id !== 'productionQty' &&
        col.id !== 'qcStatus'
    )
  );
  const [wastageLineItems, setWastageLineItems] = useState<any[]>([]);
  const [totalWastagePageCount, setTotalWastagePageCount] = useState(0);
  const [currentWastagePage, setCurrentWastagePage] = useState(0);

  //fulfillment states
  const [showSerialTracking, setShowSerialTracking] = useState(false);
  const [showBatchTracking, setShowBatchTracking] = useState(false);
  const [showMultipleWarehouse, setShowMultipleWarehouse] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>();
  const [selectedIndex, setSelectedIndex] = useState<any>(-1);
  const [isWastageFulfillmentFlow, setIsWastageFulfillmentFlow] = useState('');

  //constant
  const diableBtn =
    ConsumeProduceHelper.getRemainingQty(
      Number(props.selectedProduct.allocatedQty),
      consumptionLineItems,
      wastageLineItems
    ) === 0 || props.workOrderItems.status === WORK_ORDER_STATUS.COMPLETED;

  //selectors
  const dispatch: any = useAppDispatch();
  const warehouseData = useAppSelector(selectWarehouse);

  //effects
  useEffect(() => {
    fetchConsumptionHistoryDetails(
      getHistoryApiCallParams({
        type: WIP_CONSUMPTION_TYPES.CONSUMPTION,
        page: currentConsumptionPage,
        limit: PAGE_SIZE
      })
    );
    fetchWastageHistoryDetails(
      getHistoryApiCallParams({
        type: WIP_CONSUMPTION_TYPES.WASTAGE,
        page: currentWastagePage,
        limit: PAGE_SIZE
      })
    );

    updateConsumptionConfig();
    updateWastageConfig();
  }, []);

  //////////////////////////// Save Start ///////////////////////////////////

  const isValidated = (rows: any) => {
    let rowsCopy: any = [...rows];

    const found = rowsCopy?.find((row: any) => {
      return Utility.isEmpty(row.warehouseInventoryData);
    });

    return Utility.isEmpty(found);
  };

  const onSave = () => {
    let consumeProduceLineItems = [...consumptionLineItems];
    let wasteLineItems = [...wastageLineItems];
    try {
      consumeProduceLineItems = consumeProduceLineItems
        ?.filter((item: any) => {
          return !('id' in item);
        })
        ?.map((item: any) => {
          let warehouseInventoryDataParsed: any =
            WorkOrderHelper.parseWarehouseInventoryDataInNewFormat(
              item?.warehouseInventoryData,
              selectedItem.advancedTracking
            );

          let qty =
            item?.warehouseInventoryData?.reduce((acc: any, obj: any) => {
              return Number(acc) + Number(obj?.quantity ?? 0);
            }, 0) ?? 0;

          qty = Utility.roundingOff(qty, QTY_ROUNDOFF_PRECISION);

          return {
            costPerUnit: item?.inventoryPrice ?? 0,
            uomCostPerUnit: item?.inventoryPrice ?? 0,
            // createdDate: new Date(),
            documentDate: DateFormatService.getDateStrFromDate(
              item.documentDate,
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            ),
            productCode: item.productCode,
            quantity: qty,
            uomQuantity: qty,
            documentUOMSchemaDefinition: item?.documentUOMSchemaDefinition,
            stockUom: item?.stockUom,
            type: WIP_CONSUMPTION_TYPES.CONSUMPTION,
            warehouseInventoryData: warehouseInventoryDataParsed?.map(
              (whItem: any) => {
                return {
                  ...whItem,
                  advancedTrackingType: props.selectedProduct.advancedTracking,
                  qtyToFulfil: whItem?.qtyToFulfil,
                  qtyToFulfilUom: whItem?.qtyToFulfil
                };
              }
            ),
            workOrderCode: props.workOrderItems?.workOrderCode,
            workOrderId: props.workOrderItems?.id,
            substituteParentProductCode: props.selectedProduct.isSubstitute
              ? props.selectedProduct?.substituteParentProductCode
              : '',
            workOrderItemCode: props.selectedProduct?.workOrderItemCode
          };
        });

      wasteLineItems = wasteLineItems
        ?.filter((item: any) => {
          return !('id' in item);
        })
        ?.map((item: any) => {
          let warehouseInventoryDataParsed: any =
            item.warehouseInventoryData?.map((whInventoryItem: any) => {
              let { warehouse, row, rack, bin, ...newWHInventoryItem } =
                whInventoryItem;
              return {
                ...newWHInventoryItem,
                advancedTrackingType: props.selectedProduct.advancedTracking,
                qtyToFulfil: newWHInventoryItem?.qtyToFulfil,
                qtyToFulfilUom: newWHInventoryItem?.qtyToFulfil,
                advancedTrackingData:
                  newWHInventoryItem?.advancedTrackingData?.map(
                    (advData: any) => {
                      return {
                        ...advData,
                        qtyToFulfilUom: advData?.qtyToFulfil
                      };
                    }
                  )
              };
            });
          let qty =
            item?.warehouseInventoryData?.reduce((acc: any, obj: any) => {
              return Number(acc) + Number(obj?.quantity ?? 0);
            }, 0) ?? 0;

          qty = Utility.roundingOff(qty, QTY_ROUNDOFF_PRECISION);

          return {
            costPerUnit: item?.inventoryPrice ?? 0,
            uomCostPerUnit: item?.inventoryPrice ?? 0,
            // createdDate: new Date(),
            documentDate: DateFormatService.getDateStrFromDate(
              item.documentDate,
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            ),
            productCode: item.productCode,
            quantity: qty,
            uomQuantity: qty,
            documentUOMSchemaDefinition: item?.documentUOMSchemaDefinition,
            stockUom: item?.stockUom,
            type: WIP_CONSUMPTION_TYPES.WASTAGE,
            warehouseInventoryData: warehouseInventoryDataParsed,
            workOrderCode: props.workOrderItems?.workOrderCode,
            workOrderId: props.workOrderItems?.id,
            substituteParentProductCode: props.selectedProduct.isSubstitute
              ? props.selectedProduct?.substituteParentProductCode
              : '',
            workOrderItemCode: props.selectedProduct?.workOrderItemCode
          };
        });
    } catch (err) {
      /**
       * In case consumption entry not completed, Invalid data prepared
       */
      return;
    }
    consumeProduceLineItems = consumeProduceLineItems.concat(wasteLineItems);

    if (Utility.isEmpty(consumeProduceLineItems)) {
      showAlert(
        'Please note!',
        'Your information is already up to date and no further action is required..'
      );
      return;
    }

    let validate = isValidated(consumeProduceLineItems);

    if (!validate) {
      return;
    }

    setSaveApiLoading(true);
    WorkOrderService.saveConsumption(consumeProduceLineItems)
      .then((res: any) => {
        setSaveApiLoading(false);
        props.onSuccess();
      })
      .catch((err: any) => {
        setSaveApiLoading(false);
      });
  };

  //////////////////////////// Save End ///////////////////////////////////

  //////////////////////////// Component Methods Start ///////////////////////////////////

  const fetchConsumptionHistoryDetails = (config: any) => {
    setUpdating(true);
    WorkOrderService.apiConfigForConsumption = config;
    WorkOrderService.fetchConsumptionHistory()
      .then((res: any) => {
        setUpdating(false);
        let newRes = res?.content?.map((item: any) => {
          return {
            ...item,
            quantity: Utility.isNotEmpty(item?.documentUOMSchemaDefinition)
              ? item?.uomQuantity
              : item?.quantity,
            uom: props.selectedProduct.uom,
            stockUom: props.selectedProduct.stockUom,
            documentUOMSchemaDefinition:
              props?.selectedProduct?.documentUOMSchemaDefinition,
            productName: props.selectedProduct.productName,
            productCodeToDisplayOnly: props.selectedProduct.productCodeToDisplayOnly,
            advancedTracking: props.selectedProduct.advancedTracking,
            editable: false,
            documentDate: DateFormatService.getDateFromStr(
              item?.documentDate,
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            ),
            createdDate: new Date(item?.dateStamp),
            warehouseInventoryData:
              item?.warehouseInventoryData?.map((itemWHData: any) => {
                return {
                  ...itemWHData,
                  quantity: itemWHData?.uomQuantity ?? itemWHData?.quantity
                };
              }) ?? []
          };
        });
        setConsumptionLineItems(newRes ?? []);
        setTotalConsumptionPageCount(res?.totalPages ?? 5);
        setCurrentConsumptionPage(+res?.pageable?.pageNumber);
      })
      .catch((err: any) => {
        setUpdating(false);
      });
  };

  const fetchWastageHistoryDetails = (config: any) => {
    setUpdating(true);
    WorkOrderService.apiConfigForConsumption = config;
    WorkOrderService.fetchConsumptionHistory()
      .then((res: any) => {
        setUpdating(false);
        let newRes = res?.content?.map((item: any) => {
          return {
            ...item,
            uom: props.selectedProduct.uom,
            stockUom: props.selectedProduct.stockUom,
            productName: props.selectedProduct.productName,
            productCodeToDisplayOnly:
              props.selectedProduct.productCodeToDisplayOnly,
            advancedTracking: props.selectedProduct.advancedTracking,
            editable: false,
            documentDate: DateFormatService.getDateFromStr(
              item?.documentDate,
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            ),
            createdDate: new Date(item?.dateStamp)
          };
        });
        setWastageLineItems(newRes ?? []);
        setTotalWastagePageCount(res?.totalPages ?? 5);
        setCurrentWastagePage(+res?.pageable?.pageNumber);
      })
      .catch((err: any) => {
        setUpdating(false);
      });
  };

  const getHistoryApiCallParams = (data: any) => {
    return {
      SearchTerm: '',
      Limit: data?.limit,
      Page: data?.page,
      Sort: 'createdOn',
      SortDir: 'DESC',
      Query: `workOrderCode=${
        props.workOrderItems?.workOrderCode
      },productCode=${props.selectedProduct?.productCode},type=${
        data?.type
      },substituteParentProductCode=${
        props?.selectedProduct?.substituteParentProductCode ?? ''
      }`
    };
  };

  const updateConsumptionConfig = () => {
    let config = [...consumptionColumnConfigs];

    config.forEach((conf: any) => {
      switch (conf.key) {
        case 'warehouse':
          conf.formatter = (obj: any) => {
            return obj?.rowData?.warehouse?.name ?? '';
          };
          conf.dropdownConfig.data = warehouseData?.content?.filter(
            (wareHouse: any) =>
              wareHouse?.active && wareHouse.warehouseType !== 'REJECTED'
          );
          conf.dropdownConfig.renderer = (index: any, obj: any) => {
            return obj?.name ?? '';
          };
          conf.dropdownConfig.searchApiConfig.getUrl = (search: any) => {
            const config: WarehouseAPIConfig = {
              ...WarehouseService.apiConfig,
              SearchTerm: search,
              Limit: 20,
              Page: 0
            };
            WarehouseService.apiConfig = config;
            return WarehouseService.getWarehouseEndPoint();
          };
          conf.dropdownConfig.searchApiConfig.dataParser = (response: any) => {
            return (
              response?.content?.filter(
                (wareHouse: any) => wareHouse?.active && !wareHouse.rejected
              ) ?? []
            );
          };
          return;

        case 'quantity':
          conf.formatter = (obj: any) => {
            return NumberFormatService.getNumber(obj.rowData.quantity);
          };
          break;

        case 'documentDate':
          conf.name = 'Consumption Date';
          break;

        default:
          break;
      }
    });

    setConsumptionColumnConfigs([...config]);
  };

  const updateWastageConfig = () => {
    let config = [...wastageColumnConfigs];

    config.forEach((conf: any) => {
      switch (conf.key) {
        case 'quantity':
          conf.formatter = (obj: any) => {
            return NumberFormatService.getNumber(obj.rowData.quantity);
          };

          conf.name = 'Wastage';
          break;

        case 'documentDate':
          conf.name = 'Consumption Date';
          break;

        default:
          break;
      }
    });

    setWastageColumnConfigs([...config]);
  };

  //////////////////////////// Component Methods End ///////////////////////////////////

  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1 rounded-tr rounded-tl">
        <div className="row pop-header-drag-handle">
          <DKLabel text={`WIP Consumption`} className="fw-m fs-l" />
          {updating && <DKSpinner iconClassName="ic-s ml-s" />}
        </div>
        <div className="row width-auto">
          <DKButton
            title={'Cancel'}
            className="bg-white border-m mr-r"
            onClick={() => {
              !saveApiLoading && props.onCancel();
            }}
          />

          <div
            className={`row border-radius-m mr-r ${
              saveApiLoading
                ? ' bg-gray1 border-m pr-2'
                : ' bg-button text-white'
            }`}
          >
            <DKButton
              title={'Save'}
              className=""
              onClick={() => {
                if (
                  props.workOrderItems.status !== WORK_ORDER_STATUS.COMPLETED &&
                  !saveApiLoading
                ) {
                  onSave();
                }
              }}
            />
            {saveApiLoading && <DKSpinner iconClassName="ic-s" />}
          </div>
        </div>
      </div>
    );
  };

  const getQtyDetailsView = () => {
    const SummarySection = (props: any) => {
      return (
        <div
          className="column parent-width border-radius-m shadow-s border-ms p-l bg-white border-box mobile-full-grid-width mobile-mb"
          style={{
            backgroundColor: '#edf3fb',
            border: '1.5px solid #91b5e9'
          }}
        >
          <div className="row justify-content-between">
            <DKLabel text={props.title} className="fw-b fs-l" />
          </div>
          <div className="row justify-content-between parent-width" style={{}}>
            {!Utility.isEmpty(props.data1) && (
              <div className="column">
                <DKLabel
                  text={props.data1.title}
                  className="fs-m fw-m text-gray"
                />
                <div className="row">
                  <DKLabel
                    text={props.data1.subTitle}
                    className={'fs-l mt-m fw-b'}
                  />
                  <DKLabel
                    text={props.data1.unit}
                    className={'fs-m mt-m ml-xs text-gray'}
                  />
                </div>
                {/* <DKLabel
                text={props.footer}
                className="mt-r "
                style={{ color: 'rgb(26, 173, 93)' }}
              /> */}
              </div>
            )}
            {!Utility.isEmpty(props.data1) && (
              <div
                className="bg-blue opacity-4"
                style={{ width: 1, height: 70, marginRight: '2%' }}
              />
            )}
            {!Utility.isEmpty(props.data2) && (
              <div
                className={`${
                  !Utility.isEmpty(props.data1)
                    ? 'column align-items-center'
                    : ''
                }`}
              >
                <DKLabel
                  text={props.data2.title}
                  className="text-green fs-m fw-m"
                />
                <div className="row">
                  <DKLabel
                    text={props.data2.subTitle}
                    className="fs-l mt-m fw-b"
                  />
                  <DKLabel
                    text={props.data2.unit}
                    className={'fs-m mt-m ml-xs text-gray'}
                  />
                </div>
              </div>
            )}
            {!Utility.isEmpty(props.data2) && (
              <div
                className="bg-blue opacity-4"
                style={{ width: 1, height: 70 }}
              />
            )}
            {!Utility.isEmpty(props.data3) && (
              <div className="column align-items-end">
                <DKLabel
                  text={props.data3.title}
                  className={`${
                    props.data3.color ? props.data3.color : 'text-orange'
                  } fs-m fw-m`}
                />
                <div className="row">
                  <DKLabel
                    text={props.data3.subTitle}
                    className="fs-l mt-m fw-b"
                  />
                  <DKLabel
                    text={props.data3.unit}
                    className={'fs-m mt-m ml-xs text-gray'}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      );
    };
    return (
      <div className="mb-m" style={{ width: '50%' }}>
        <SummarySection
          isRightGapRequired={false}
          title=""
          data1={{
            title: 'Allocated Qty',
            subTitle: `${NumberFormatService.getNumber(
              props.selectedProduct.allocatedQty ?? 0
            )}`,
            unit: props.selectedProduct?.uom ?? ''
          }}
          data2={{
            title: 'Consumed Qty',
            subTitle: `${NumberFormatService.getNumber(
              ConsumeProduceHelper.getConsumedOrProducedQtyCount(
                consumptionLineItems
              )
            )}`,
            unit: props.selectedProduct?.uom ?? ''
          }}
          data3={{
            title: `Wastage Qty`,
            subTitle: NumberFormatService.getNumber(
              ConsumeProduceHelper.getWastageQtyCount(wastageLineItems)
            ),
            unit: props.selectedProduct?.uom ?? ''
          }}
          footer={''}
        />
      </div>
    );
  };

  //////////////////////////// Consumption Grid Start ///////////////////////////////////

  const getRowButtons = (lineItem: any) => {
    let btns: any[] = [];

    btns.push({
      icon: getFulfillmentAssigned(lineItem)
        ? ic_barcode_green
        : ic_barcode_red,
      className: 'p-0 underline text-blue',
      onClick: (data: any, index: any) => {
        // if (data?.rowDat?.allowRowEdit) {
        onFulfillmentClick(data, 'CONSUME_PRODUCE');
        // }
      }
    });

    if (lineItem.editable) {
      btns.push({
        icon: DKIcons.ic_delete,
        className: 'p-0 underline text-blue',
        onClick: (data: any) => {
          const updatedState = [...consumptionLineItems];
          updatedState.splice(data?.rowIndex, 1);
          setConsumptionLineItems([...updatedState]);
        }
      });
    }

    return btns;
  };

  const onConsumptionRowUpdate = (data: any) => {
    if (!data.rowData?.editable) {
      return;
    }

    let lineItems = [...consumptionLineItems];
    if (
      data.columnKey === 'quantity' &&
      Number(data.rowData['quantity']) <=
        ConsumeProduceHelper.getRemainingQty(
          Number(props.selectedProduct.allocatedQty),
          consumptionLineItems,
          wastageLineItems
        )
    ) {
      lineItems[data.rowIndex]['quantity'] =
        Utility.roundOffToTenantDecimalScale(data.rowData['quantity']);
      lineItems[data.rowIndex]['warehouseInventoryData'] = [];
    }

    if (data.columnKey === 'documentDate') {
      lineItems[data.rowIndex]['documentDate'] = new Date(
        data.rowData['documentDate']
      );
    }

    setConsumptionLineItems([...lineItems]);
  };

  const getConsumptionGrid = () => {
    return !Utility.isEmpty(consumptionLineItems) ? (
      <DKDataGrid
        title={`Consumption History`}
        needShadow={false}
        needColumnIcons={false}
        needBorder={true}
        needTrailingColumn={false}
        allowBulkOperation={false}
        allowColumnSort={false}
        filterData={[]}
        allowColumnDelete={false}
        allowRowEdit={true}
        allowColumnEdit={false}
        allowFilter={false}
        allowColumnAdd={false}
        allowBottomRowAdd={false}
        allowSearch={false}
        allowShare={false}
        rows={[...deepClone(consumptionLineItems)]?.map((lineItem: any) => {
          return {
            ...lineItem,
            allowRowEdit: lineItem?.editable,
            rowButtons: getRowButtons(lineItem),
            invalidFields: lineItem.quantity ? [] : ['quantity']
          };
        })}
        columns={[
          ...consumptionColumnConfigs,
          {
            id: 'action',
            key: 'action',
            name: 'Actions',
            type: INPUT_TYPE.BUTTON,
            width: 160,
            options: []
          }
        ]}
        currentPage={currentConsumptionPage + 1}
        totalPageCount={totalConsumptionPageCount}
        onPrevPageTapped={() => {
          fetchConsumptionHistoryDetails(
            getHistoryApiCallParams({
              type: WIP_CONSUMPTION_TYPES.CONSUMPTION,
              page: currentConsumptionPage - 1, // Books backend pagination offset is 0, so subtract 1
              limit: PAGE_SIZE
            })
          );
        }}
        onNextPageTapped={() => {
          fetchConsumptionHistoryDetails(
            getHistoryApiCallParams({
              type: WIP_CONSUMPTION_TYPES.CONSUMPTION,
              page: currentConsumptionPage + 1, // Books backend pagination offset is 0, so add 1
              limit: PAGE_SIZE
            })
          );
        }}
        onRowUpdate={(data: any) => {
          onConsumptionRowUpdate(data);
        }}
        onRowClick={(data: any) => {
          setSelectedItem(data?.rowData);
        }}
      />
    ) : (
      <div className="column mt-4 parent-width align-items-center justify-content-center bg-gray1 border border-radius-m p-4 gap-4">
        <div className="column parent-width align-items-center">
          <DKLabel text={`No consumption added yet.`} className=" mt-s" />
          <DKLabel
            text={`Please add a consumption to continue.`}
            className="text-gray mt-s"
          />
        </div>
        <DKButton
          title={`Add new Consumption`}
          className={`text-white fw-m ${
            props.selectedProduct.allocatedQty !==
              ConsumeProduceHelper.getConsumedOrProducedQtyCount(
                consumptionLineItems
              ) &&
            props.workOrderItems.status !== WORK_ORDER_STATUS.COMPLETED &&
            !updating
              ? 'bg-app'
              : 'bg-gray'
          }`}
          onClick={() => {
            if (
              props.selectedProduct.allocatedQty !==
                ConsumeProduceHelper.getConsumedOrProducedQtyCount(
                  consumptionLineItems
                ) &&
              props.workOrderItems.status !== WORK_ORDER_STATUS.COMPLETED &&
              !updating
            ) {
              addNewConsumption();
            }
          }}
        />
      </div>
    );
  };

  const addNewConsumption = () => {
    if (
      props.selectedProduct.allocatedQty !==
      ConsumeProduceHelper.getConsumedOrProducedQtyCount(consumptionLineItems)
    ) {
      setConsumptionLineItems([
        ...consumptionLineItems,
        {
          productName: props?.selectedProduct?.productName ?? '',
          productCode: props?.selectedProduct?.productCode,
          productCodeToDisplayOnly: props?.selectedProduct?.productCodeToDisplayOnly,
          documentDate: new Date(),
          warehouse: null,
          quantity: 0,
          uom: props.selectedProduct.uom,
          stockUom: props.selectedProduct.stockUom,
          documentUOMSchemaDefinition:
            props.selectedProduct?.documentUOMSchemaDefinition,
          createdDate: new Date(),
          advancedTracking: props.selectedProduct.advancedTracking,
          editable: true,
          inventoryPrice: ConsumeProduceHelper.getProductDetailsForScrapGrid(
            props?.workOrderItems?.workOrderItems,
            props?.selectedProduct?.productCode,
            'inventoryPrice',
            false
          )
        }
      ]);
    }
  };

  const getAddNewConsumptionBtn = () => {
    return (
      <DKButton
        title={`+ Add Consumption`}
        onClick={() => {
          if (!diableBtn) {
            addNewConsumption();
          }
        }}
        className={`fw-m position-relative ${
          !diableBtn ? 'text-blue' : 'text-gray'
        }`}
        style={{
          paddingTop: 0,
          paddingLeft: 0,
          width: 100,
          marginBottom: 12,
          marginTop: -10
        }}
      />
    );
  };

  //////////////////////////// Consumption Grid End ///////////////////////////////////

  //////////////////////////// Wastage Grid Start ///////////////////////////////////

  const getWastageRowButtons = (lineItem: any) => {
    let btns: any[] = [];

    btns.push({
      icon: getFulfillmentAssigned(lineItem)
        ? ic_barcode_green
        : ic_barcode_red,
      className: 'p-0 underline text-blue',
      onClick: (data: any, index: any) => {
        onFulfillmentClick(data, 'WASTAGE');
      }
    });

    if (lineItem.editable) {
      btns.push({
        icon: DKIcons.ic_delete,
        className: 'p-0 underline text-blue',
        onClick: (data: any) => {
          const updatedState = [...wastageLineItems];
          updatedState.splice(data?.rowIndex, 1);
          setWastageLineItems([...updatedState]);
        }
      });
    }

    return btns;
  };

  const onWastageRowUpdate = (data: any) => {
    if (!data.rowData?.editable) {
      return;
    }

    let lineItems = [...wastageLineItems];
    if (
      data.columnKey === 'quantity' &&
      Number(data.rowData['quantity']) <=
        ConsumeProduceHelper.getRemainingQty(
          Number(props.selectedProduct.allocatedQty),
          consumptionLineItems,
          wastageLineItems
        )
    ) {
      lineItems[data.rowIndex]['quantity'] =
        Utility.roundOffToTenantDecimalScale(data.rowData['quantity']);
      lineItems[data.rowIndex]['warehouseInventoryData'] = [];
    }

    if (data.columnKey === 'documentDate') {
      lineItems[data.rowIndex]['documentDate'] = data.rowData['documentDate'];
    }
    setWastageLineItems([...lineItems]);
  };

  const getWastageGrid = () => {
    return !Utility.isEmpty(wastageLineItems) ? (
      <DKDataGrid
        title={`Wastage History`}
        needShadow={false}
        needColumnIcons={false}
        needBorder={true}
        needTrailingColumn={false}
        allowBulkOperation={false}
        allowColumnSort={false}
        filterData={[]}
        allowColumnDelete={false}
        allowRowEdit={true}
        allowColumnEdit={false}
        allowFilter={false}
        allowColumnAdd={false}
        allowBottomRowAdd={false}
        allowSearch={false}
        allowShare={false}
        rows={[...deepClone(wastageLineItems)]?.map((lineItem: any) => {
          return {
            ...lineItem,
            allowRowEdit: lineItem?.editable,
            rowButtons: getWastageRowButtons(lineItem)
          };
        })}
        columns={[
          ...wastageColumnConfigs,
          {
            id: 'action',
            key: 'action',
            name: 'Actions',
            type: INPUT_TYPE.BUTTON,
            width: 160,
            options: []
          }
        ]}
        currentPage={currentWastagePage + 1}
        totalPageCount={totalWastagePageCount}
        onPrevPageTapped={() => {
          fetchWastageHistoryDetails(
            getHistoryApiCallParams({
              type: WIP_CONSUMPTION_TYPES.WASTAGE,
              page: currentWastagePage - 1, // Books backend pagination offset is 0, so subtract 1
              limit: PAGE_SIZE
            })
          );
        }}
        onNextPageTapped={() => {
          fetchWastageHistoryDetails(
            getHistoryApiCallParams({
              type: WIP_CONSUMPTION_TYPES.WASTAGE,
              page: currentWastagePage + 1, // Books backend pagination offset is 0, so add 1
              limit: PAGE_SIZE
            })
          );
        }}
        onRowUpdate={(data: any) => {
          onWastageRowUpdate(data);
        }}
        onRowClick={(data: any) => {
          setSelectedItem(data?.rowData);
        }}
      />
    ) : (
      <div className="column mt-4 parent-width align-items-center justify-content-center bg-gray1 border border-radius-m p-4 gap-4">
        <div className="column parent-width align-items-center">
          <DKLabel text={'No wastage added yet.'} className=" mt-s" />
          <DKLabel
            text={'Please add a wastage to continue.'}
            className="text-gray mt-s"
          />
        </div>
        <DKButton
          title="Add new wastage"
          className={`text-white fw-m ${
            props.selectedProduct.allocatedQty !==
              ConsumeProduceHelper.getConsumedOrProducedQtyCount(
                consumptionLineItems
              ) &&
            props.workOrderItems.status !== WORK_ORDER_STATUS.COMPLETED &&
            !updating
              ? 'bg-app'
              : 'bg-gray'
          }`}
          onClick={() => {
            if (
              props.selectedProduct.allocatedQty !==
                ConsumeProduceHelper.getConsumedOrProducedQtyCount(
                  consumptionLineItems
                ) &&
              props.workOrderItems.status !== WORK_ORDER_STATUS.COMPLETED &&
              !updating
            ) {
              addWastage();
            }
          }}
        />
      </div>
    );
  };

  const addWastage = () => {
    if (
      props.selectedProduct.allocatedQty !==
      ConsumeProduceHelper.getConsumedOrProducedQtyCount(consumptionLineItems)
    ) {
      setWastageLineItems([
        ...wastageLineItems,
        {
          productName: props?.selectedProduct?.productName ?? '',
          productCode: props?.selectedProduct?.productCode,
          productCodeToDisplayOnly:
            props?.selectedProduct?.productCodeToDisplayOnly,
          documentDate: new Date(),
          warehouse: null,
          quantity: 0,
          uom: props.selectedProduct.uom,
          stockUom: props.selectedProduct.stockUom,
          documentUOMSchemaDefinition:
            props.selectedProduct?.documentUOMSchemaDefinition,
          createdDate: new Date(),
          advancedTracking: props.selectedProduct.advancedTracking,
          editable: true
        }
      ]);
    }
  };

  const getAddNewWastageBtn = () => {
    return (
      <DKButton
        title={`+ Add Wastage`}
        onClick={() => {
          if (!diableBtn) {
            addWastage();
          }
        }}
        className={`fw-m position-relative ${
          !diableBtn ? 'text-blue' : 'text-gray'
        }`}
        style={{
          paddingTop: 0,
          paddingLeft: 0,
          width: 100,
          marginBottom: 12,
          marginTop: -10
        }}
      />
    );
  };

  //////////////////////////// Wastage Grid End ///////////////////////////////////

  //////////////////////////// Fulfillment Grid Start ///////////////////////////////////

  const isBatchItemAssigned = (item: any) => {
    const totalItem =
      (item.warehouseInventoryData &&
        item.warehouseInventoryData.length > 0 &&
        item.warehouseInventoryData.reduce(
          (a: number, b: any) => Number(a) + parseFloat(b['quantity']),
          0
        )) ||
      0;
    return (
      Number(item.quantity) !== 0 && Number(totalItem) === Number(item.quantity)
    );
  };

  const isSerialItemAssigned = (item: any) => {
    let allotedQty = item?.warehouseInventoryData?.reduce(
      (acc: number, curr: any) => {
        let qty = curr['quantity'];

        // if (item?.advancedTracking === ADVANCE_TRACKING.SERIAL) {
        //   qty = Utility.getUomQuantityWithoutRoundOff(
        //     qty,
        //     props?.selectedProduct?.documentUOMSchemaDefinition
        //   );
        // }

        return acc + parseFloat(qty);
      },
      0
    );
    allotedQty = Utility.roundingOff(allotedQty, QTY_ROUNDOFF_PRECISION);

    return allotedQty === Number(item?.quantity || 0);
  };

  const isNoneTrackedItemAssigned = (item: any) => {
    const totalQuantity =
      (item.warehouseInventoryData &&
        item.warehouseInventoryData.length > 0 &&
        item.warehouseInventoryData.reduce(
          (a: number, b: any) => Number(a) + parseFloat(b['quantity']),
          0
        )) ||
      0;
    return (
      Number(item.quantity) !== 0 &&
      Number(item.quantity) === Number(totalQuantity)
    );
  };

  const getFulfillmentAssigned = (lineItem: any) => {
    if (lineItem.advancedTracking === ADVANCE_TRACKING.BATCH) {
      return isBatchItemAssigned(lineItem);
    }

    if (lineItem.advancedTracking === ADVANCE_TRACKING.SERIAL) {
      return isSerialItemAssigned(lineItem);
    }

    if (lineItem.advancedTracking === ADVANCE_TRACKING.NONE) {
      return isNoneTrackedItemAssigned(lineItem);
    }
  };

  const onFulfillmentClick = (data: any, flowType: any) => {
    setIsWastageFulfillmentFlow(flowType);
    if (data?.rowData?.advancedTracking === ADVANCE_TRACKING.BATCH) {
      setBatchTracking(data?.rowData, data?.rowIndex);
    }

    if (data?.rowData?.advancedTracking === ADVANCE_TRACKING.SERIAL) {
      setSerialTracking(data?.rowData, data?.rowIndex);
    }

    if (data?.rowData?.advancedTracking === ADVANCE_TRACKING.NONE) {
      setNoneTracking(data?.rowData, data?.rowIndex);
    }
  };

  const loadSerialTrackingProducts = async (code: any) => {
    try {
      dispatch(
        fetchSerialTrackingProducts({
          productCode: code,
          enableQCWarehouse: false
        })
      );
    } catch (err) {
      console.error('Error fetching Advanced Tracking Products: ', err);
    }
  };

  const setSerialTracking = (item: any, index: any) => {
    setSelectedItem(item);
    loadSerialTrackingProducts(item.productCode);
    setSelectedIndex(index);
    setShowSerialTracking(true);
  };

  const loadProductInventoryById = async (ids: any) => {
    try {
      await dispatch(fetchProductInventoryByID(ids));
    } catch (err) {
      console.error('Error fetching UOMs details: ', err);
    }
  };

  const setNoneTracking = (item: any, index: any) => {
    loadProductInventoryById([item?.productCode]);
    setSelectedItem({ ...item, pendingQuantity: item?.quantity });
    setSelectedIndex(index);
    setShowMultipleWarehouse(true);
  };

  const setBatchTracking = (item: any, index: any) => {
    setSelectedItem(item);
    loadBatchTrackingProducts(item?.productCode);
    setSelectedIndex(index);
  };

  const loadBatchTrackingProducts = async (code: any) => {
    showLoader();
    try {
      await dispatch(
        fetchBatchTrackingProducts({
          productCode: code,
          enableQCWarehouse: false
        })
      );
      removeLoader();
      setShowBatchTracking(true);
    } catch (err) {
      console.error('Error fetching Advanced Tracking Products: ', err);
      removeLoader();
    }
  };

  const getFlatArrayOfSerialSelections = (obj: any, type: ADVANCE_TRACKING) => {
    let mapped: any[];
    if (type === ADVANCE_TRACKING.BATCH || type === ADVANCE_TRACKING.SERIAL) {
      mapped = obj?.map((item: any) => {
        let itemAdvTrack = item?.advancedTrackingData?.map((lineItem: any) => {
          return {
            ...lineItem,
            warehouse: {
              ...item,
              name: item.warehouseName,
              code: item.warehouseCode
            }, // For serial tracked edit mode
            warehouseName: item.warehouseName,
            warehouseCode: item.warehouseCode,
            batchSize: lineItem.qtyToFulfil,
            row: {
              rowName: item?.rowName,
              rowCode: item?.rowCode
            },
            rack: {
              rackName: item?.rackName,
              rackCode: item?.rackCode
            },
            bin: {
              binName: item?.binName,
              binCode: item?.binCode
            }
          };
        });
        return itemAdvTrack;
      });
    } else {
      mapped = obj?.map((item: any) => {
        return item?.advancedTrackingData;
      });
    }

    let flatArray = mapped?.flat() || [];
    return flatArray;
  };

  const createProductItemForTracking = (type: ADVANCE_TRACKING) => {
    return {
      product: {
        name: selectedItem?.productName,
        stockUom: selectedItem?.stockUom,
        availableQuantity: 1
      },
      productCode: selectedItem?.productCode,
      documentSequenceCode: selectedItem?.documentSequenceCode,
      requiredQuantity: selectedItem.quantity,
      quantityRequired: selectedItem.quantity,
      productQuantity: selectedItem.quantity,
      advancedTracking: selectedItem.advancedTracking,
      advancedTrackingFulfilmentData: getFlatArrayOfSerialSelections(
        selectedItem?.warehouseInventoryData ?? [],
        type
      ),
      advancedTrackingMetaData: getFlatArrayOfSerialSelections(
        selectedItem?.warehouseInventoryData ?? [],
        type
      ),
      isQuickCommit: false,
      documentUOMSchemaDefinition: selectedItem?.documentUOMSchemaDefinition
    };
  };

  const getDefaultActiveWarehouse = () => {
    let activeWarehouses =
      warehouseData &&
      warehouseData?.content &&
      warehouseData?.content.filter(
        (item: any) => item.active && !item.rejected
      );

    let selectedWarehouse = activeWarehouses?.find(
      (warehouse: any) => warehouse.primary === true
    );
    return selectedWarehouse?.code;
  };

  const parseWarehouseInventoryData = (
    warehouseInventoryData: any,
    trackingType: ADVANCE_TRACKING
  ) => {
    if (trackingType === ADVANCE_TRACKING.NONE) {
      return warehouseInventoryData;
    }

    let result: any = [];
    warehouseInventoryData?.forEach((warehouseInventoryObject: any) => {
      warehouseInventoryObject?.advancedTrackingData?.forEach(
        (advanceTrackingObj: any) => {
          let object = {
            ...warehouseInventoryObject,
            quantity: warehouseInventoryObject?.quantity ?? 0,
            advancedTrackingData: [advanceTrackingObj],
            rackCode: advanceTrackingObj?.rackCode ?? null,
            rowCode: advanceTrackingObj?.rowCode ?? null,
            binCode: advanceTrackingObj?.binCode ?? null
          };
          result.push(object);
        }
      );
    });
    return result;
  };

  /**
   * TODO: Refactor below method & replace with component: GetReceivePopupForTargetWarehouseWO & TrackingPopupHelper
   * @sumeet
   */
  const getFulfillmentPopUp = () => {
    const setWarehouseInventoryData = (warehouseInventoryData: any) => {
      if (isWastageFulfillmentFlow === 'CONSUME_PRODUCE') {
        let lineItems = [...consumptionLineItems];
        lineItems[selectedIndex]['warehouseInventoryData'] =
          warehouseInventoryData;
        setConsumptionLineItems([...lineItems]);
      }

      if (isWastageFulfillmentFlow === 'WASTAGE') {
        let lineItems = [...wastageLineItems];
        lineItems[selectedIndex]['warehouseInventoryData'] =
          warehouseInventoryData;
        setWastageLineItems([...lineItems]);
      }
    };
    let allITems = deepClone(props.workOrderItems?.workOrderItems);
    let filteredData: any = null;
    if (!props.selectedProduct.isSubstitute) {
      filteredData = allITems?.find((item: any) => {
        return item.itemName.productId === props.selectedProduct.productCode;
      });
      let consumeProduceLineItems = [
        ...consumptionLineItems,
        ...wastageLineItems
      ];
      consumeProduceLineItems = consumeProduceLineItems?.filter(
        (consumeLineItem: any, index: any) => {
          // consider consumption and wastage rows as one single grid (because qtys should be deducted from both grids)
          if (isWastageFulfillmentFlow === 'CONSUME_PRODUCE') {
            return index !== selectedIndex;
          } else {
            return index !== consumptionLineItems?.length + selectedIndex;
          }
        }
      );

      if (
        selectedItem &&
        selectedIndex !== -1 &&
        filteredData?.warehouseInventoryData
      ) {
        let copyWhInData: any = [];
        if (filteredData.itemName.advancedTracking === ADVANCE_TRACKING.NONE) {
          copyWhInData = filteredData?.warehouseInventoryData?.map(
            (filteredWhData: any) => {
              let copyFilteredWHData = { ...filteredWhData };
              let allWarehousesData = [...consumeProduceLineItems]
                ?.reduce((current: any[], consumedLI: any) => {
                  return [...current, consumedLI?.warehouseInventoryData];
                }, [])
                .flat();
              let currentWarehouseDataArray = allWarehousesData?.filter(
                (whData: any) => {
                  return (
                    copyFilteredWHData.warehouseCode === whData?.warehouseCode
                  );
                }
              );

              let totalX = 0;
              totalX = currentWarehouseDataArray
                ?.filter((x: any) => {
                  return (
                    x.rowCode === copyFilteredWHData.rowCode ||
                    x.rackCode === copyFilteredWHData.rackCode ||
                    x.binCode === copyFilteredWHData.binCode
                  );
                })
                .reduce((acc: any, currWh: any) => {
                  return Number(acc) + Number(currWh.quantity ?? 0);
                }, 0);
              copyFilteredWHData = {
                ...copyFilteredWHData,
                quantity: copyFilteredWHData.quantity - totalX
              };

              return copyFilteredWHData;
            }
          );
        } else {
          copyWhInData = filteredData?.warehouseInventoryData?.map(
            (filteredWhData: any) => {
              let copyFilteredWHData = { ...filteredWhData };
              copyFilteredWHData = {
                ...copyFilteredWHData,
                ...(copyFilteredWHData?.advancedTrackingData?.[0] ?? {})
              };
              let allWarehousesData = [...consumeProduceLineItems]
                ?.reduce((current: any[], consumedLI: any) => {
                  return [...current, consumedLI?.warehouseInventoryData];
                }, [])
                .flat();

              if (Utility.isEmpty(allWarehousesData)) {
                return filteredWhData;
              }

              let currentWarehouseDataArray = allWarehousesData?.filter(
                (whData: any) => {
                  return (
                    copyFilteredWHData.warehouseCode === whData?.warehouseCode
                  );
                }
              );

              let allAdvancedTrackingDataForCurrentWarehouse =
                currentWarehouseDataArray
                  ?.reduce((current: any[], consumedLI: any) => {
                    return [...current, consumedLI?.advancedTrackingData];
                  }, [])
                  .flat();

              let advTrckData = copyFilteredWHData.advancedTrackingData?.map(
                (adTrackData: any) => {
                  let filteredAdTrackData =
                    allAdvancedTrackingDataForCurrentWarehouse?.filter(
                      (targetAd: any) => {
                        return (
                          targetAd.serialBatchNumber ===
                          adTrackData.serialBatchNumber
                        );
                      }
                    );
                  let remainingQty =
                    adTrackData.qtyToFulfil -
                    filteredAdTrackData?.reduce((acc: any, obj: any) => {
                      return Number(acc) + Number(obj.qtyToFulfil);
                    }, 0);
                  adTrackData = {
                    ...adTrackData,
                    qtyToFulfil: remainingQty ?? 0
                  };

                  return adTrackData;
                }
              );
              advTrckData = advTrckData?.filter((i: any) => {
                return i.qtyToFulfil > 0;
              });
              copyFilteredWHData.advancedTrackingData = advTrckData;
              return copyFilteredWHData;
            }
          );
          copyWhInData = copyWhInData?.map((copyWhData: any) => {
            const qty = copyWhData?.advancedTrackingData?.reduce(
              (acc: any, adCopy: any) => {
                return Number(acc) + Number(adCopy?.qtyToFulfil ?? 0);
              },
              0
            );
            return (copyWhData = {
              ...copyWhData,
              quantity: qty,
              qtyToFulfil: Utility.getUomWarehouseQuantityWithoutRoundOff(
                qty,
                selectedItem?.documentUOMSchemaDefinition
              ),
              advancedTrackingData: copyWhData?.advancedTrackingData?.map(
                (advcData: any) => {
                  return {
                    ...advcData,
                    qtyToFulfil: Utility.getUomWarehouseQuantityWithoutRoundOff(
                      advcData?.qtyToFulfil,
                      selectedItem?.documentUOMSchemaDefinition
                    )
                  };
                }
              )
            });
          });
        }
        filteredData.warehouseInventoryData = copyWhInData;
      }
    } else {
      filteredData = props.selectedProduct?.bomProductSubstitutesDetails?.find(
        (subs: any) => {
          return subs.productCode === props.selectedProduct.productCode;
        }
      );
    }

    let batchTrackDetails: any = null;
    if (selectedItem) {
      batchTrackDetails = createProductItemForTracking(ADVANCE_TRACKING.BATCH);
      batchTrackDetails = {
        ...batchTrackDetails,
        quantityRequired: Utility.getUomWarehouseQuantityWithoutRoundOff(
          batchTrackDetails?.quantityRequired ??
            batchTrackDetails?.requiredQuantity,
          batchTrackDetails?.documentUOMSchemaDefinition
        ),
        advancedTrackingFulfilmentData:
          batchTrackDetails?.advancedTrackingFulfilmentData?.map(
            (itemBatchTrack: any) => {
              return {
                ...itemBatchTrack,
                qtyToFulfil: selectedItem?.allowRowEdit
                  ? Utility.getUomWarehouseQuantityWithoutRoundOff(
                      itemBatchTrack?.qtyToFulfil,
                      batchTrackDetails?.documentUOMSchemaDefinition
                    )
                  : itemBatchTrack?.qtyToFulfil,
                advancedTrackingData: itemBatchTrack?.advancedTrackingData?.map(
                  (batchAdvanceTrack: any) => {
                    return {
                      ...batchAdvanceTrack,
                      qtyToFulfil:
                        Utility.getUomWarehouseQuantityWithoutRoundOff(
                          batchAdvanceTrack?.qtyToFulfil,
                          batchTrackDetails?.documentUOMSchemaDefinition
                        )
                    };
                  }
                )
              };
            }
          )
      };
    }

    const noneTrackDetails = selectedItem
      ? createProductItemForTracking(ADVANCE_TRACKING.NONE)
      : null;

    const serialTrackDetails = selectedItem
      ? createProductItemForTracking(ADVANCE_TRACKING.SERIAL)
      : null;
    return (
      <>
        {showSerialTracking && (
          <SerialTrackedAssignment
            isMRP={true}
            filterBatchData={true}
            selectedItem={selectedItem}
            filteredWarehouseInventoryData={filteredData}
            disableBtn={'id' in selectedItem}
            mrpTitle="Material"
            itemDetails={serialTrackDetails}
            module={MODULE_TYPE.SELL}
            defaultProductWarehouse={selectedItem?.warehouseCode}
            onSerialSave={(
              data: any,
              isQuickCommit: boolean,
              quantityToFulfill: any
            ) => {
              let updatedData = data?.map((itemData: any) => {
                return {
                  ...itemData,
                  qtyToFulfil: Utility.getUomQuantityWithoutRoundOff(
                    itemData?.qtyToFulfil,
                    serialTrackDetails?.documentUOMSchemaDefinition
                  )
                };
              });

              const codesSet: any = new Set(
                updatedData.map((item: any) => item.warehouseCode)
              );
              const uniquesWarehouseCodes = [...codesSet];

              const warehouseInventoryData = uniquesWarehouseCodes?.map(
                (warehouseCode: any) => {
                  let advancedTrackingData = data?.filter(
                    (object: any) => object.warehouseCode === warehouseCode
                  );

                  // let advancedTrackingDataWithUpdatedQtys = updatedData?.filter(
                  //   (object: any) => object.warehouseCode === warehouseCode
                  // );

                  // let totalQuantity =
                  //   advancedTrackingDataWithUpdatedQtys.reduce(
                  //     (prev: number, current: any) => {
                  //       let qty = current.qtyToFulfil;

                  //       // qty = Utility.getUomQuantityWithoutRoundOff(
                  //       //   qty,
                  //       //   serialTrackDetails?.documentUOMSchemaDefinition
                  //       // );

                  //       return Number(prev) + Number(qty);
                  //     },
                  //     0
                  //   );

                  // totalQuantity = Utility.roundingOff(
                  //   totalQuantity,
                  //   QTY_ROUNDOFF_PRECISION
                  // );

                  return {
                    advancedTrackingData: advancedTrackingData,
                    quantity: Utility.getUomQuantityWithoutRoundOff(
                      advancedTrackingData?.[0]?.qtyToFulfil,
                      serialTrackDetails?.documentUOMSchemaDefinition
                    ),
                    // Utility.roundingOff(
                    //   Utility.getUomQuantityWithoutRoundOff(
                    //     advancedTrackingData?.[0]?.qtyToFulfil,
                    //     serialTrackDetails?.documentUOMSchemaDefinition
                    //   ),
                    //   QTY_ROUNDOFF_PRECISION
                    // ),
                    warehouseCode: warehouseCode,
                    warehouseName: advancedTrackingData?.[0].warehouseName
                  };
                }
              );
              const updatedWarehouseInventoryData = parseWarehouseInventoryData(
                warehouseInventoryData,
                ADVANCE_TRACKING.SERIAL
              );
              setWarehouseInventoryData(updatedWarehouseInventoryData);
              setShowSerialTracking(false);
            }}
            onClose={() => {
              setShowSerialTracking(false);
            }}
          ></SerialTrackedAssignment>
        )}
        {showBatchTracking && (
          <BatchTrackingAssignment
            isMrpFlow={true}
            isConsumptionType={true}
            targetWarehouse={null}
            filterBatchData={true} // isConsumptionType
            selectedItem={selectedItem}
            filteredWarehouseInventoryData={filteredData}
            disableBtn={'id' in selectedItem}
            itemDetails={batchTrackDetails}
            module={MODULE_TYPE.SELL}
            defaultProductWarehouse={null}
            onBatchSave={(data: any, quantityToFulfill: any) => {
              let updatedData = data?.map((itemData: any) => {
                return {
                  ...itemData,
                  qtyToFulfil: Utility.getUomQuantity(
                    itemData?.qtyToFulfil,
                    batchTrackDetails?.documentUOMSchemaDefinition
                  ),
                  advancedTrackingData: itemData?.advancedTrackingData?.map(
                    (itemTrackingData: any) => {
                      return {
                        ...itemTrackingData,
                        qtyToFulfil: Utility.getUomQuantity(
                          itemTrackingData?.qtyToFulfil,
                          batchTrackDetails?.documentUOMSchemaDefinition
                        )
                      };
                    }
                  )
                };
              });

              const codesSet: any = new Set(
                updatedData.map((item: any) => item.warehouseCode)
              );
              const uniquesWarehouseCodes = [...codesSet];

              const warehouseInventoryData = uniquesWarehouseCodes?.map(
                (warehouseCode: any) => {
                  let advancedTrackingData = updatedData?.filter(
                    (object: any) => object.warehouseCode === warehouseCode
                  );

                  let totalQuantity = 0;
                  totalQuantity = advancedTrackingData.reduce(
                    (prev: number, current: any) => {
                      return Number(prev) + Number(current.qtyToFulfil);
                    },
                    0
                  );

                  return {
                    advancedTrackingData: advancedTrackingData,
                    quantity: totalQuantity,
                    warehouseCode: warehouseCode,
                    warehouseName: advancedTrackingData?.[0].warehouseName
                  };
                }
              );
              setWarehouseInventoryData(warehouseInventoryData);
              setShowBatchTracking(false);
            }}
            onClose={() => {
              setShowBatchTracking(false);
            }}
          ></BatchTrackingAssignment>
        )}
        {showMultipleWarehouse && (
          <NoneTrackedAssignment
            isEditMode={props.isEditMode}
            details={{
              ...noneTrackDetails,
              pendingQuantity: Utility.getUomWarehouseQuantityWithoutRoundOff(
                selectedItem?.pendingQuantity,
                noneTrackDetails?.documentUOMSchemaDefinition
              ),
              parentQuantityToFulfill: selectedItem.quantity,
              quantityRequired: selectedItem.quantity,
              warehouseInventoryData: selectedItem?.warehouseInventoryData ?? []
            }}
            isMrpFlow={true}
            filterNormalData={true}
            filteredWarehouseInventoryData={filteredData}
            module={MODULE_TYPE.SELL}
            defaultProductWarehouse={getDefaultActiveWarehouse()}
            onSave={(data: any) => {
              if (data && data.length > 0) {
                let warehouseInventoryData = data?.map((obj: any) => {
                  return {
                    ...obj,
                    advancedTrackingData: [],
                    quantity: obj.quantity,
                    warehouseCode: obj.warehouseCode,
                    warehouseName: obj.warehouseName,
                    costPerUnit: selectedItem.inventoryPrice
                  };
                });
                setWarehouseInventoryData(warehouseInventoryData);
              }
              setShowMultipleWarehouse(false);
            }}
            onCancel={() => {
              setShowMultipleWarehouse(false);
            }}
          ></NoneTrackedAssignment>
        )}
      </>
    );
  };

  //////////////////////////// Fulfillment Grid End ///////////////////////////////////

  return (
    <DynamicPopupWrapper>
      <div className="transparent-background" style={{ zIndex: 9998 }}>
        <div
          className="popup-window"
          style={{
            maxWidth: '98%',
            width: 1300,
            maxHeight: '93%',
            padding: 0,
            overflow: 'visible'
          }}
        >
          {getHeader()}
          <div className="column parent-width p-h-r p-v-r overflow-scroll">
            {getQtyDetailsView()}
            {/* consumption grid */}
            {getConsumptionGrid()}
            {!Utility.isEmpty(consumptionLineItems) &&
              getAddNewConsumptionBtn()}
            <DKLine
              style={{ height: 1, marginBottom: 12 }}
              className="opacity-80"
            />
            {/* wastage grid (with consumption) */}
            {getWastageGrid()}
            {!Utility.isEmpty(wastageLineItems) && getAddNewWastageBtn()}
          </div>
        </div>
        {getFulfillmentPopUp()}
      </div>
    </DynamicPopupWrapper>
  );
};

export default ConsumptionPopup;

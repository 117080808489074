import ic_cashfree from '../Assets/Icons/ic_cashfree.png';
import ic_razorpay from '../Assets/Icons/ic_razorpay.png';
import ic_paytm from '../Assets/Icons/ic_paytm.png';
import ic_paypal from '../Assets/Icons/ic_paypal.png';
import ic_stripe from '../Assets/Icons/ic_stripe.png';
import ic_veem from '../Assets/Icons/ic_veem_2.png';
import ic_opennode from '../Assets/Icons/ic_opennode.png';
import {
  BANK_ACCOUNT_TYPE,
  NUMBER_FORMAT,
  PAYMENT_FILE_FORMAT,
  PAYMENT_INFO_TYPE
} from './Enum';
import { INPUT_TYPE } from 'deskera-ui-library';

export const GOOGLE_NO_TRANSLATE_CLASS = 'notranslate';
export const BARCODE_LENGTH_ALLOWED = 256;
export const DESKERA_URL =
  'https://www.deskera.com/?utm_source=inv_designer&utm_medium=inv_email&utm_campaign=freeinvoicetool';
export const LANDING_PAGE_URL = process.env.REACT_APP_URL_REPORT_BUILDER;
export const LOCALECODE_EN_IN = 'en-IN';
export const PAGE_SIZE = 25;
export const MAIN_HOLDER_ID = 'root';
export const MAX_SECTION_WIDTH = 1200;
export const CREDIT_AMOUNT = 'credit amount';
export const DEBIT_AMOUNT = 'debit amount';
export const FLOATING_ZERO = '0.0';
export const CHART_HEIGHT = 220;
export const CHART_WIDTH = 418;
export const APP_NAME = 'ERP';
export const MRP_APP_NAME = 'MRP';
export const CRM_APP_NAME = 'CRM3';
export const CURRENCY_PRECISION = 6;
export const STICKER_WIDTH = 50.8;
export const SETTINGS_GRID_WIDTH = 780;
export const STICKER_HEIGHT = 25.4;
export const NUMBER_30 = 30;
export const CUST_EXPORT_WO_PAY = 'EXPORT_WO_PAY';
export const CUST_EXPORT_W_PAY = 'EXPORT_W_PAY';
export const CUST_NA = 'NA';
export const CUST_SEZ_WO_PAY = 'SEZ_WO_PAY';
export const CUST_SEZ_W_PAY = 'SEZ_W_PAY';
export const VENDOR_IMPORT = 'IMPORT';
export const VENDOR_NA = 'NA';
export const VENDOR_SEZ_W_PAY = 'SEZ_W_PAY';
export const VENDOR_SEZ_WO_PAY = 'SEZ_WO_PAY';
export const REGISTERED_BUSINESS_REGULAR = 'REGISTERED_BUSINESS_REGULAR';
export const REGISTERED_BUSINESS_COMPOSITION =
  'REGISTERED_BUSINESS_COMPOSITION';
export const UNREGISTERED_BUSINESS = 'UNREGISTERED_BUSINESS';
export const CUSTOMER_TYPE = 'CUSTOMER';
export const OVERSEAS = 'OVERSEAS';
export const SPECIAL_ECONOMIC_ZONE = 'SPECIAL_ECONOMIC_ZONE';
export const DEEMED_EXPORT = 'DEEMED_EXPORT';
export const ITC_IS_REVERSED_ID = 'ITC_IS_REVERSED';
export const UOM_NA_ID = 5;
export const COMPLIANCE_ENABLED = 'complianceEnabled';
export const NONE = 'NONE';
export const PRODUCT_TRANSACTIONTYPE_SALES = 'BOTH,SALES';
export const PRODUCT_TRANSACTIONTYPE_PURCHASE = 'BOTH,PURCHASE';
export const REGEX = {
  WEBSITE_DOMAIN:
    /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
  PERCENT_NUMER: /^(\d+)(\.(\d+))?(%)?$/,
  WHATSAPP: /^((\D+)?0+)|\D/g,
  DECIMAL_NUMBER: /^[-+]?[0-9]*\.?[0-9]+$/,
  HYPHEN_ONLY: /^[-]*$/,
  HTML_PATTERN: /<[^>]*>/g,
  WO_TZ_DATE_FORMAT: /T(\d\d):(\d\d):(\d\d).(\d\d\d)\+(\d\d\d\d)/,
  WA_TEMPLATE_PLACEHOLDER: /{{[0-9a-zA-Z]}}/gm
};
export const DOCUMENT_DESIGNER = 'Document Designer Template';
export const DOCUMENT_THERMAL_DESIGNER = 'Document Thermal Template';
export const THREE_FIVE = 'three_five';
export const ERROR = 'error';
export const STANDLEY_EMAIL = 'standleyming@gmail.com';

export const ROUND_PRECISION = 5;

export const QTY_ROUNDOFF_PRECISION_BACKEND = 10;

export const appTitleStyle = 'mt-r fw-m fs-m text-app-color';

export enum MRP {
  BILL_OF_MATERIAL = 'MRP_BILL_OF_MATERIAL',
  FINISHED_GOODS = 'MRP_FINISHED_GOODS',
  RAW_MATERIAL = 'MRP_RAW_MATERIAL',
  SERVICE = 'MRP_SERVICE'
}

export enum MRP_MATERIAL_TYPE_VALUE {
  WIP_MATERIAL = 'WIP Material',
  FINISHED_GOODS = 'Finished Good',
  RAW_MATERIAL = 'Raw Material',
  SERVICE = 'Non-Tracked'
}

export enum PRODUCE_PRODUCT_TYPE {
  SCRAP = 'SCRAP',
  CO_PRODUCT = 'COPRODUCT',
  NONE = 'NONE'
}

export const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

export const MONTHS_WITH_NUMBER = [
  { name: 'Jan', num: '01' },
  { name: 'Feb', num: '02' },
  { name: 'Mar', num: '03' },
  { name: 'Apr', num: '04' },
  { name: 'May', num: '05' },
  { name: 'Jun', num: '06' },
  { name: 'Jul', num: '07' },
  { name: 'Aug', num: '08' },
  { name: 'Sep', num: '09' },
  { name: 'Oct', num: '10' },
  { name: 'Nov', num: '11' },
  { name: 'Dec', num: '12' }
];
export const PAYMENT_LINK_PLACEHOLDER = '{{payment_link}}';
export const STRIPE_PAYMEMT_LIMIT = 0.5;
export const DOCUMENT_CODE_FUTURE_PLACEHOLDER = '{{document_future_code}}';
export const CONTACT_CODE_FUTURE_PLACEHOLDER = '{{contact_future_code}}';
export const DOCUMENT_AMOUNT_FUTURE_PLACEHOLDER = '{{amount_future_code}}';
export const PAYMENT_LINK_FUTURE_PLACEHOLDER = '{{payment_link_future_code}}';
export const INVOICE_CODE_PLACEHOLDER = '{{invoice_code}}';
export const COUNTRY_PLACE_HOLDER = '/$CC$';

//module Name
export const QUOTE = 'QUOTE';
export const RFQ = 'RFQ';
export const PURCHASE_REQUEST_FOR_QUOTES = 'PURCHASE_REQUEST_FOR_QUOTES';
export const INVOICE = 'INVOICE';
export const SALES_ORDER = 'SALES_ORDER';
export const ORDER = 'ORDER';
export const PURCHASE_ORDER = 'PURCHASE_ORDER';
export const REQUISITION = 'REQUISITION';
export const PURCHASE_BILL = 'PURCHASE_BILL';
export const PURCHASE_INVOICE = 'PURCHASE_INVOICE';
export const BUY_ORDER = 'ORDER';
export const BUY_BILL = 'BILLS';
export const IMPORT = 'IMPORT';
export const GET_STARTED = 'GET_STARTED';
export const DEBIT_NOTE = 'DEBIT_NOTE';
export const CREDIT_NOTE = 'CREDIT_NOTE';
export const DEPOSIT = 'DEPOSIT';
export const EXPENSE = 'EXPENSE';
export const OPENING_DOCUMENT_LABEL = 'Opening document...';
export interface MonthFormat {
  name: string;
  num: string;
  fromDate?: string;
  toDate?: string;
  columnKey?: string;
  days?: number;
}
export const MONTHS_FULL: MonthFormat[] = [
  {
    name: 'JANUARY',
    num: '01',
    fromDate: '2023-01-01',
    toDate: '2023-01-31',
    days: 31
  },
  {
    name: 'FEBRUARY',
    num: '02',
    fromDate: '2023-02-01',
    toDate: '2023-02-28',
    days: 28
  },
  {
    name: 'MARCH',
    num: '03',
    fromDate: '2023-03-01',
    toDate: '2023-03-31',
    days: 31
  },
  {
    name: 'APRIL',
    num: '04',
    fromDate: '2023-04-01',
    toDate: '2023-04-30',
    days: 30
  },
  {
    name: 'MAY',
    num: '05',
    fromDate: '2023-05-01',
    toDate: '2023-05-31',
    days: 31
  },
  {
    name: 'JUNE',
    num: '06',
    fromDate: '2023-06-01',
    toDate: '2023-06-30',
    days: 30
  },
  {
    name: 'JULY',
    num: '07',
    fromDate: '2023-07-01',
    toDate: '2023-07-31',
    days: 31
  },
  {
    name: 'AUGUST',
    num: '08',
    fromDate: '2023-08-01',
    toDate: '2023-08-31',
    days: 31
  },
  {
    name: 'SEPTEMBER',
    num: '09',
    fromDate: '2023-09-01',
    toDate: '2023-09-30',
    days: 30
  },
  {
    name: 'OCTOBER',
    num: '10',
    fromDate: '2023-10-01',
    toDate: '2023-10-31',
    days: 31
  },
  {
    name: 'NOVEMBER',
    num: '11',
    fromDate: '2023-11-01',
    toDate: '2023-11-30',
    days: 30
  },
  {
    name: 'DECEMBER',
    num: '12',
    fromDate: '2023-12-01',
    toDate: '2023-12-31',
    days: 31
  }
];

export const QUARTER_FULL: MonthFormat[] = [
  { name: 'QUARTER 1', num: '01' },
  { name: 'QUARTER 2', num: '02' },
  { name: 'QUARTER 3', num: '03' },
  { name: 'QUARTER 4', num: '04' }
];
export type COLUMN_CONFIG_TYPE = {
  name: string;
  type: typeof INPUT_TYPE;
  key: string;
  index: number;
  width: number;
  editable: boolean;
  required: boolean;
  hidden: boolean;
  columnCode: string;
  id: string;
  [key: string]: any; //other properties
};
export const MRP_QUARTER_FULL: MonthFormat[] = [
  {
    name: 'QUARTER 1 (Jan-Mar)',
    columnKey: 'JANUARY - MARCH',
    num: '01',
    fromDate: '2023-01-01',
    toDate: '2023-03-31'
  },
  {
    name: 'QUARTER 2 (Apr-Jun)',
    columnKey: 'APRIL - JUNE',
    num: '02',
    fromDate: '2023-04-01',
    toDate: '2023-06-30'
  },
  {
    name: 'QUARTER 3 (July-Sept)',
    columnKey: 'JULY - SEPTEMBER',
    num: '03',
    fromDate: '2023-07-01',
    toDate: '2023-09-30'
  },
  {
    name: 'QUARTER 4 (Oct-Dec)',
    columnKey: 'OCTOBER - DECEMBER',
    num: '04',
    fromDate: '2023-10-01',
    toDate: '2023-12-31'
  }
];

export const ACTIVITY_DATE_FORMAT = 'EEE, MMM dd yyyy, HH:mm aa';
export const PERIOD = ['MONTHLY', 'QUARTERLY', 'YEARLY', 'CUSTOM'];

export const FORECAST_PERIODS = {
  WEEKLY: 'WEEKLY',
  QUARTERLY: 'QUARTERLY',
  MONTHLY: 'MONTHLY',
  YEARLY: 'YEARLY'
};

export const DAY_INTERVAlS = [
  'Current',
  '1-30 days',
  '31-60 days',
  '61-90 days',
  'Over 90 days'
];

export const EMAIL_PAYLOAD = {
  application: 'application',
  asOfDate: 'asOfDate',
  bcc: 'bcc',
  body: 'body',
  category: 'category',
  category_name: 'categoryName',
  contact: 'contact',
  contactCode: 'contactCode',
  contactCodeWithName: 'contactCodeWithName',
  details: 'details',
  doc_subtotal: 'docSubTotal',
  endDate: 'endDate',
  fileFormat: 'fileFormat',
  fromDate: 'fromDate',
  groupByCurrency: 'groupByCurrency',
  limit: 'limit',
  module_name: 'moduleName',
  page: 'page',
  replyTo: 'replyTo',
  reportCode: 'reportCode',
  senderName: 'senderName',
  startDate: 'startDate',
  statementOfAccountRequest: 'statementOfAccountRequest',
  subject: 'subject',
  tax_amount: 'itemTaxAmt',
  tenant_billing: 'tenantBillingAddr',
  tenant_contact: 'tenantContactNum',
  tenant_name: 'tenantName',
  textArea: 'textArea',
  to: 'to',
  total_amount: 'totalAmt',
  total_discount: 'totalDiscount',
  total_item_amount: 'totalItemAmt',
  total_tax: 'totalTax',
  vendor: 'vendor',
  cc: 'cc'
};

export const PRINT_PAYLOAD_KEYS = {
  total_item_amount: 'totalItemAmt',
  itemCount: 'SNo',
  item_description: 'description',
  invoiceNumber: 'invoiceNumber',
  quoteNumber: 'quotationNumber',
  credit: 'CREDIT_NOTE',
  receive: 'RECEIVE_PAYMENT',
  module_name: 'moduleName',
  category_name: 'categoryName',
  orderNo: 'orderNo',
  BillNo: 'BillNo',
  IS_CUSTOM_TEMPLATE: 'customTemplate',
  DOCUMENT_TYPE_KEY: 'documentType',
  DOCUMENT_CODE_KEY: 'documentCode',
  template_id: 'templateId'
};

export enum TEMPLATE_CATEGORY {
  INVOICE = 'INVOICE',
  QUOTE = 'QUOTE',
  REQUISITION = 'REQUISITION',
  SUPPLIER_QUOTE = 'SUPPLIER_QUOTE',
  REQUEST_FOR_QUOTATION = 'REQUEST_FOR_QUOTATION',
  PURCHASE_REQUEST_FOR_QUOTES = 'PURCHASE_REQUEST_FOR_QUOTES',
  SALES_ORDER = 'SALES_ORDER',
  PAYMENT = 'PAYMENT',
  CHEQUE = 'CHEQUE',
  ORDER = 'ORDER',
  BILLS = 'BILLS',
  FULFILLMENT = 'FULFILLMENT',
  GOODS_RECEIPT = 'GOODS_RECEIPT',
  EXPENSE = 'EXPENSE',
  DEPOSIT = 'DEPOSIT',
  EWAY_BILL = 'EWAY_BILL',
  EXPENSE_BILL = 'EXPENSE_BILL',
  JOB_WORK_OUT_ORDER = 'JOB_WORK_OUT_ORDER',
  SOA_CUSTOMER = 'SOA_CUSTOMER',
  SOA_VENDOR = 'SOA_VENDOR'
}

export enum REVENUE_PLANS_ON {
  REVENUE_ARRANGEMENT = 'REVENUE_ARRANGEMENT',
  FULFILMENT = 'FULFILMENT',
  BILLING = 'BILLING'
}

export enum MODULES_NAME {
  ESTIMATE = 'ESTIMATE',
  BOM_ASSEMBLY = 'BOMASSEMBLY',
  REV_REC = 'REV_REC',
  ASSET = 'FIXEDASSET',
  CONTACT = 'CONTACT',
  CONTACT_ADDRESS = 'CONTACTADDRESS',
  EXPENSE_BILL = 'EXPENSE_BILL',
  FORM_1099_CONTRACTOR = 'FORM_1099_CONTRACTOR',
  PRODUCT = 'PRODUCT',
  MATERIAL_MASTER = 'MATERIAL_MASTER',
  INVOICE = 'INVOICE',
  QUOTES = 'QUOTES',
  QUOTATION = 'QUOTATION',
  PURCHASE_ORDER = 'PURCHASE ORDER',
  REQUISITION = 'REQUISITION',
  PURCHASE_REQUISITION = 'PURCHASE_REQUISITION',
  SALESORDER = 'SALESORDER', // This is used only for cusom fields currently.
  JOURNAL = 'JOURNAL',
  ACCOUNTING = 'ACCOUNTING',
  BILL = 'BILL',
  SALES_ORDER = 'SALES_ORDER',
  EXPENSE = 'EXPENSE',
  DEPOSIT = 'DEPOSIT',
  DEBITNOTE = 'DEBITNOTE',
  CREDITNOTE = 'CREDITNOTE',
  DRAFTS = 'DRAFTS',
  SETTINGS = 'SETTINGS',
  JOURNAL_ENTRY = 'JOURNAL_ENTRY',
  COA = 'CHART_OF_ACCOUNTS',
  ACCOUNT = 'ACCOUNT',
  WAREHOUSE = 'WAREHOUSE',
  IMPORT = 'IMPORT',
  ORDER = 'ORDER',
  OB_INVOICES = 'OB_INVOICES',
  PRICE_LIST = 'PRICE_LIST',
  PRICE_BOOK = 'PRICE_BOOK',
  QUOTE = 'QUOTE',
  BANK = 'BANK',
  REVENUE_ARRANGEMENT = 'REVENUE_ARRANGEMENT',
  UPDATE_REVENUE_ARRANGEMENT = 'UPDATE_REVENUE_ARRANGEMENT',
  APPS = 'APPS',
  EWAY_BILL = 'EWAY_BILL',
  CLASSES = 'CLASSES',
  BANK_DETAILS = 'BANK_DETAILS',
  PAYMENT_TERMS = 'PAYMENT_TERMS',
  PREPAYMENT = 'EXPENSE_PREPAYMENT',
  RULES = 'RULES',
  AUDIT_LOG = 'AUDIT_LOG',
  CURRENCY = 'CURRENCY',
  TAX = 'TAX',
  IMPORT_LOG = 'IMPORT_LOG',
  IMPORT_DRAFT = 'IMPORT_DRAFT',
  REV_REC_JE_LOGS = 'REV_REC_JE_LOGS',
  UOM = 'UOM',
  UOM_SCHEMA = 'UOM_SCHEMA',
  TDS_RATES = 'TDS_RATES',
  NATURE_OF_PAYMENT = 'NATURE_OF_PAYMENT',
  DEDUCTEE_TYPE = 'DEDUCTEE_TYPE',
  TCS_RATES = 'TCS_RATES',
  FY_CLOSING = 'FY_CLOSING',
  REPORTS = 'REPORTS',
  DEBIT_REPORT = 'Debit Notes',
  CREDIT_REPORT = 'Credit Notes',
  INVOICE_REPORT = 'Invoice',
  TENANT = 'TENANT',
  BILL_REPORT = 'Bill List',
  RECONCILIATION_LOG_REPORT = 'Reconciliation Log Report',
  GST_REPORT_LOG = 'GST Log Report',
  ORDER_REPORT = 'PO List',
  STOCK_MOVEMENT_REPORT = 'Stock Movement',
  QUOTATION_REPORT = 'Quotation Report',
  SALES_BY_PRODUCT = 'Sales By Product',
  SALES_BY_CONTACT = 'Sales By Contact',
  FULFILLMENT_REPORT = 'Fulfillment Report',
  GOODS_RECEIVED_NOTES = 'Goods Received Report',
  PURCHASE_ORDER_DETAIL_REPORT = 'Purchase Order Detail Report',
  SALES_ORDER_DETAIL_REPORT = 'Sales Order Detail Report',
  FULFILLMENT_REPORT_IN = 'Delivery Order Report',
  PURCHASE_BY_PRODUCT = 'Purchase By Product',
  PURCHASE_BY_CONTACT = 'Purchase By Contact',
  RECEIPT_LIST = 'Receipt List',
  PAYMENT_LIST = 'Payment_List',
  AGED_RECEIVABLES_REPORT = 'Aged Receivables',
  MILESTONE_REPORT = 'Payment Milestone',
  AGED_PAYABLES_REPORT = 'Aged Payables',
  INVOICE_EXPORT = 'INVOICE_SALES',
  SALES_ORDER_EXPORT = 'SALES_ORDER',
  PURCHASE_ORDER_EXPORT = 'ORDERS',
  REQUISITION_EXPORT = 'REQUISITION',
  BILLS_EXPORT = 'INVOICE_PURCHASE',
  SALES_TAX_RETURN = 'SALES_TAX_RETURN',
  SERVICE_TAX_RETURN = 'SERVICE_TAX_RETURN',
  VARIANT = 'VARIANT',
  PICK_PACK_SHIP_ALL = 'PICK_PACK_SHIP_ALL',
  PICK_PACK_SHIP_PENDING = 'PICK_PACK_SHIP_PENDING',
  PICK_PACK_SHIP_PICKING = 'PICK_PACK_SHIP_PICKING',
  PICK_PACK_SHIP_PACKING = 'PICK_PACK_SHIP_PACKING',
  PICK_PACK_SHIP_SHIPPING = 'PICK_PACK_SHIP_SHIPPING',
  AUTOMATIONS = 'Automations',
  INVENTORY_TAB = 'Inventory',
  PICK_PACK_SHIP_ALL_LINE_ITEM = 'allLineItems',
  PICK_PACK_SHIP_PICK_LINE_ITEM = 'pickLineItem',
  AUDIT_TRAIL = 'AUDIT_TRAIL',
  CUSTOM_FIELD_LIST = 'CUSTOM_FIELD_LIST',
  LOCATION_LIST = 'LOCATION_LIST',
  CUSTOM_REPORTS = 'CUSTOM_REPORTS',
  PRINT_CHECKS = 'PRINT_CHECKS',
  BOOKKEEPER_TEAM = 'BOOKKEEPER_TEAM',
  BOOKKEEPER_CLIENT = 'BOOKKEEPER_CLIENT',
  ITEM_LANDED_COST = 'ITEM_LANDED_COST',
  ITEM_LANDED_COST_REPORT = 'Item Landed Cost Report',
  AGENCIES = 'Agencies',
  EWB_REPORTS = 'E-Way Bill Report',
  PICK_LIST = 'PICKLIST',
  PACK_LIST = 'PACKLIST',
  SHIP_LIST = 'SHIPLIST',
  BANK_DOCUMENTS_DETAIL = 'BANK_DOCUMENTS_DETAIL',
  BUDGETING = 'BUDGETING',
  OB_INVENTORY = 'OB_INVENTORY',
  CONSOLIDATION_FINANCIAL_REPORT = 'CONSOLIDATION_FINANCIAL_REPORT',
  CONSOLIDATION_FINANCIAL_REPORT_LIST = 'CONSOLIDATION_FINANCIAL_REPORT_LIST',
  SALES_SERVICE_DETAILS = 'SALES_SERVICE_DETAILS',
  OPERATIONS = 'OPERATIONS',
  MACHINE_MAINTAINCE_AND_TOOLS = 'MACHINE_MAINTAINCE_AND_TOOLS',
  MACHINE_SCHEDULING = 'MACHINE_SCHEDULING',
  FORECASTING = 'FORECASTING',
  MRP_PRODUCTS = 'MRP_PRODUCTS',
  MRP_HOLIDAY_LIST = 'MRP_HOLIDAY_LIST',
  WORKSTATIONS = 'mrp_workstations',
  WORK_ORDERS = 'MRP_WORK_ORDER',
  MRP_QUALITY_CHECK = 'MRP_QUALITY_CHECK',
  MRP_ROUTING_TEMPLATES = 'MRP_ROUTING_TEMPLATES',
  MRP_REPORTS_YIELD_SUMMARY = 'MRP_REPORTS_YIELD_SUMMARY',
  MRP_REPORTS_YIELD_DETAILED_SUMMARY = 'MRP_REPORTS_YIELD_DETAILED_SUMMARY',
  MRP_REPORTS_DAILY_CONSUMPTION = 'MRP_REPORTS_DAILY_CONSUMPTION',
  PRODUCTION_PLAN = 'MRP_PRODUCTION_PLAN',
  JOB_CARDS = 'JOB_CARDS',
  MRP_OPERATOR_LIST = 'MRP_OPERATOR_LIST',
  BOM_EXPLODE = 'BOM_EXPLODE',
  BOM_EXPLOSION = 'BOM_EXPLOSION',
  BOM_EXPLOSION_SUB_GRID = 'BOM_EXPLOSION_SUB_GRID',
  STOCK_ADJUSTMENT = 'STOCKADJUSTMENT',
  STOCK_TRANSFER = 'STOCKTRANSFER',
  STOCK_ISSUE = 'STOCKISSUE',
  STOCK_REQUEST = 'STOCKREQUEST',
  JOB_WORK_OUT = 'JOBWORKOUTORDER',
  FORECAST = 'FORECAST',
  MASTER_FORECAST = 'MASTER_FORECAST',
  SALES_BY_CLASS = 'SALES_BY_CLASS',
  JOB_BRIEF = 'JOB_BRIEF',
  RGASSET = 'RGASSET',
  FIXEDASSET = 'FIXEDASSET',
  MULTI_COMPANY_CONSOLIDATION_FINANCIAL_REPORT_LIST = 'MULTI_COMPANY_CONSOLIDATION_FINANCIAL_REPORT_LIST',
  AMORTIZATION = 'AMORTIZATION',
  DEFERRED_EXPENSE = 'DEFERRED_EXPENSE',
  VAT_RETURN = 'VAT_RETURN',
  AMORTIZATION_TEMPLATES = 'AMORTIZATION_TEMPLATES',
  AMORTIZATION_SCHEDULE = 'AMORTIZATION_SCHEDULE',
  AMORTIZATION_JE = 'AMORTIZATION_JE',
  LINK_JOURNAL_ENTRY = 'LINK_JOURNAL_ENTRY',
  PRINT_TEMPLATE = 'PRINT_TEMPLATE',
  LABEL_TEMPLATE = 'LABEL_TEMPLATE',
  PRODUCTION_CONSUMPTION_DETAILS = 'PRODUCTION_CONSUMPTION_DETAILS',
  MRP_JOB_CARD = 'JOB_CARD',
  WORK_ORDER = 'WORK_ORDER',
  //*info added for Custom-field only
  MRP_WORK_ORDER = 'WORKORDER',
  MRP_JOB_WORK_OUT = 'JOB_WORK_OUT',
  MRP_OPERATOR = 'OPERATOR',
  MRP_PRODUCT = 'PRODUCT',
  MRP_OPERATION = 'OPERATION',
  MRP_MACHINE = 'MACHINE',
  BATCH_SERIAL = 'BATCHSERIAL',
  MRP_MACHINE_SCHEDULE = 'SCHEDULE',
  MRP_WORKSTATION = 'WORKSTATION',
  WORKORDER_DOCUMENT = 'WORKORDER_DOCUMENT',
  SKU_ORDER_TRACKING = 'SKU Wise Order Tracking',
  SALES_TAX_AGENCY = 'Sales Tax Report',
  USER = 'USER',
  ROLE = 'ROLE',
  MASTER_FORECAST_DETAILS = 'MASTER_FORECAST_DETAILS',
  VALUATION_BY_WAREHOUSE = 'Valuation By Warehouse',
  VALUATION_BY_WAREHOUSE_DETAILS = 'Valuation By Warehouse Details',
  PICK_PACK_SHIP = 'PICKPACKSHIP',
  GOODS_RECEIPT_LIST = 'Goods Receipt List',
  GOODS_RECEIPT_TOLERANCE_LEVEL_REPORT = 'Goods Receipt - Tolerance Level report',
  FULFILLMENT_TOLERANCE_LEVEL_REPORT = 'Fulfillment - Tolerance Level report',
  DELIVERY_ORDER_TOLERANCE_LEVEL_REPORT = 'Delivery Order - Tolerance Level report',
  TOLERANCE_LEVEL_REJECTION_STOCK_REPORT = 'GR FF Tolerance Level Rejection Stock Report',
  SO_TO_WO_CONVERSION_REPORT = 'SO_WO_CONVERSION_REPORT',
  SALES_TAX_LIABILITY_DETAILS_REPORT = 'SALES_TAX_LIABILITY_DETAILS_REPORT',
  ORDER_LEVELS_REPORT = 'Order Levels Report',
  QC_DOCUMENT_DETAILS = 'QC_DOCUMENT_DETAILS',
  MACHINE_ASSET_MAINTAINCE_SCHEUDLE = 'MACHINE_ASSET_MAINTAINCE_SCHEUDLE',
  GENERATE_BANK_FILE_INVOICE = 'GENERATE_BANK_FILE_INVOICE',
  GENERATE_BANK_FILE_BILL = 'GENERATE_BANK_FILE_BILL',
  SECURITY_GATE_ENTRY = 'GATEENTRY',
  UPLOAD_TO_FTP_LOGS = 'UPLOAD_TO_FTP_LOGS',
  PAYMENT_LOGS = 'PAYMENT_LOGS',
  SALES_PERSON_MASTER = 'SALES_PERSON_MASTER',
  BANK_BOOK_REPORT = 'BANK_BOOK_REPORT',
  CONSOLIDATE_BANK_BOOK_REPORT = 'CONSOLIDATE_BANK_BOOK_REPORT',
  WHATSAPP_API_CONNECTIONS = 'WHATSAPP_API_CONNECTIONS',
  REQUEST_FOR_QUOTATION = 'REQUESTFORQUOTATION',
  RFQ_CONTACT_MASTER = 'RFQ_CONTACT_MASTER',
  PREFERRED_VENDOR = 'PREFERREDVENDOR',
  PRODUCTS_BY_VENDOR = 'PRODUCTS_BY_VENDOR'
}

export enum CUSTOM_NUMBER_INPUT_MODULES {
  CONTACT = 'CONTACT',
  INVOICE = 'SALES_INVOICE',
  QUOTE = 'QUOTE',
  SALES_ORDER = 'SALES_ORDER',
  PURCHASE_ORDER = 'ORDER',
  REQUISITION = 'REQUISITION',
  PURCHASE_REQUEST = 'PURCHASE_REQUEST',
  BILL = 'PURCHASE_INVOICE',
  EXPENSE_BILL = 'PURCHASE_INVOICE',
  PRODUCT = 'PRODUCT',
  DEBIT_NOTE = 'DEBIT_NOTE',
  CREDIT_NOTE = 'CREDIT_NOTE',
  JOURNAL_ENTRY = 'JE',
  ACCOUNT = 'ACCOUNT',
  BUILD_ASSEMBLY = 'BUILD_ASSEMBLY',
  BUY = 'BUY',
  SELL = 'SELL',
  WORK_ORDER = 'WORK_ORDER',
  PRODUCTION_PLAN = 'PRODUCTION_PLAN',
  JOB_CARD = 'JOB_CARD',
  OPERATOR = 'OPERATOR',
  STOCKS = 'STOCKSs',
  JOB_WORK_OUT = 'JOB_WORK_OUT_ORDER',
  FIXED_ASSET = 'FIXED_ASSET',
  SECURITY_ENTRY_GATE = 'GATE_ENTRY',
  MACHINE = 'MACHINE',
  PURCHASE_REQUEST_FOR_QUOTE = 'PURCHASE_REQUEST_FOR_QUOTE'
}

export enum COA_OPTIONS {
  CASH = 'Cash',
  BANK = 'Bank'
}

export enum TAX_TYPES {
  PURCHASE = 'PURCHASE',
  SELL = 'SELL',
  BOTH = 'BOTH',
  SALES = 'SALES'
}
export enum VALIDATIONS_TYPE_LIST {
  REQUIRED = 'required',
  MAX_LENGTH = 'maxLength'
}

export enum JAVASCRIPT_DATA_TYPE {
  BOOLEAN = 'boolean',
  NUMBER = 'number',
  BIGINT = 'bigint',
  OBJECT = 'object',
  STRING = 'string',
  SYMBOL = 'symbol',
  UNDEFINED = 'undefined',
  FUNCTION = 'function',
  ARRAY = 'object' // array type is object in js
}

export enum FORM_ELEMENTS {
  DROPDOWN = 'dropdown',
  INPUT = 'input',
  TEXT_AREA = 'text_area',
  SEARCHABLE = 'searchable',
  TOGGLE = 'toggle',
  DATE = 'date'
}

export enum DropdownSelectionType {
  SINGLE = 'single',
  MULTIPLE = 'multiple'
}

export enum INPUT_VIEW_DIRECTION {
  HORIZONTAL = 'HORIZONTAL',
  VERTICAL = 'VERTICAL'
}
export enum CURRENCY_CONSTANTS {
  SortDir = 'ASC',
  Sort = 'currencyName',
  Dir = 'ASC'
}

export enum AGENCIES_CONSTANTS {
  SortDir = 'ASC',
  Sort = 'name',
  Dir = 'ASC'
}

export enum CURRENCY_EXCHANGE_CONSTANTS {
  SortDir = 'ASC',
  Sort = 'currencyStatus',
  Dir = 'ASC'
}

export enum CURRENCY_HISTORY_CONSTANTS {
  SortDir = 'desc',
  Sort = 'exchangeRateDate',
  Dir = 'desc'
}

export enum WAREHOUSE_FIELD_KEY_NAMES {
  NAME = 'name',
  ADDRESS1 = 'address1',
  ADDRESS2 = 'address2',
  STATE = 'state',
  COUNTRY = 'country',
  POSTAL_CODE = 'postalCode',
  CITY = 'city'
}

export enum CONTACT_FIELD_KEY_NAMES {
  NAME = 'name',
  EMAIL = 'emailId',
  PAN_NUMBER = 'pan',
  CURRENCY = 'currencyCode',
  NUMBERING_FORMAT = 'sequenceFormat',
  PAYMENT_TERM = 'paymentTermCode',
  PAYABLE_ACCOUNT = 'payableAccountCode',
  RECEIVEABLE_ACCOUNT = 'receivableAccountCode',
  GST_TREATMENT = 'gstTreatment',
  GST_IN = 'gstin',
  CUSTOMER_TYPE = 'customerType',
  VENDOR_TYPE = 'vendorType',
  ADDRESS = 'address1',
  ADDRESS2 = 'address2',
  STATE = 'state',
  COUNTRY = 'country',
  POSTAL_CODE = 'postalCode',
  CITY = 'city',
  ACCOUNT_ADD = 'newAccountAdd',
  TAX = 'tax',
  NATURE_OF_INCOME_PAYMENT = 'nip',
  CONTACT_UEN = 'uen',
  PEPPOL_ID = 'peppolId',
  TAX_NUMBER = 'taxNumber',
  BUSINESS_UNIT = 'businessUnit',
  ATTENTION_TO = 'attentionTo',
  NPWP_NUMBER = 'npwpNumber',
  NIK_NUMBER = 'nikNumber',
  DEDUCTEE_TYPE = 'deducteeType',
  VENDER_TAN = 'venderTan',
  TAX_EXEMPT = 'taxExempt',
  CONTACT_SG_GOVT = 'contactSgGovt',
  RESIDENT = 'resident',
  NON_DEDUCTION_REASON = 'nonDeductionReason',
  TDS_RATE = 'tdsRate',
  CERTIFICATE_NUMBER = 'certificateNumber',
  FROM_DATE = 'fromDate',
  TO_DATE = 'toDate',
  IS_TDS_APPLICABLE = 'isTdsApplicable',
  HIGHER_RATE = 'higherRate',
  LOWER_DEDUCTION_APPLICABLE = 'lowerDeductionApplicable',
  EXEMPTION_LIMIT = 'exemptLimit',
  CUSTOMER_TAX_TYPE = 'customerTaxType',
  VENDOR_TAX_TYPE = 'vendorTaxType',
  BUSINESS_RESGISTRATION_NUMBER = 'businessRegistrationNumber',
  SERVICE_TAX_REGISTRATION_NUMBER = 'serviceTaxRegistrationNumber',
  SALES_TAX_REGISTRATION_NUMBER = 'salesTaxRegistrationNumber',
  CUSTOMER_TYPE_MALAYSIA = 'customerTypeMalaysia',
  VENDOR_TYPE_MALAYSIA = 'vendorTypeMalaysia',
  EXEMPTION_CRITERIAL_MALAYSIA = 'exemptionCriterialMalaysia',
  EXEMPTION_CERTIFICATE_MALAYSIA = 'exemptionCertificateMalaysia',
  VAT_REGISTRATION_UAE = 'vatRegistraionUae',
  TAX_TREATMENT_UAE = 'taxTreatmentUae',
  MOBILE_NUMBER = 'contactNumber',
  CREDIT_LIMIT = 'creditLimit',
  CONTACT_NAME = 'contactName',
  INTERCOMPANY_TYPE = 'intercompanyType',
  UK_BUSINESS_REGISTRATION_NUMBER = 'ukBusinessRegistrationNo',
  UK_DEFAULT_PURCHASE_TAX_RATE = 'ukDefaultPurchaseTaxRate',
  UK_DEFAULT_SALES_TAX_RATE = 'ukDefaultSalesTaxRate',
  PURCHASE_TAX_RATE_ISRAEL = 'purchaseTaxCodeIsrael',
  SALES_TAX_RATE_ISRAEL = 'salesTaxCodeIsrael',
  UK_VAT_IDENTIFICATION_NUMBER = 'ukVatIdentificationNo',
  TAX_PAYER_ID_ISRAEL = 'taxPayerIdIsrael',
  ASSESSING_OFFICER_NUMBER_ISRAEL = 'assessingOfficerNumberIsrael',
  TYPE_OF_COMPANY_ISRAEL = 'typeOfCompanyIsrael',
  WITHHOLDING_TAX_TYPE_ID_ISRAEL = 'withHoldingTaxTypeIsraelId',
  WITHHOLDING_TAX_PAYMENT_GROUP_ID_ISRAEL = 'withHoldingTaxPaymentGroupIsraelId',
  WHT_APPLICABLE_ISRAEL = 'whtApplicableIsrael',
  TAX_CERTIFICATE_VALIDITY_DATE = 'taxCertificateValidityDateIsrael',
  IS_INCLUDED_TAX_REPORTING_SOURCE_ISRAEL = 'isIncludedTaxReportingAtSourceIsrael',
  IS_SELF_INVOICEING_ISRAEL = 'isSelfInvoicingIsrael',
  OTHER_EMAILS = 'otherEmails',
  CUSTOMER_ID = 'customerId',
  CUSTOMER_INTERNAL_ID = 'customerInternalId',
  TYPE = 'type',
  BANK_ACCOUNT_TYPE = 'bankAccountType',
  BANK_ACCOUNT_NUMBER = 'bankAccountNumber',
  PAYMENT_FILE_FORMAT = 'paymentFileFormat',
  COUNTRY_CHECK = 'countryCheck',
  BANK_CODE = 'bankCode',
  PROCESSOR_CODE = 'processorCode',
  RECEIVING_DFI_ID = 'receivingDfiId',
  CHECK_DIGIT = 'checkDigit',
  AGENT = 'agent',
  CLIENT_NUMBER = 'clientNumber',
  TAX_CODE = 'txnCode',
  CAD_FINANCIAL_INST_TRANSIT = 'cadFinancialInstTransit',
  PROCESSING_CENTER_ID = 'processingCenterId',
  ELIGIBLE = 'is1099Eligible'
}
export enum COA_FIELD_KEY_NAMES {
  ACCOUNT_CODE = 'accountCode',
  ACCOUNT_GROUP_ID = 'accountGroupId',
  CURRENCY = 'currency',
  CUSTOM_FIELD = 'customField',
  DESCRIPTION = 'description',
  IS_CREDIT_CARD = 'isCreditCard',
  IS_TDS_APPLICABLE = 'isTdsApplicable',
  NAME = 'name',
  OPENING_BALANCE = 'openingBalance',
  OPENING_BALANCE_CD_TYPE = 'openingBalanceCdType',
  TAXCODE = 'taxCode',
  NIP = 'nip',
  NATURE_OF_INCOME_PAYMENT = 'nip',
  CREDIT_CARD_ACCOUNT = 'Credit Card Account'
}
export enum CONTACT_FIELD_SECTION_NAMES {
  ACCOUNTING_INFO = 'accounting',
  GENERAL_INFO = 'generalInfo',
  ADDRESS_INFO = 'addressInfo',
  ADDITIONAL_INFO = 'additionalInfo',
  ADDITIONAL_PAYMENT_INFO = 'additionalPaymentInfo'
}
export enum COA_FIELD_SECTION_NAMES {
  Coa_Info = 'CoaInfo'
}
export enum PRICE_FIELD_SECTION_NAMES {
  PRICE_INFO = 'PriceInfo'
}
export enum COA_DROPDOWN_TYPE {
  Type = 'type',
  OTHER = 'other',
  NIP = 'nip',
  TAX = 'tax'
}

export enum TAX_SYSTEM {
  US = 'US',
  SG = 'SG',
  INDIA_GST = 'IN',
  INDONESIA = 'ID',
  PHILIPPINES = 'PH',
  DEFAULT = 'DEFAULT',
  MALAYSIA = 'MY',
  UAE = 'AE',
  AUSTRALIA = 'AU',
  CANADA = 'CA',
  NL = 'NL',
  BE = 'BE',
  NZ = 'NZ',
  UK = 'UK',
  IL = 'IL',
  DE = 'DE',
  SA = 'SA'
}

export enum GST_TYPE {
  DEFAULT,
  INTER,
  INTRA,
  EXEMPT
}

export enum MODULE_NAME_FOR_STORAGE_UTILITY {
  SELL = 'SELL',
  BUY = 'BUY'
}
export enum CHECK_FORMAT_NUMBER_TYPE {
  FORMAT_A = 'FORMAT_A',
  FORMAT_B = 'FORMAT_B',
  FORMAT_C = 'FORMAT_C'
}

// space is replaced with _S_
export enum DOC_TYPE {
  QUOTE = 'QUOTATION',
  MACHINE = 'MACHINE',
  SALES_ORDER = 'SALES_ORDER',
  BOM_ASSEMBLY = 'BOM_ASSEMBLY',
  INVOICE = 'SALES_INVOICE',
  ORDER = 'PURCHASE_ORDER',
  REQUISITION = 'REQUISITION',
  GOODS_RECEIVED_NOTE = 'GOODS_RECEIVED_NOTE',
  PURCHASE_REQUISITION = 'PURCHASE_REQUISITION',
  SERVICE_REQUISITION = 'SERVICE_REQUISITION',
  PURCHASE_REQUEST = 'PURCHASE_REQUEST',
  SUPPLIER_QUOTE = 'SUPPLIER_QUOTE',
  RFQ = 'RFQ',
  PURCHASE_REQUEST_FOR_QUOTES = 'PURCHASE_REQUEST_FOR_QUOTES',
  BILL = 'PURCHASE_INVOICE',
  EXPENSE_PURCHASE_INVOICE = 'EXPENSE_PURCHASE_INVOICE',
  REV_ARR = 'REV_ARR',
  FA_BILL = 'FA_BILL',
  FA_ORDER = 'FA_ORDER',
  SALES_TAX_PAYMENT = 'SALES_TAX_PAYMENT',
  EXPENSE_BILL = 'EXPENSE_BILL',
  CLOSED = 'CLOSED',
  SALES_RETURN = 'SALES_RETURN',
  OPENING_INVOICE = 'OPENING_INVOICE',
  OPENING_BILL = 'OPENING_BILL',
  DEBIT_NOTE = 'DEBIT_NOTE',
  CREDIT_NOTE = 'CREDIT_NOTE',
  EXPENSE = 'EXPENSE',
  SELL_SIDE_CLOSED = 'SELL_SIDE_CLOSED_DOCUMENTS',
  BUY_SIDE_CLOSED = 'BUY_SIDE_CLOSED_DOCUMENTS',
  RECEIVE_PAYMENT = 'RECEIVE_PAYMENT',
  MAKE_PAYMENT = 'MAKE_PAYMENT',
  PREPAYMENT = 'EXPENSE_PREPAYMENT',
  DEPOSIT_ADVANCED_PAYMENT = 'DEPOSIT_ADVPAYMENT',
  DEPOSIT = 'DEPOSIT',
  JOURNAL_ENTRY = 'JOURNAL_ENTRY',
  PICKLIST = 'pick-pack/PICKLIST',
  PACKLIST = 'pick-pack/PACKLIST',
  SHIPLIST = 'pick-pack/SHIPLIST',
  CHEQUE = 'CHEQUE',
  NORMAL_JE = 'NORMAL_JE',
  // book_specific_je
  BOOK_SPECIFIC_JE = 'BOOK_SPECIFIC_JE',
  WORK_ORDER = 'WORK_ORDER',
  STOCK_ADJUSTMENT = 'STOCK_ADJUSTMENT',
  STOCK_TRANSFER = 'STOCK_TRANSFER',
  FUND_TRANSFER_JE = 'BANK_TRANSFER',
  STOCK_ISSUE = 'STOCK_ISSUE',
  STOCK_REQUEST = 'STOCK_REQUEST',
  QC_INSPECTION = 'QC_INSPECTION',
  INSPECTION_REPORT = 'INSPECTION_REPORT',
  JOB_WORK_OUT_ORDER = 'JOB_WORK_OUT_ORDER',
  EWAY_BILL = 'EWAY_BILL',
  FULFILLMENT = 'FULFILLMENT',
  FULFILLMENT_REPORT = 'FULFILLMENT_REPORT',
  GOODS_RECEIPT = 'GOODS_RECEIPT',
  ALLOCATION = 'ALLOCATION',
  WORKORDER_OPERATION = 'WORKORDER_OPERATION',
  AMORTIZATION_SCHEDULE = 'AMORTIZATION_SCHEDULE',
  //for Reports drill down documents type coming from backend
  Direct_S_Expense = 'Direct Expense',
  Prepayment = 'Prepayment',
  Advance_S_Payment = 'Advance Payment',
  Direct_S_Deposit = 'Direct Deposit',
  Debit_S_Note = 'Debit Note',
  Credit_S_Note = 'Credit Note',
  Bill = 'Bill',
  Invoice = 'Invoice',
  Contact = 'Contact',
  Journal_S_Entry = 'Journal Entry',
  PARTY_JE = 'Journal Entry - Party',
  FIXED_ASSET_BILL = 'FIXED_ASSET_BILL',
  AMORTIZATION = 'AMORTIZATION',
  GOOD_RECEIPT = 'GOOD_RECEIPT',
  PURCHASE_RETURN = 'PURCHASE_RETURN',
  Payment = 'Payment',
  PRODUCT = 'PRODUCT',
  Warehouse = 'Warehouse',
  MRP_PRODUCT = 'MRP_PRODUCT',
  ADJUSTMENT = 'ADJUSTMENT',
  OFFSET_INVOICE = 'Offset Invoice',
  PRODUCT_DOCUMENT = 'PRODUCT_DOCUMENT',
  RATE_ANALYSIS = 'Rate Analysis',
  WORKORDER_DOCUMENT = 'WORKORDER_DOCUMENT',
  QC_DOCUMENT = 'QC_DOCUMENT',
  QC_DOCUMENT_DETAILS = 'QC_DOCUMENT_DETAILS',
  GATE_ENTRY = 'GATE_ENTRY',
  PENDING_GOODS_RECEIPT = 'PENDING_GOODS_RECEIPT',
  PENDING_FULFILLMENT = 'PENDING_FULFILLMENT',
  ASSET_SCHEDULE_AD_HOC = 'ASSET_SCHEDULE_AD_HOC',
  MACHINE_SCHEDULE_COLUMN = 'MACHINE_SCHEDULE_COLUMN',
  MACHINE_MATERIAL_CONSUMPTION = 'MACHINE_MATERIAL_CONSUMPTION',
  MACHINE_MAINTENANCE_SCHEDULE = 'MACHINE_MAINTENANCE_SCHEDULE',
  PURCHASE_INWARD_QUOTATION = 'PURCHASE_INWARD_QUOTATION',
  GOODS_RECEIPT_JOURNAL_ENTRY = 'GOODS_RECEIPT_JOURNAL_ENTRY',
  MAKE_PAYMENT_JOURNAL_ENTRY = 'MAKE_PAYMENT_JOURNAL_ENTRY',
  FULFILLMENT_JOURNAL_ENTRY = 'FULFILLMENT_JOURNAL_ENTRY',
  RECEIVE_PAYMENT_JOURNAL_ENTRY = 'RECEIVE_PAYMENT_JOURNAL_ENTRY'
}

export enum RECORD_SAVED_EVENT_DOC_TYPE {
  WORK_ORDER = 'WORK_ORDER',
  RECEIVED_GOODS = 'RECEIVED_GOODS',
  PURCHASE_ORDER = 'PURCHASE_ORDER',
  SALES_ORDER = 'SALES_ORDER',
  JOB_WORK_OUT = 'JOB_WORK_OUT_ORDER',
  DISPATCHED_GOODS = 'DISPATCHED_GOODS',
  CREATE_BILL = 'CREATE_BILL',
  BILL = 'PURCHASE_INVOICE'
}

export enum PAYMENT_TYPES {
  EXPENSE = 'EXPENSE',
  BANK_TRANSFER = 'BANK_TRANSFER',
  FUND_TRANSFER = 'FUND_TRANSFER',
  JOURNAL_ENTRY = 'JOURNAL_ENTRY',
  CASH_EXPENSE = 'CASH_EXPENSE',
  MAKE_PAYMENT = 'MAKE_PAYMENT',
  RECEIVE_PAYMENT = 'RECEIVE_PAYMENT',
  CHEQUE = 'CHEQUE',
  CARD = 'CARD',
  CASH = 'CASH',
  PICKLIST = 'PICKLIST',
  PACKLIST = 'PACKLIST',
  SHIPLIST = 'SHIPLIST'
}

export const DOC_TYPE_TO_MODULE_MAP = {
  [DOC_TYPE.INVOICE.toString()]: INVOICE,
  [DOC_TYPE.QUOTE.toString()]: QUOTE,
  [DOC_TYPE.ORDER.toString()]: ORDER,
  [DOC_TYPE.REQUISITION.toString()]: REQUISITION,
  [DOC_TYPE.BILL.toString()]: BUY_BILL,
  [DOC_TYPE.PURCHASE_REQUEST_FOR_QUOTES.toString()]: PURCHASE_REQUEST_FOR_QUOTES
};

export const DOC_TYPE_TO_ATTACHMENT_MAP = {
  [DOC_TYPE.INVOICE.toString()]: INVOICE,
  [DOC_TYPE.QUOTE.toString()]: DOC_TYPE.QUOTE,
  [DOC_TYPE.SALES_ORDER.toString()]: DOC_TYPE.SALES_ORDER,
  [DOC_TYPE.ORDER.toString()]: ORDER,
  [DOC_TYPE.FA_ORDER.toString()]: ORDER,
  [DOC_TYPE.PURCHASE_INWARD_QUOTATION.toString()]:
    DOC_TYPE.PURCHASE_INWARD_QUOTATION,
  [DOC_TYPE.PURCHASE_REQUISITION.toString()]: DOC_TYPE.PURCHASE_REQUISITION,
  [DOC_TYPE.REQUISITION.toString()]: REQUISITION,
  [DOC_TYPE.BILL.toString()]: INVOICE,
  [DOC_TYPE.FA_BILL.toString()]: INVOICE,
  [DOC_TYPE.DEBIT_NOTE.toString()]: DEBIT_NOTE,
  [DOC_TYPE.CREDIT_NOTE.toString()]: CREDIT_NOTE,
  [DOC_TYPE.REQUISITION.toString()]: REQUISITION,
  [DOC_TYPE.PREPAYMENT.toString()]: EXPENSE,
  [DOC_TYPE.DEPOSIT_ADVANCED_PAYMENT.toString()]: DEPOSIT,
  [DOC_TYPE.EXPENSE.toString()]: EXPENSE,
  [DOC_TYPE.DEPOSIT.toString()]: DEPOSIT,
  [DOC_TYPE.JOURNAL_ENTRY.toString()]: DOC_TYPE.JOURNAL_ENTRY,
  [DOC_TYPE.JOB_WORK_OUT_ORDER.toString()]: DOC_TYPE.JOB_WORK_OUT_ORDER,
  [DOC_TYPE.FULFILLMENT.toString()]: DOC_TYPE.FULFILLMENT,
  [DOC_TYPE.GOODS_RECEIPT.toString()]: DOC_TYPE.GOODS_RECEIPT,
  [DOC_TYPE.WORKORDER_OPERATION.toString()]: DOC_TYPE.WORKORDER_OPERATION,
  [DOC_TYPE.PRODUCT_DOCUMENT.toString()]: DOC_TYPE.PRODUCT_DOCUMENT,
  [DOC_TYPE.PRODUCT_DOCUMENT.toString()]: DOC_TYPE.PRODUCT_DOCUMENT,
  [DOC_TYPE.Contact.toString()]: DOC_TYPE.Contact.toUpperCase(),
  [DOC_TYPE.WORKORDER_DOCUMENT]: DOC_TYPE.WORKORDER_DOCUMENT,
  [DOC_TYPE.PENDING_GOODS_RECEIPT.toString()]: DOC_TYPE.PENDING_GOODS_RECEIPT,
  [DOC_TYPE.PENDING_FULFILLMENT.toString()]: DOC_TYPE.PENDING_FULFILLMENT,
  [DOC_TYPE.MACHINE_MAINTENANCE_SCHEDULE]:
    DOC_TYPE.MACHINE_MAINTENANCE_SCHEDULE,
  [DOC_TYPE.QC_DOCUMENT_DETAILS]: DOC_TYPE.QC_DOCUMENT_DETAILS,
  [DOC_TYPE.MACHINE]: DOC_TYPE.MACHINE,
  [DOC_TYPE.GATE_ENTRY]: DOC_TYPE.GATE_ENTRY
};

export enum PRODUCT_TYPE {
  TRACKED = 'TRACKED',
  NON_TRACKED = 'NONTRACKED',
  BILL_OF_MATERIALS = 'BILL_OF_MATERIALS'
}

export enum DOCUMENT_TYPE {
  INVOICE = 'INVOICE',
  QUOTATION = 'QUOTATION',
  BILL = 'BILL',
  PURCHASE_ORDER = 'PURCHASEORDER',
  SALES_ORDER = 'SALES_ORDER',
  STOCK_ADJUSTMENT = 'STOCK_ADJUSTMENT',
  JOB_WORK_OUT = 'JOB_WORK_OUT',
  REQUISITION = 'REQUISITION'
}

export enum MODULE_TYPE {
  BUY = 'BUY',
  SELL = 'SELL'
}

export enum ADDITIONAL_CHARGES_TYPE {
  BUY = 'BUY',
  SELL = 'SELL',
  BOTH = 'BOTH'
}

export enum TRACKING_TYPE {
  BATCH = 'BATCH',
  SERIAL = 'SERIAL',
  NONE = 'NONE'
}

export enum DOCUMENT_STATUS {
  OPEN = 'OPEN',
  DRAFT = 'DRAFT',
  PROCESSED = 'PROCESSED',
  CLOSED = 'CLOSED'
}

export enum FULFILLMENT_STATUS {
  UNFULFILLED = 'UNFULFILLED',
  PARTIAL_FULFILLED = 'PARTIAL_FULFILLED',
  FULLY_FULFILLED = 'FULLY_FULFILLED',
  PENDING_FOR_APPROVAL = 'PENDING_FOR_APPROVAL'
}

export enum FULFILLMENT_TYPE {
  NONE = 'NONE',
  DEFAULT = 'DEFAULT',
  PICK_PACK_SHIP = 'PICK_PACK_SHIP',
  DROP_SHIP = 'DROP_SHIP'
}

export enum PAYMENT_STATUS {
  PENDING = 'PENDING',
  PARTIAL = 'PARTIAL',
  RECEIVED = 'RECEIVED'
}

export enum EMAIL_STATUS {
  SENT = 'SENT',
  NOT_SENT = 'NOT_SENT',
  NOT_TRACKED = 'NOT_TRACKED'
}

export const IRP_CRED_VALID_GSTIN = '27ABFPD4021L002';

export const GSTIN_REGEX_PATTERN: string = '^[a-zA-Z0-9]{15}$';

export const INDONESIA = {
  NIK_NUMBER: '^[0-9]{16}',
  NPWP_NUMBER: '[0-9]{2}.[0-9]{3}.[0-9]{3}.[0-9]{1}-[0-9]{3}.[0-9]{3}'
};

export const PAN_REGEX_PATTERN =
  '(^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$)|(^([a-zA-Z]){4}([0-9]){5}([a-zA-Z]){1}?$)';

export const INDIA_MOBILE_REGEX_PATTERN = '^[0-9]{10}$';

export const CIN_REGEX_PATTERN =
  '^([L|U]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$';

export const EMAIL_PATTERN =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const NUMBER_REGEX_PATTERN: string = '^[0-9]+$';

export const DOC_PATH_WITH_ID_REGEX = /^(?:(\/\w+-*\w+){2}){1,2}$/;
export const REGEX_PHONE_NUMBER: RegExp =
  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
export const JWO_DOC_PATH_WITH_ID_REGEX = /^(?:(\/\w+-*\w+-*\w+){3}){1,2}$/;
export const FILENAME_REGEX = '^[A-Za-z0-9_-]*$';

export const UK_VAT_AND_BUSINESS_REG_NUMBER = '^.{1,20}$';

export enum API_STATUS {
  IDLE = 'idle',
  LOADING = 'loading',
  FAILED = 'failed'
}

export enum NEW_SETTINGS_SECTION {
  ORGANISATION_SETTINGS = 'ORGANISATION_SETTINGS',
  ADVANCED_SETTINGS = 'ADVANCED_SETTINGS',
  MULTI_COMPANY_SETTINGS = 'MULTI_COMPANY_SETTINGS',
  USER_PERMISSIONS = 'USER_PERMISSIONS',
  CHECK_FORMAT = 'CHECK_FORMAT',
  LABEL_TEMPLATE = 'LABEL_TEMPLATE',
  CURRENCY = 'CURRENCY',
  TAX = 'TAX',
  TDS_SETUP = 'TDS_SETUP',
  TCS_SETUP = 'TCS_SETUP',
  REVENUE_RECOGNITION = 'REVENUE_RECOGNITION',
  ITEM_REVENUE_CATEGORIES = 'ITEM_REVENUE_CATEGORIES',
  FAIR_VALUE_FORMULAS = 'FAIR_VALUE_FORMULAS',
  FAIR_VALUE_PRICE_LIST = 'FAIR_VALUE_PRICE_LIST',
  REVENUE_REC_RULES = 'REVENUE_REC_RULES',
  PAYMENT_TERMS = 'PAYMENT_TERMS',
  UNIT_OF_MEASUREMENT = 'UNIT_OF_MEASUREMENT',
  PRINT_TEMPLATE = 'PRINT_TEMPLATE',
  EMAIL = 'EMAIL',
  IMPORT_LOG = 'IMPORT_LOG',
  REV_REC_JE_LOG = 'REV_REC_JE_LOG',
  AUDIT_LOG = 'AUDIT_LOG',
  AUTOMATION_LOGS = 'AUTOMATION_LOGS',
  UOM_SCHEMA = 'UOM_SCHEMA',
  SEQUENCE_NUMBER_SETTING = 'SEQUENCE_NUMBER_SETTING',
  CREDIT_LIMIT = 'CREDIT_LIMIT',
  CUSTOM_FIELDS = 'CUSTOM_FIELDS',
  SALES_PERSON_MASTER = 'SALES_PERSON_MASTER',
  LOCATIONS = 'LOCATIONS',
  CLASSES = 'CLASSES',
  ILC_SETTING = 'ILC_SETTING',
  AGENCIES = 'AGENCIES',
  ACTIVE_DATE_RANGE_SETTINGS = 'ACTIVE_DATE_RANGE_SETTINGS',
  BACK_DATED_RESTRICTIONS_SETTINGS = 'BACK_DATED_RESTRICTIONS_SETTINGS',
  BARCODE_SETTINGS = 'BARCODE_SETTINGS',
  WITHHOLDING_TAXES = 'WITHHOLDING_TAXES',
  RATE_ANALYSIS = 'RATE_ANALYSIS',
  CLIENT_PORTAL = 'CLIENT_PORTALl',
  QC_FLOW_MODULES = 'QC_FLOW_MODULES',
  QC_TEMPLATE = 'QC_TEMPLATE',
  JOB_CARDS_EMAIL_SETTINGS = 'JOB_CARDS_EMAIL_SETTINGS',
  WHATSAPP_API = 'WHATSAPP_API'
}

export enum POPUP_CLICK_TYPE {
  CLOSE_POPUP = 'closePopup',
  UPDATE_PRICELIST = 'updatePriceList',
  UPDATE_CONTACT = 'updateContact',
  CREATE_CONTACT = 'createContact',
  CREATE_COA = 'createCoa',
  UPDATE_COA = 'updateCoa',
  UPDATE_INVOICE = 'updateInvoice',
  UPDATE_SALES_ORDER = 'updateSalesOrder',
  COPY_SALES_ORDER = 'copySalesOrder',
  COPY_INVOICE = 'copyInvoice',
  UPDATE_PRODUCT = 'updateProduct',
  SAVE_GST_LOG_REPORT_SETTING = 'saveGSTLogReportSetting',
  CREATE_GST_REPORT_TYPE = 'createGSTReportType',
  CREATE_PRODUCT = 'createProduct',
  UPDATE_QUOTE = 'updateQuote',
  COPY_QUOTE = 'copyQuote',
  UPDATE_BILL = 'updateBill',
  COPY_BILL = 'copyBill',
  UPDATE_ORDER = 'updateOrder',
  UPDATE_REQUISITION = 'updateRequisition',
  COPY_PURCHASE_ORDER = 'copyPurchaseOrder',
  COPY_REQUISITION = 'copyRequisition',
  BILL_PAYMENT = 'billPayment',
  RECEIVE_PAYMENT = 'receivePayment',
  COPY_CONTACT = 'copyContact',
  UPDATE_ADDRESS = 'updateAddress',
  SAVE_CUSTOM_DOCUMENT_NUMBER = 'saveNumber',
  SAVE_AUTO_UPDATE_FX_RATE = 'saveAutoUpdateFXRate',
  UPDATE_CASH_FLOW_MAPPING = 'saveCashFlowMapping',
  UPDATE_EXCHANGE_RATE = 'updateExchangeRate',
  SAVE_NATURE_OF_INCOME_PAYMENT = 'saveNatureOfIncomePayment',
  SAVE_HSN_SAC = 'saveHsnSac',
  UPDATE_INVOICE_REMINDER = 'updateInvoiceReminder',
  APPLY_OFFSET = 'applyOffset',
  CREATE_OBSI = 'createOBSI',
  SAVE_VARIANT = 'saveVariant',
  CLOSE_VARIANT = 'closeVariant',
  CLOSE_PRODUCT_CREATED_POPUP = 'closeProductCreatedPopup',
  SAVE_ADVANCED_TRACKING = 'saveBatch',
  FULFILLMENT = 'fulfillment',
  BACKORDER = 'backOrder',
  BULK_FULFILLMENT = 'bulkFulfillment',
  FULFILLMENT_RECORDS = 'fulfillmentRecords',
  DIRECT_FULFILLMENT = 'directFulfiilment',
  DROPSHIP_FULFILLMENT = 'dropshipFulfillment',
  ASSIGN_SERIAL_TRACKING = 'assignSerialTracking',
  ASSIGN_BATCH_TRACKING = 'assignBatchTracking',
  RECEIVED_GOODS = 'receivedGoods',
  BULK_RECEIVED_GOODS = 'bulkReceivedGoods',
  OPEN_CONVERT_QUOTE_OPTIONS = 'openConvertQuoteOptions',
  OPEN_CONVERT_QUOTE_TO_SO_OPTIONS = 'openConvertQuoteToSOOptions',
  OPEN_CONVERT_SO_OPTIONS = 'openConvertSOOptions',
  CREATE_PRICE_LIST = 'createPriceList',
  UPDATE_PRICE_LIST = 'updatePriceList',
  ASSIGN_PRICE_LIST = 'assignPriceList',
  COPY_PRICE_LIST = 'copyPriceList',
  OPEN_CONVERT_PO_OPTIONS = 'openConvertPOOptions',
  SELECT_CLOSING_PERIOD = 'selectClosingPeriod',
  SELECT_CHECK_LIST = 'selectCheckList',
  SAVE_CLOSING_JE_POPUP = 'saveClosingJePopUp',
  SAVE_FY_SETTINGS = 'selectFySettings',
  RECEIVED_GOODS_RECORDS = 'receivedGoodsRecords',
  ADD_WAREHOUSE = 'addWarehouse',
  CREATE_JE = 'createJE',
  UPDATE_JE = 'updateJE',
  CREATE_EXPENSE = 'createExpense',
  EDIT_WAREHOUSE = 'editWarehouse',
  CREATE_DEBIT = 'createDebit',
  UPDATE_DEBIT = 'updateDebit',
  CREATE_CREDIT = 'createCredit',
  UPDATE_CREDIT = 'updateCredit',
  OPEN_PRINT_PREVIEW = 'openPrintPreview',
  PRINT_DOCUMENT = 'printDocument',
  OPEN_EMAIL_POPUP = 'openEmailPopup',
  EMAIL_DOCUMENT = 'emailDocument',
  ADD_PAYMENT_TERMS = 'addPaymentTerms',
  EDIT_PAYMENT_TERMS = 'editPaymentTerms',
  UPDATE_EXPENSE = 'updateExpense',
  CREATE_PREPAYMENT = 'createPrepayment',
  CREATE_DEPOSIT = 'createDeposit',
  CREATE_ADVANCED_PAYMENT = 'createAdvancedPayment',
  UPDATE_DEPOSIT = 'updateDeposit',
  CREATE_TAX = 'createTax',
  UPDATE_TAX = 'updateTax',
  CREATE_TAX_GROUP = 'createTaxGroup',
  UPDATE_TAX_GROUP = 'updateTaxGroup',
  CREATE_RULE = 'createRule',
  ADD_UOM = 'addUom',
  EDIT_UOM = 'editUom',
  SMTP_TO_USER = 'smtpUser',
  CREATE_BANK_DEPOSIT = 'createBankDeposit',
  DELETE_DATA = 'deleteData',
  CONFIRM_DROPSHIP = 'confirmDropship',
  UPDATE_ORGANISATION = 'updateOrganisation',
  ADD_UOM_SCHEMA = 'addUomSchema',
  EDIT_UOM_SCHEMA = 'editUomSchema',
  CREATE_EXPENSE_BILL = 'createExpenseBill',
  ADD_SEQUENCE_FORMAT = 'addSequenceFormat',
  CREATE_EXPENSE_DEPOSIT = 'createExpenseDeposit',
  MATCH_RECORD = 'matchRecord',
  MATCH_RECORD_UPDATE = 'matchRecordUpdate',
  SET_RECURRING_DOCUMENT = 'setRecurringDocument',
  SAVE_RECURRING_DOCUMENT = 'saveRecurringDocument',
  AUTO_SEND_RECURRING_DOCUMENT = 'autoSendRecurringDocument',
  CREATE_BANK = 'createBankn',
  UPDATE_BANK = 'updateBank',
  CREATE_NEW_ORG = 'createNewOrg',
  ADD_TDS_RATE = 'addTDSRate',
  SUBMIT_TDS_AMOUNT = 'submitTDSAmount',
  UPDATE_CONTACT_REMAINDER_STATUS = 'updateContactRemainderStatus',
  OPEN_GLOBAL_REMAINDER_POPUP = 'openGlobalRemainderPopup',
  SAVE_CUSTOM_FIELD = 'saveCustomField',
  CREATE_DEDUCTEE_TYPE = 'createDeducteeType',
  SAVE_RECEIVE_PAYMENT = 'saveReceivePayment',
  UPDATE_RECEIVE_PAYMENT = 'updateReceivePayment',
  SEND_PAYMENT_MAIL = 'sendPaymentMail',
  VIEW_DOCUMENT = 'viewDocument',
  IRP_CREDENTIAL = 'irpCredential',
  GST_E_FILING = 'gstEfiling',
  GST_E_FILING_SUCCESS = 'gstEfilingSuccess',
  TAX_ADJUSTMENT = 'taxAdjustment',
  SAVE_IRAS = 'IRAS',
  ADD_VARIANT = 'addVariant',
  ADD_VARIANT_ATTRIBUTE = 'addVariantAttribute',
  UPDATE_VARIANT = 'updateVariant',
  ADD_GENERAL_PICK_LIST = 'addGeneralPickList',
  GO_TO_TRACKING = 'goToTracking',
  CLOSE_BATCH_SERIAL_TRACKING = 'closeBatchSerialTracking',
  PPS_FILTER = 'ppsFilter',
  ENABLE_FORM = 'ENABLE_FORM',
  ADVANCED_INVENTORY_POPUP = 'advancedInventoryPopup',
  FIFO_ENABLED_SUCCESSFULLY = 'fifoEnabledSuccessfully',
  OPEN_ATTACHMENT = 'openAttachment',
  CREATE_GST_RETURN = 'gstReturn',
  SAVE_REPORT = 'saveReport',
  UPDATE_REPORT = 'updateReport',
  REPORTS_FILTER = 'reportsFilter',
  ADD_LANDED_COST_CATEGORY = 'addLandedCostCategory',
  CREATE_AGENCY_PAYMENT_RECORD = 'createAgencyPaymentRecord',
  SALES_RETURN = 'salesReturn',
  PURCHASE_RETURN = 'purchaseReturn',
  START_MATCHING_GSTR2B = 'startMatchingGSTR2B',
  UPLOAD_GSTR2B_FILE = 'uploadGstr2BFile',
  CONFIRM = 'CONFIRM',
  CLOSE_OTP_VIEW = 'closeOTPView',
  SAVE_BARCODE_SETTINGS = 'saveBarcodeSettings',
  SAVE_PRE_BARCODE_SETTINGS = 'savePreBarcodeSettings',
  ADD_NOTES_SUBMIT_BTN = 'addNotesSubmitBtn',
  RESET_BARCODE_VALUES_BTN = 'resetBarcodeValuesBtn',
  ADD_STOCK_ISSUE = 'addStockIssue',
  SAVE_PAYMENT_MILESTONE = 'savePaymentMilestone',
  SAVE_SECURITY_GATE_ENTRY = 'saveSecurityGateEntry',
  SAVE_MULTI_COMPANY_SETTINGS = 'saveMultiCompanySettings',
  SAVE_ASSET_GROUP = 'saveAssetGroup',
  SAVE_AMORTIZATION_TEMPLATE = 'saveAmortizationTemplate',
  SAVE_REVENUE_CATEGORY = 'saveRevenueCategory',
  SAVE_EXPENSE_ALLOCATION_TEMPLATE = 'saveExpenseAllocationTemplate',
  RUN_EXPENSE_ALLOCATION = 'runExpenseAllocation',
  UPDATE_AND_RUN_EXPENSE_ALLOCATION = 'updateAndRunExpenseAllocation',
  SAVE_RATE_ANALYSIS = 'saveRateAnalysis',
  GENERATE_LABEL = 'generateLabel',
  API_CALL_STOP_PROGRESS = 'apiCallStopProgress',
  SHOW_LOGS = 'showLogs',
  UPDATE_AMORTIZATION_SCHEDULE = 'updateAmortizationSchedule',
  CREATE_AMORTIZATION_SCHEDULE = 'createAmortizationSchedule',
  ADD_SALES_PERSON_MASTER = 'addSalesPersonMaster',
  EDIT_SALES_PERSON_MASTER = 'editSalesPersonMaster',
  CREATE = 'create',
  SAVE = 'save',
  SAVE_PREFERRED_VENDOR = 'savePreferredVendor',
  CONFIRM_PREFERRED_VENDOR_PRODUCT = 'confirmPreferredVenderProduct'
}

export enum POPUP_TYPE {
  POPUP = 'popup',
  DRAFT = 'draft'
}
export enum TAX_APPLY_TYPE {
  PRE = 'PRE',
  AFTER = 'AFTER'
}
export enum TAX_APPLY_LABEL {
  PRE = 'Pre-tax Amount',
  AFTER = 'After-tax Amount'
}

export enum STATUS_TYPE {
  ACTIVE = 'ACTIVE',
  active = 'active',
  Active = 'Active',
  INACTIVE = 'INACTIVE',
  inactive = 'inactive',
  Inactive = 'Inactive',
  OPEN = 'OPEN'
}

export enum POPUP_CALLBACKS_TYPE {
  UPDATE_CONTACT = 'updateContact',
  CREATE_CONTACT = 'createContact',
  CREATE_CONTACT_SUCCESS = 'createContactSuccess',
  CREATE_COA = 'createCoa',
  CREATE_COA_SUCCESS = 'createCoaSuccess',
  COPY_CONTACT = 'copyContact',
  UPDATE_INVOICE = 'updateInvoice',
  CREATE_INVOICE = 'createInvoice',
  UPDATE_QUOTE = 'updateQuote',
  UPDATE_COA = 'updateCoa',
  CREATE_QUOTE = 'createQuote',
  CREATE_SALES_ORDER = 'createSalesOrder',
  UPDATE_SALES_ORDER = 'updateSalesOrder',
  SAVE_AS_DRAFT = 'saveAsDraft',
  CLOSE_POPUP = 'closePopup',
  CREATE_PRODUCT = 'createProduct',
  CREATE_EWAY_BILL = 'createEwayBill',
  CREATE_TRANSPORTER = 'createTransporter',
  UPDATE_PRODUCT = 'updateProduct',
  SAVE_GST_LOG_REPORT_SETTING = 'saveGSTLogReportSetting',
  CREATE_GST_REPORT_TYPE = 'createGSTReportType',
  CREATE_BILL = 'createBill',
  UPDATE_BILL = 'updateBill',
  UPDATE_ORDER = 'updateOrder',
  CREATE_ORDER = 'createOrder',
  BILL_PAYMENT = 'billPayment',
  RECEIVE_PAYMENT = 'receivePayment',
  UPDATE_ADDRESS = 'updateAddress',
  ADDRESS_UPDATE_SUCCESSFUL = 'addressUpdatedSuccessfully',
  NONE = 'none',
  UPDATE_INVOICE_REMINDER = 'updateInvoiceReminder',
  APPLY_OFFSET = 'applyOffset',
  CREATE_OBSI = 'createOpeningBalanceSalesInvoice',
  SAVE_VARIANT = 'saveVariant',
  CLOSE_VARIANT = 'closeVariant',
  CLOSE_PRODUCT_CREATED_POPUP = 'closeProductCreatedPopup',
  SAVE_ADVANCED_TRACKING = 'saveBatch',
  FULFILLMENT = 'fulfillment',
  FULFILLMENT_SUCCESS = 'fulfillmentSuccess',
  BACKORDER = 'backOrder',
  BULK_FULFILLMENT = 'bulkFulfillment',
  FULFILLMENT_RECORDS = 'fulfillmentRecords',
  DIRECT_FULFILLMENT = 'directFulfillment',
  DROPSHIP_FULFILLMENT = 'dropshipFulfillment',
  PICK_PACK_SHIP = 'pickPackShip',
  ASSIGN_SERIAL_TRACKING = 'assignSerialTracking',
  ASSIGN_BATCH_TRACKING = 'assignBatchTracking',
  RECEIVED_GOODS = 'receivedGoods',
  CREATE_PRICE_LIST = 'createPriceList',
  UPDATE_PRICE_LIST = 'updatePriceList',
  ASSIGN_PRICE_LIST = 'assignPriceList',
  COPY_PRICE_LIST = 'copyPriceList',
  BULK_RECEIVED_GOODS = 'bulkReceivedGoods',
  SAVE_CUSTOM_NUMBER = 'saveCustomNumber',
  SAVE_AUTO_UPDATE_FX_RATE = 'saveAutoUpdateFXRates',
  UPDATE_CASH_FLOW_MAPPING = 'saveCashFlowMapping',
  SAVE_HSN_SAC_CODES = 'saveHsnSacCodes',
  UPDATE_CURRENCY_EXCHANGE_RATE = 'updateCurrencyExchangeRate',
  SAVE_NATURE_INCOME_PAYMENT = 'saveNatureIncomePayment',
  SELECT_CLOSING_PERIOD = 'selectClosingPeriod',
  SELECT_CHECK_LIST = 'selectCheckList',
  RECEIIVE_CHECKLIST_DATA = 'receiveChecklistData',
  SELECT_CLOSING_PERIOD_DATA = 'closingPeriodData',
  SAVE_CLOSING_JE_POPUP = 'saveClosingJePopUp',
  SELECT_SETTING_FY = 'selectFySettings',
  CLOSE_POPUP_SETTINGS = 'closeFySetting',
  STOP_LOADING_IN_FY_SETTINGS = 'stopLoadingInFYSettings',
  RECEIVED_GOODS_RECORDS = 'receivedGoodsRecords',
  ADD_WAREHOUSE = 'addWarehouse',
  CREATE_JE = 'createJE',
  UPDATE_JE = 'updateJE',
  CREATE_EXPENSE = 'createExpense',
  EDIT_WAREHOUSE = 'editWarehouse',
  CREATE_DEBIT = 'createDebit',
  ADD_PAYMENT_TERMS = 'addPaymentTerms',
  EDIT_PAYMENT_TERMS = 'editPaymentTerm',
  UPDATE_DEBIT = 'updateDebit',
  CREATE_CREDIT = 'createCredit',
  UPDATE_CREDIT = 'updateCredit',
  UPDATE_EXPENSE = 'updateExpense',
  CREATE_PREPAYMENT = 'createPrepayment',
  CREATE_DEPOSIT = 'createDeposit',
  CREATE_ADVANCED_PAYMENT = 'createAdvancedPayment',
  UPDATE_DEPOSIT = 'updateDeposit',
  CREATE_TAX = 'createTax',
  CREATE_TAX_SUCCESS = 'createTaxSuccess',
  UPDATE_TAX = 'updateTax',
  CREATE_TAX_GROUP = 'createTaxGroup',
  UPDATE_TAX_GROUP = 'updateTaxGroup',
  EMAIL_DOCUMENT = 'emailDocument',
  CREATE_RULE = 'createRule',
  ADD_UOM = 'addUom',
  EDIT_UOM = 'editUom',
  UPDATE_ORGANISATION = 'updateOrganisation',
  CONFIRM_DROPSHIP = 'confirmDropship',
  ADD_UOM_SCHEMA = 'addUomSchema',
  EDIT_UOM_SCHEMA = 'editUomSchema',
  CREATE_BANK_DEPOSIT = 'createBankDeposit',
  CREATE_EXPENSE_BILL = 'createExpenseBill',
  ADD_SEQUENCE_FORMAT = 'addSequenceFormat',
  CREATE_EXPENSE_DEPOSITE = 'createExpenseDeposit',
  MATCH_RECORD = 'matchRecord',
  MATCH_RECORD_UPDATE = 'matchRecordUpdate',
  SET_RECURRING_DOCUMENT = 'setRecurringDocument',
  SAVE_RECURRING_DOCUMENT = 'saveRecurringDocument',
  AUTO_SEND_RECURRING_DOCUMENT = 'autoSendRecurringDocument',
  CREATE_NEW_ORG = 'createNewOrg',
  ADD_TDS_RATE = 'addTDSRate',
  DEDUCT_TDS_SUBMIT = 'deductTDSRateSubmit',
  DEDUCT_TDS_SUCCESS = 'deductTDSRateSuccess',
  UPDATE_CONTACT_REMAINDER_STATUS = 'updateContactRemainderStatus',
  OPEN_GLOBAL_REMAINDER_POPUP = 'openGlobalRemainderPopup',
  API_CALL_IN_PROGRESS = 'apiCallInProgress',
  API_CALL_STOP_PROGRESS = 'apiCallStopProgress',
  CREATE_DEDUCTEE_TYPE = 'createDeducteeType',
  DEDUCTEE_CREATE_SUCCESS = 'deducteeCreateSuccess',
  SAVE_RECEIVE_PAYMENT = 'saveReceivePayment',
  UPDATE_RECEIVE_PAYMENT = 'updateReceivePayment',
  SEND_PAYMENT_MAIL = 'sendPaymentMail',
  IRP_CREDENTIAL = 'irpCredential',
  IRP_CREDENTIAL_SUCCESS = 'irpCredentialSuccess',
  GST_E_FILING = 'gstEfiling',
  GST_E_FILING_SUCCESS = 'gstEfilingSuccess',
  PAYMENT_TERM_SUCCESS = 'paymentTermSuccess',
  UOM_SCHEMA_SUCCESS = 'uomSchemaSuccess',
  TAX_ADJUSTMENT = 'taxAdjustment',
  UOM_SUCCESS = 'uomSuccess',
  ADD_VARIANT = 'addVariant',
  CLOSE_VARIANT_POPUP = 'closeVariantPopup',
  UPDATE_VARIANT = 'updateVariant',
  ADD_GENERAL_PICK_LIST = 'addGeneralPickList',
  GO_TO_TRACKING = 'goToTracking',
  CLOSE_BATCH_SERIAL_TRACKING = 'closeBatchSerialTracking',
  PPS_FILTER = 'ppsFilter',
  PPS_FILTER_RECEIVED = 'ppsFilterReceived',
  VARIANT_PRODUCT_ON_EDIT_MODE = 'variantProductOnEditMode',
  ENABLE_FORM = 'ENABLE_FORM',
  ADVANCED_INVENTORY_POPUP = 'advancedInventoryPopup',
  FIFO_ENABLED_SUCCESSFULLY = 'fifoEnabledSuccessfully',
  VARIANT_GENERATED_SUCCESSFULLY = 'variantGeneratedSuccessfully',
  SAVE_REPORT = 'saveReport',
  CREATE_GST_RETURN = 'gstReturn',
  UPDATE_REPORT = 'updateReport',
  REPORT_NAME_UPDATED_SUCCESSFULLY = 'reportNameUpdatedSuccessfully',
  REPORTS_FILTER = 'reportsFilter',
  REPORTS_PAYLOAD = 'reportsPayload',
  ADD_LANDED_COST_CATEGORY = 'addLandedCostCategory',
  CREATE_AGENCY_PAYMENT_RECORD = 'createAgencyPaymentRecord',
  AGENCY_PAYMENT_SUCCESSFUL = 'agencyPaymentSuccessful',
  SALES_RETURN = 'salesReturn',
  PURCHASE_RETURN = 'purchaseReturn',
  START_MATCHING_GSTR2B = 'startMatchingGSTR2B',
  CANCEL_MATCHING_GSTR2B = 'cancelMatching',
  DISPLAY_GSTR2B_OVERVIEW = 'displayGSTR2bOverview',
  MATCH_SUBMIT_BTN = 'submitMatchBtn',
  SAVE_BARCODE_SETTINGS = 'saveBarcodeSettings',
  ADD_NOTES_SUBMIT_BTN = 'addNotesSubmitBtn',
  CLOSE_DRAFT_POPUP = 'closeDraftPopup',
  CLOSE_POPUP_RECEIVE_GOODS_SUCCESS = 'closePopupReceiveGoods',
  ADD_VARIANT_ATTRIBUTE = 'addVariantAttribute',
  UPDATE_REQUISITION = 'updaterequisition',
  CREATE_REQUISION = 'create_requisition',
  SAVE_PAYMENT_MILESTONE = 'save_payment_milestone',
  SAVE_SECURITY_GATE_ENTRY = 'save_security_gate_entry',
  SAVE_MULTI_COMPANY_SETTINGS = 'saveMultiCompanySettings',
  SAVE_RATE_ANALYSIS = 'saveRateAnalysis',
  PRINT_DOCUMENT = 'printDocument',
  ADD_SALES_PERSON_MASTER = 'addSalesPersonMaster',
  UPDATE_SALES_PERSON_MASTER = 'updateSalesPersonMaster',
  CONFIRM_PREFERRED_VENDOR_PRODUCT = 'confirmPreferredVenderProduct',
  DIRECT_FULFILLMENT_FOR_DROPSHIP = 'directFulfillmentForDropship'
}

export enum RECEIVED_GOODS_STATUS {
  NOT_RECEIVED = 'UNRECEIVED',
  PARTIAL_RECEIVED = 'PARTIAL_RECEIVED',
  FULLY_RECEIVED = 'FULLY_RECEIVED',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  SAVE_RECEIVE_PAYMENT = 'saveReceivePayment',
  UPDATE_RECEIVE_PAYMENT = 'updateReceivePayment',
  DRAFT = 'DRAFT',
  PENDING_FOR_APPROVAL = 'PENDING_FOR_APPROVAL'
}

export enum BUY_TYPE {
  EXPENSE = 'EXPENSE',
  ASSET = 'ASSET',
  INVENTORY = 'INVENTORY'
}

export enum JWO_BILLED_STATUS {
  PENDING = 'PENDING',
  PARTIAL = 'PARTIAL',
  BILLED = 'BILLED'
}

export enum JWO_RECEIPT_STATUS {
  UNRECEIVED = 'UNRECEIVED',
  PARTIAL_RECEIVED = 'PARTIAL_RECEIVED',
  FULLY_RECEIVED = 'FULLY_RECEIVED',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  IN_PROGRESS = 'IN_PROGRESS'
}

export enum JWO_DISPATCH_STATUS {
  PENDING_DISPATCH = 'PENDING_DISPATCH',
  PARTIALLY_DISPATCHED = 'PARTIALLY_DISPATCHED',
  DISPATCHED = 'DISPATCHED',
  PENDING_DISPATCH_APPROVAL = 'PENDING_DISPATCH_APPROVAL',
  IN_PROGRESS = 'IN_PROGRESS'
}

export const FORECAST_PERIOS = [
  {
    name: 'Yearly',
    value: 'YEARLY'
  },
  {
    name: 'Monthly',
    value: 'MONTHLY'
  },
  {
    name: 'Quarterly',
    value: 'QUARTERLY'
  },
  {
    name: 'Weekly',
    value: 'WEEKLY'
  }
];

export const LABELS = {
  PRODUCTS: 'Product',
  ASSET: 'Asset',
  FA_BILL: 'Fixed Asset Bill',
  FA_ORDER: 'Fixed Asset Order',
  CONTACT: 'Contact',
  WAREHOUSE: 'Warehouse',
  INVOICES: 'Invoice',
  REV_REC: 'Revenue Arrangement',
  BILLS: 'Bill',
  PURCHASE_ORDERS: 'Purchase Order',
  WORK_OUT: 'Work Out',
  REQUISITION: 'Requisition',
  QUOTES: 'Quote',
  SALES_ORDER: 'Sales Order',
  COA: 'Coa',
  EXPENSE_BILL: 'Expense Bill',
  ESTIMATE: 'Estimate', // New name/title for Quotes
  SMART_REPORT: 'Smart Report',
  JOB_WORK_OUT: 'Job Work Out',
  AMORTIZATION_SCHEDULE: 'Amortization Schedule',
  PRICE_LIST: 'Price List',
  PRICE_BOOK: 'Price Book',
  PURCHASE_INWARD_QUOTATION: 'Purchase Inward Quotation'
};

export const LANGUAGES = {
  ENGLISH_US: 'English (US)',
  BAHASA_INDONESIA: 'Bahasa Indonesia',
  FRENCH: 'French',
  ENGLISH_UK: 'English (UK)',
  GERMAN: 'German',
  SPANISH: 'Spanish',
  HINDI: 'Hindi',
  TAMIL: 'Tamil',
  PUNJABI: 'Pubnjabi',
  GUJARATI: 'Gujarati',
  MARATHI: 'Marathi',
  BENGALI: 'Bengali',
  KANNADA: 'Kannada',
  MALAYALAM: 'Malayalam',
  TELUGU: 'Telugu'
};

export const LANGUAGES_CODE = {
  ENGLISH_US: 'en',
  BAHASA_INDONESIA: 'id',
  FRENCH: 'fr',
  ENGLISH_UK: 'en_GB',
  GERMAN: 'ge',
  SPANISH: 'es',
  HINDI: 'hi',
  TAMIL: 'ta',
  PUNJABI: 'pb',
  GUJARATI: 'gj',
  MARATHI: 'mh',
  BENGALI: 'bg',
  KANNADA: 'ka',
  MALAYALAM: 'ma',
  TELUGU: 'te'
};

export const COMMON_CONSTANT = {
  CHEQUE_VALUE: 'Cheque',
  CHEQUE_VALUE_US: 'Check',
  BANK_TRANSFER_VALUE: 'Bank Transfer',
  CARD_VALUE: 'Card',
  BANK: 'Bank',
  CASH: 'Cash',
  CURRENT_LIABILITY: 'Current Liabilities',
  CURRENT_ASSETS: 'Current Assets',
  CURRENCY_PRECISION: 6,
  DEBITNOTE_TYPE: 'Vendor',
  GST_PORTAL_URL: 'https://einvoice1.gst.gov.in/',
  PEPPOL_ID_FORMAT: '0195:sguen',
  DEFAULT_FY_FILE:
    'ERP-Default-dates/FINANCIAL-AND-BOOK-BEGINNING-DEFAULT-DATES.JSON',
  FILE: 'file',
  DEFAULT_FY_DATE: 'DefaultDates',
  DEFAULT_DEPOSIT_TO: 'AC-0000001',
  OTHERS: 'Others',
  DEFAULT_CUSTOM_FIELD_MAX_LENGTH: 100
};

export enum DECIMAL_SCALE {
  TWO = 2,
  THREE = 3,
  FOUR = 4
}

export enum BOOKS_ADDRESS_TYPES {
  BILLING_ADDRESS = 'billingAddress',
  SHIPPING_ADDRESS = 'shippingAddress',
  RETURN_ADDRESS = 'returnAddress',
  BILL_TO = 'billTo',
  SHIP_TO = 'shipTo',
  SHIP_FROM = 'shipFrom',
  NONE = 'none'
}

export enum COLUMN_TYPE {
  MANDATORY = 'mandatory',
  CUSTOM = 'custom'
}

export enum CUSTOM_FIELD_TYPE {
  CURRENCY = 'CURRENCY',
  MULTI_SELECT = 'MULTISELECT',
  COUNTRY = 'COUNTRY',
  BOOLEAN = 'BOOLEAN',
  NUMBER = 'NUMBER',
  TEXT = 'TEXT',
  TEXT_AREA = 'TEXT_AREA',
  DATE = 'DATE',
  DROPDOWN = 'DROPDOWN',
  USER = 'USER'
}

export enum DEPRECIATION_METHOD {
  NO_DEPRECIATION = 'No Depreciation',
  STRAIGHT_LINE = 'Straight Line',
  DECLINING_BALANCE = 'Declining Balance',
  INSTANT_ASSET_WRITE_OFF = 'Instant Asset Write-off'
}

export enum PRODUCT_OFFERING_TYPE {
  GOODS = 'GOODS',
  SERVICES = 'SERVICES'
}

export enum DOCUMENT_MODE {
  NEW = 'new',
  VIEW = 'view',
  EDIT = 'edit',
  LIST = 'list',
  COPY = 'copy',
  DIRECT = 'direct',
  EVENT = 'EVENT'
}
export enum APPROVAL_STATUS {
  EDITING = 'EDITING',
  PENDING_FOR_APPROVAL = 'PENDING_FOR_APPROVAL',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  NOT_REQUIRED = 'NOT_REQUIRED',
  PENDING = 'PENDING'
}
export enum STOCK_REQUEST_STATUS {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING'
}
export const APPROVAL_STATUS_LIST: any = {
  EDITING: 'Editing',
  PENDING_FOR_APPROVAL: 'Pending for approval',
  PENDING: 'Pending for approval',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  NOT_REQUIRED: 'Not Required'
};
export const REPORTS = {
  STATEMENT_OF_ACCOUNTS: 'STATEMENT_OF_ACCOUNTS',
  STATEMENT_OF_ACCOUNT: 'STATEMENT_OF_ACCOUNT',
  STATEMENT_OF_ACCOUNT_TITLE: 'Statement of Account',
  OUTSTANDING_PURCHASE_GRN_REPORT: 'Outstanding Purchases Good Received Report',
  OUTSTANDING_SALES_DO_REPORT: 'Outstanding Sales Fulfillment Report',
  OFFSET_INVOICE_TYPE: 'offsetInvoiceType',
  SALES: 'SALES',
  PURCHASE: 'PURCHASE',
  SOA: 'SOA'
};

export enum TemplateMappingEvent {
  EMAIL = 'EMAIL',
  PRINT = 'PRINT'
}

/* TAX RELATED CONST */
export enum APPLY_TO_TYPE {
  PRE = 'PRE',
  AFTER = 'AFTER',
  POST = 'POST'
}
export const CESS_RULE_AMOUNT = 'amount';
export const CESS_RULE_QUANTITY = 'quantity';
export const EXCISE_PERCENT = 'PERCENT';
export const EXCISE_FLAT = 'FLAT';

export enum IMPORT_MODULE {
  PRODUCT = 'PRODUCT',
  IMPORT_PRODUCT = 'IMPORT_PRODUCT',
  CONTACT = 'CONTACT',
  IMPORT_CONTACT = 'IMPORT_CONTACT',
  STOCK_TRANSFER = 'STOCK_TRANSFER',
  IMPORT_STOCK_TRANSFER = 'IMPORT_STOCK_TRANSFER',
  STOCK_ADJUSTMENT = 'STOCK_ADJUSTMENT',
  IMPORT_STOCK_ADJUSTMENT = 'IMPORT_STOCK_ADJUSTMENT',
  FULFILLMENT = 'FULFILLMENT',
  DELIVERY_IMPORT = 'DELIVERY_IMPORT',
  IMPORT_FULFILLMENT = 'IMPORT_FULFILLMENT',
  GOODS_RECEIPT = 'GOODS_RECEIPT',
  IMPORT_GOODS_RECEIPT = 'IMPORT_GOODS_RECEIPT',
  OPERATION = 'OPERATION',
  IMPORT_OPERATION = 'IMPORT_OPERATION',
  OPERATOR = 'OPERATOR',
  IMPORT_OPERATOR = 'IMPORT_OPERATOR',
  WORKSTATION = 'WORKSTATION',
  IMPORT_WORKSTATION = 'IMPORT_WORKSTATION',
  ACCOUNT = 'ACCOUNT',
  IMPORT_ACCOUNT = 'IMPORT_ACCOUNT',
  INVOICE = 'INVOICE',
  IMPORT_INVOICE = 'IMPORT_INVOICE',
  QUOTATION = 'QUOTATION',
  IMPORT_QUOTATION = 'IMPORT_QUOTATION',
  ORDER = 'ORDER',
  REQUISITION = 'REQUISITION',
  IMPORT_ORDER = 'IMPORT_ORDER',
  SALES_ORDER = 'SALES_ORDER',
  IMPORT_SALES_ORDER = 'IMPORT_SALES_ORDER',
  BILL = 'BILL',
  IMPORT_BILL = 'IMPORT_BILL',
  MAKE_PAYMENT = 'MAKE_PAYMENT',
  IMPORT_MAKE_PAYMENT = 'IMPORT_MAKE_PAYMENT',
  GET_PAID = 'GET_PAID',
  IMPORT_GET_PAID = 'IMPORT_GET_PAID',
  EXPENSE_BILL = 'EXPENSE_BILL',
  IMPORT_EXPENSE_BILL = 'IMPORT_EXPENSE_BILL',
  EXPENSE_PREPAYMENT = 'EXPENSE_PREPAYMENT',
  IMPORT_PREPAYMENT = 'IMPORT_EXPENSE_PREPAYMENT',
  DIRECT_DEPOSIT = 'DEPOSIT_DIRECT',
  IMPORT_DIRECT_DEPOSIT = 'IMPORT_DEPOSIT_DIRECT',
  ADVANCED_PAYMENT = 'DEPOSIT_ADVPAYMENT',
  IMPORT_ADVANCED_PAYMENT = 'IMPORT_DEPOSIT_ADVPAYMENT',
  NORMAL_JOURNAL = 'NORMAL_JOURNAL',
  BOOK_SPECIFIC_JOURNAL = 'BOOK_SPECIFIC_JOURNAL',
  PARTY_JOURNAL = 'PARTY_JOURNAL',
  IMPORT_NORMAL_JOURNAL = 'IMPORT_NORMAL_JOURNAL',

  IMPORT_PARTY_JOURNAL = 'IMPORT_PARTY_JOURNAL',
  FUND_TRANSFER_JOURNAL = 'FUND_TRANSFER_JOURNAL',
  IMPORT_FUND_TRANSFER_JOURNAL = 'IMPORT_NORMAL_JOURNAL',
  EXPENSE_DIRECT = 'EXPENSE_DIRECT',
  IMPORT_EXPENSE_DIRECT = 'IMPORT_EXPENSE_DIRECT',
  OPENING_ACCOUNT = 'ACCOUNT_OPENING',
  IMPORT_OPENING_ACCOUNT = 'IMPORT_ACCOUNT',
  INVENTORY_OPENING = 'INVENTORY_OPENING',
  IMPORT_INVENTORY_OPENING = 'IMPORT_INVENTORY_OPENING',
  OPENING_INVOICE = 'SI_OPENING',
  IMPORT_OPENING_INVOICE = 'IMPORT_SI_OPENING',
  OPENING_BILL = 'PI_OPENING',
  IMPORT_OPENING_BILL = 'IMPORT_PI_OPENING',
  BOM_PRODUCT = 'BOM_PRODUCT',
  IMPORT_BOM_PRODUCT = 'IMPORT_BOM_PRODUCT',
  BOM_PRODUCT_RECIPE_UPDATE = 'BOM_PRODUCT_RECIPE_UPDATE',
  IMPORT_BOM_PRODUCT_RECIPE_UPDATE = 'IMPORT_BOM_PRODUCT_RECIPE_UPDATE',
  FILE = 'file',
  FILE_ENTITY = 'file-entity',
  PRICE_LIST = 'PRICE_LIST',
  IMPORT_PRICE_LIST = 'IMPORT_PRICE_LIST',
  BANK_STATEMENT = 'BANK_STATEMENT',
  IMPORT_BANK_STATEMENT = 'IMPORT_BANK_STATEMENT',
  CLIENT = 'CLIENT',
  IMPORT_CLIENT = 'IMPORT_CLIENT',
  CREDIT_NOTE = 'CREDIT_NOTE',
  IMPORT_CREDIT_NOTE = 'IMPORT_CREDIT_NOTE',
  DEBIT_NOTE = 'DEBIT_NOTE',
  IMPORT_DEBIT_NOTE = 'IMPORT_DEBIT_NOTE',
  OPENING_CREDIT_NOTE = 'OPENING_CREDIT_NOTE',
  IMPORT_OPENING_CREDIT_NOTE = 'IMPORT_OPENING_CREDIT_NOTE',
  OPENING_DEBIT_NOTE = 'OPENING_DEBIT_NOTE',
  IMPORT_OPENING_DEBIT_NOTE = 'IMPORT_OPENING_DEBIT_NOTE',
  OPENING_EXPENSE_PREPAYMENT = 'OPENING_EXPENSE_PREPAYMENT',
  IMPORT_OPENING_EXPENSE_PREPAYMENT = 'IMPORT_OPENING_EXPENSE_PREPAYMENT',
  OPENING_ADV_PAYMENT = 'OPENING_ADV_PAYMENT',
  IMPORT_OPENING_ADV_PAYMENT = 'IMPORT_OPENING_ADV_PAYMENT',
  QB_INVOICE = 'QB_INVOICE',
  IMPORT_QB_INVOICE = 'IMPORT_QB_INVOICE',
  QB_BILL = 'QB_BILL',
  IMPORT_QB_BILL = 'IMPORT_QB_BILL',
  QB_JE = 'QB_NORMAL_JOURNAL',
  IMPORT_MACHINE = 'IMPORT_MACHINE',
  MRP_MACHINE = 'MACHINE',
  IMPORT_QB_JE = 'IMPORT_NORMAL_JOURNAL',
  IMPORT_BOOKSPECIFIC_JE = 'IMPORT_BOOKSPECIFIC_JE',
  IMPOS_BILL = 'IMPOS_BILL',
  IMPORT_IMPOS_BILL = 'IMPORT_IMPOS_BILL',
  IMPOS_INVOICE = 'IMPOS_INVOICE',
  IMPORT_IMPOS_INVOICE = 'IMPORT_IMPOS_INVOICE',
  TDS_RATE = 'TDS_RATE',
  IMPORT_TDS_RATE = 'IMPORT_TDS_RATE',
  JWO_GOODS_RECEIPT = 'JOB_WORK_OUT_ORDER_GOOD_RECEIPT',
  IMPORT_JWO_GOODS_RECEIPT = 'IMPORT_JWO_GOODS_RECEIPT',
  PREFERRED_VENDOR = 'PREFERREDVENDOR',
  IMPORT_PREFERRED_VENDORS = 'IMPORT_PREFERRED_VENDORS'
  //   QB_JE = 'QB_NORMAL_JOURNAL',
  //   IMPORT_QB_JE = 'IMPORT_QB_NORMAL_JOURNAL'
}

export enum ENTITY_TYPE_ATTACHMENT {
  PRODUCT_BOM_DETAILS = 'PRODUCT_BOM_DETAILS'
}

export enum IMPORT_MODULE_TO_PERMISSION {
  ACCOUNT_OPENING = 'erp_coa_import',
  ACCOUNT = 'erp_coa_import',
  CONTACT = 'erp_contact_import',
  STOCK_TRANSFER = 'erp_stock_transfer_import',
  PRODUCT = 'erp_product_import',
  BOM_PRODUCT = 'erp_product_import',
  BANK_STATEMENT = 'erp_bank_import_stmnt',
  INVOICE = 'erp_invoice_import',
  GET_PAID = 'erp_invoice_import',
  FULFILLMENT = 'erp_invoice_import',
  QB_INVOICE = 'erp_invoice_import',
  NORMAL_JOURNAL = 'erp_je_import',
  PARTY_JOURNAL = 'erp_je_import',
  FUND_TRANSFER_JOURNAL = 'erp_je_import',
  BOOK_SPECIFIC_JOURNAL = 'erp_je_import',
  SALES_ORDER = 'erp_sales_order_import',
  ORDER = 'erp_po_import',
  QUOTATION = 'erp_quote_import',
  EXPENSE_DIRECT = 'erp_expense_import',
  EXPENSE_PREPAYMENT = 'erp_expense_import',
  PRICE_LIST = 'erp_price_list_import',
  CREDIT_NOTE = 'erp_cndn_import',
  DEBIT_NOTE = 'erp_cndn_import',
  OPENING_CREDIT_NOTE = 'erp_cndn_import',
  OPENING_DEBIT_NOTE = 'erp_cndn_import',
  DEPOSIT_DIRECT = 'erp_deposit_import',
  DEPOSIT_ADVPAYMENT = 'erp_deposit_import',
  OPERATION = 'mrp_operations_import',
  WORKSTATION = 'mrp_workstation_import',
  OPERATOR = 'mrp_operators_import',
  GOODS_RECEIPT = 'goods_receipt_master_import',
  STOCK_ADJUSTMENT = 'erp_stock_adjustment_import'
}

export const IMPORT_CONSTANTS = {
  BOM_COMPONENT: {
    QUANTITY: 'Component Product Quantity',
    PRODUCT_ID: 'Component Product ID',
    COST_NAME: 'Additional Cost Name',
    COST_AMOUNT: 'Additional Cost Amount'
  },
  PRODUCT_ADVANCED: {
    SERIAL_NUMBER: 'Serial Number',
    BATCH_NUMBER: 'Batch Number',
    BATCH_QUANTITY: 'Batch Quantity',
    MANUFACTURED_DATE: 'Manufactured Date',
    EXPIRY_DATE: 'Expiry Date'
  },
  NUMBER: 'Number',
  PRODUCT_NAME: 'Product Name',
  INVOICE_NUMBER: 'Invoice No.',
  PRICE_LIST: 'Price List',
  PAY_FROM: 'Pay From',
  DEPOSIT_TO: 'Deposit To',
  SALES_INVOICE_DATE: 'Sales Invoice Date',
  INVOICE_DATE: 'Invoice Date',
  INVOICE_DUE_DATE: 'Invoice Due Date',
  DUE_DATE: 'Due Date',
  PURCHASE_INVOICE_DATE: 'Purchase Invoice Date',
  JOURNAL_ENTRY_DATE: 'Journal Entry Date',
  EFFECTIVE_DATE: 'Effective Date',
  TRANSACTION_DATE: 'Transaction Date',
  CHEQUE_DATE: 'Cheque Date',
  FILE: 'file',
  FILE_ENTITY: 'file-entity',
  SUBMODULES: {
    OPENING_ACCOUNT: 'opening_accounts',
    OPENING_INVENTORY: 'opening_inventory',
    BOM: 'bom',
    BOM_UPDATE: 'bom_update',
    OPENING_INVOICE: 'opening_invoice',
    OPENING_BILL: 'opening_bill',
    OPENING_EXPENSE_PREPAYMENT: 'opening_expense_prepayment',
    OPENING_ADV_PAYMENT: 'opening_adv_payment',
    OPENING_CREDIT_NOTE: 'opening_credit_note',
    OPENING_DEBIT_NOTE: 'opening_debit_note',
    EXPENSE_DIRECT: 'expense_direct',
    EXPENSE_PREPAYMENT: 'expense_prepayment',
    DIRECT_DEPOSIT: 'direct_deposit',
    ADVANCED_PAYMENT: 'advanced_payment',
    NORMAL_JE: 'normal_je',
    PARTY_JE: 'party_je',
    FUNDS_TRANSFER_JE: 'funds_transfer_je',
    BILL: 'bill',
    MAKE_PAYMENT: 'make_payment',
    GET_PAID: 'get_paid',
    EXPENSE_BILL: 'expense_bill',
    QB_INVOICE: 'qb_invoice',
    QB_BILL: 'qb_bill',
    QB_JE: 'qb_journal',
    FULFILLMENT: 'fulfillment',
    DELIVERY_ORDER: 'delivery_order',
    BOOK_SPECIFIC_JE: 'book_specific_je',
    GOODS_RECEIPT: 'goods_receipt',
    IMPOS_BILL: 'IMPOS_BILL',
    IMPOS_INVOICE: 'IMPOS_INVOICE'
  },
  TITLE: {
    CONTACT: 'Import Contacts',
    JWO_RG: 'Import JWO Receive Goods',
    STOCK_TRANSFER: 'Import Stock Transfer',
    STOCK_ADJUSTMENT: 'Import Stock Adjustment',
    FULFILLMENT: 'Import Fulfillment',
    DELIVERY_ORDER: 'Import Delivery Order',
    BOOK_SPECIFIC_JE: 'Import Book Specific JE',
    GOODS_RECEIPT: 'Import Goods Receipt',
    OPERATIONS: 'Import Operations',
    OPERATORS: 'Import Operators',
    WORKSTATION: 'Import Workstations',
    INVOICE: 'Import Invoices',
    QB_INVOICE: 'Import QB Invoices',
    QB_JE: 'Import QB JE',
    QUOTATION: 'Import Quotations',
    ORDER: 'Import Orders',
    SALES_ORDER: 'Import Sales Orders',
    PRODUCT: 'Import Products',
    BOM: 'Import Bill of Material',
    MRP_BOM: 'Import WIP Materials/Finished Goods',
    BILL: 'Import Bill',
    CREDIT_NOTE: 'Import Credit Note',
    DEBIT_NOTE: 'Import Debit Note',
    OPENING_CREDIT_NOTE: 'Import Opening Credit Note',
    OPENING_DEBIT_NOTE: 'Import Opening Debit Note',
    OPENING_EXPENSE_PREPAYMENT: 'Import Opening Prepayment',
    OPENING_ADV_PAYMENT: 'Import Opening Advanced Payment',
    MAKE_PAYMENT: 'Import Make Payment',
    GET_PAID: 'Import Get Paid',
    EXPENSE_BILL: 'Import Expense Bill',
    EXPENSE_DIRECT: 'Import Direct Expense',
    DIRECT_DEPOSIT: 'Import Direct Deposit',
    ADVANCED_PAYMENT: 'Import Advanced Payment',
    EXPENSE_PREPAYMENT: 'Import Prepayment Expense',
    ACCOUNTS: 'Import Accounts',
    OPENING_ACCOUNTS: 'Import Opening Accounts',
    OPENING_INVENTORY: 'Import Opening Inventory',
    OPENING_INVOICE: 'Import Opening Invoices',
    OPENING_BILL: 'Import Opening Bills',
    NORMAL_JE: 'Import Journal Entry',
    PARTY_JE: 'Import Party Journal Entry',
    FUNDS_TRANSFER_JE: 'Import Funds Transfer JE',
    DEFAULT: 'Import Data',
    PRICE_LIST: 'Import Price List',
    PRICE_BOOK: 'Import Price Book',
    BANK_STATEMENT: 'Import Bank Statement',
    QB_BILL: 'QB Bill',
    IMPOS_BILL: 'Import Custom Bill',
    IMPOS_INVOICE: 'Import Custom Invoice',
    TDS_RATE: 'Import TDS Rate',
    PREFERRED_VENDOR: 'Import Preferred Vendor'
  },
  SUCCESS_IMPORT_MESSAGE: '',
  MODULE: 'module',
  IS_MRP: 'mrp',
  BANK_CODE: 'bankAccountCode',
  SUBMODULE: 'submodule',
  IS_DRAFT_IMPORT: 'isDraftImport',
  ORIGIN: 'origin',
  ROUTE: {
    IMPORT_MACHINE:
      '/import-portal?module=mrp_machine&origin=/mrp/machine-tool-master',
    CONTACT: 'import-portal?module=books_contact&origin=/contacts',
    STOCK_TRANSFER:
      '/import-portal?module=books_stock_transfer&origin=/stock-management/stock-transfer',
    STOCK_ADJUSTMENT:
      '/import-portal?module=books_stock_adjustment&origin=/stock-management/stock-adjustment',
    MRP_OPERATION:
      '/import-portal?module=books_operations&origin=/mrp/operations',
    MRP_OPERATORS:
      '/import-portal?module=books_operators&origin=/mrp/operator-list',
    MRP_WORKSTATION:
      '/import-portal?module=books_workstations&origin=/mrp/work-stations',
    INVOICE: 'import-portal?module=books_invoice&origin=/invoices',
    JWO_RG: '/import-portal?module=books_jwo&origin=/mrp/job-work-out',
    FULFILLMENT:
      'import-portal?module=books_invoice&origin=/invoices&submodule=fulfillment',
    DELIVERY_ORDER:
      'import-portal?module=books_invoice&origin=/invoices&submodule=delivery_order',
    FULFILLMENT_SO:
      'import-portal?module=books_invoice&origin=/sales-orders&submodule=fulfillment',
    GOODS_RECEIPT:
      'import-portal?module=books_bill&origin=/bills&submodule=goods_receipt',
    GOODS_RECEIPT_PO:
      'import-portal?module=books_bill&origin=/purchase-orders&submodule=goods_receipt',
    RFQ: 'import-portal?module=books_rfq&origin=/rfq',
    QB_INVOICE:
      'import-portal?module=books_invoice&submodule=qb_invoice&origin=/invoices',
    QB_BILL: 'import-portal?module=books_bill&submodule=qb_bill&origin=/bills',
    BILL: 'import-portal?module=books_bill&origin=/bills&submodule=bill',
    MAKE_PAYMENT:
      'import-portal?module=books_bill&origin=/bills&submodule=make_payment',
    RECEIVE_PAYMENT:
      'import-portal?module=books_invoice&origin=/invoices&submodule=get_paid',
    EXPENSE_BILL:
      'import-portal?module=books_bill&origin=/bills&submodule=expense_bill',
    PRODUCT: 'import-portal?module=books_product&origin=/products',
    BOM: 'import-portal?module=books_product&origin=/products&submodule=bom',
    PREFERRED_VENDOR: 'import-portal?module=preferred_vendors&origin=/products',
    MRP_PRODUCT:
      '/import-portal?module=books_product&origin=/mrp/products&mrp=true',
    MRP_BOM:
      '/import-portal?module=books_product&origin=/mrp/products&submodule=bom&mrp=true',
    MRP_BOM_UPDATE:
      '/import-portal?module=books_product&origin=/mrp/products&submodule=bom_update&mrp=true',
    EXPENSE_DIRECT:
      '/import-portal?module=books_expense&origin=/accounting/expense&submodule=expense_direct',
    EXPENSE_PREPAYMENT:
      '/import-portal?module=books_expense&origin=/accounting/expense&submodule=expense_prepayment',
    DIRECT_DEPOSIT:
      '/import-portal?module=books_deposit&origin=/accounting/deposit&submodule=direct_deposit',
    ADVANCED_PAYMENT:
      '/import-portal?module=books_deposit&origin=/accounting/deposit&submodule=advanced_payment',
    ACCOUNTS: '/import-portal?module=books_accounts&origin=/accounting/coa',
    OPENING_ACCOUNTS:
      '/import-portal?module=books_accounts&origin=/accounting/openingbalance&submodule=opening_accounts',
    OPENING_INVENTORY:
      '/import-portal?module=books_product&origin=/accounting/openingbalance&submodule=opening_inventory',
    OPENING_INVOICE:
      '/import-portal?module=books_invoice&origin=/accounting/openingbalance&submodule=opening_invoice',
    OPENING_BILL:
      '/import-portal?module=books_bill&origin=/accounting/openingbalance&submodule=opening_bill',
    NORMAL_JE:
      '/import-portal?module=books_journal_entry&origin=/accounting/journal-entry&submodule=normal_je',
    BOOK_SPECIFIC_JE:
      '/import-portal?module=books_journal_entry&origin=/accounting/journal-entry&submodule=book_specific_je',
    FUNDS_TRANSFER_JE:
      '/import-portal?module=books_journal_entry&origin=/accounting/journal-entry&submodule=funds_transfer_je',
    QB_JE:
      '/import-portal?module=books_journal_entry&submodule=qb_journal&origin=/accounting/journal-entry',
    PARTY_JE:
      '/import-portal?module=books_journal_entry&origin=/accounting/journal-entry&submodule=party_je',
    CLIENT: '/import-portal?module=book_keeper_client&origin=/client',
    QUOTATION: 'import-portal?module=books_quote&origin=/quotes',
    ORDER: 'import-portal?module=books_order&origin=/purchase-orders',
    REQUISITION:
      'import-portal?module=books_requisition&origin=/requisition-orders',
    SALES_ORDER: 'import-portal?module=books_sales_order&origin=/sales-orders',
    CREDIT_NOTE:
      '/import-portal?module=books_credit_notes&origin=/accounting/creditnotes',
    DEBIT_NOTE:
      '/import-portal?module=books_debit_notes&origin=accounting/debitnotes',
    OPENING_CREDIT_NOTE:
      '/import-portal?module=books_credit_notes&origin=/accounting/openingbalance&submodule=opening_credit_note',
    OPENING_DEBIT_NOTE:
      '/import-portal?module=books_debit_notes&origin=/accounting/openingbalance&submodule=opening_debit_note',
    OPENING_EXPENSE_PREPAYMENT:
      '/import-portal?module=books_expense&origin=/accounting/openingbalance&submodule=opening_expense_prepayment',
    OPENING_ADV_PAYMENT:
      '/import-portal?module=books_deposit&origin=/accounting/openingbalance&submodule=opening_adv_payment',
    TDS_RATE: '/import-portal?module=tds_rate&origin='
  }
};
export enum TEXT_ALIGNMENT {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right'
}

export const DATE_CONSTANTS = {
  DEFAULT_DATE_FORMAT: 'YYYY-MM-DD',
  SELL_BUY_SERVER_FORMAT: 'D-M-YYYY',
  GRAFANA_SERVER_DEFAULT_FORMAT: '%d-%m-%Y',
  PPS_SERVER_FORMAT: 'DD-MM-YYYY',
  JE_SERVER_FORMAT: 'DD-MM-YYYY',
  DEFUALT_SERVER_FORMAT: 'DD-MM-YYYY',
  IMPORT_LOG_SERVER_FORMAT: 'yyyy-MM-dd',
  EWAY_SERVER_FROMAT_TO_SAVE: 'dd/MM/yyyy',
  EXPORT_REPORT_FORMAT: 'dd-MM-yyyy',
  DEFAULT_LOCALE: 'en_US'
};

export const DATE_FORMAT_LIST = [
  { format: 'D MMM YYYY', serverFormat: '%e %b %Y' },
  { format: 'DD-MM-YYYY', serverFormat: '%d-%m-%Y' },
  { format: 'YYYY-MM-DD', serverFormat: '%Y-%m-%d' },
  { format: 'MM-DD-YYYY', serverFormat: '%m-%d-%Y' },
  { format: 'MM/DD/YYYY', serverFormat: '%m/%d/%Y' },
  { format: 'DD/MM/YYYY', serverFormat: '%d/%m/%Y' }
];

export const NUMBER_FORMAT_LIST = [
  { id: NUMBER_FORMAT.US, value: '123,456.00' },
  { id: NUMBER_FORMAT.ES, value: '123.456,00' },
  { id: NUMBER_FORMAT.FR, value: '123 456,00' },
  { id: NUMBER_FORMAT.IN, value: '1,23,456.00' }
];

export const ORG_BUSINESS_TYPE = [
  { id: 'SOLE_PROPRIETORSHIP', name: 'Sole Proprietorship' },
  { id: 'PARTNERSHIP', name: 'Partnership' },
  { id: 'LLP', name: 'LLP' },
  { id: 'S_CORPORATION', name: 'S Corporation' },
  { id: 'CORPORATION', name: 'Corporation' },
  { id: 'LLC', name: 'LLC' },
  { id: 'NON_PROFIT_ORGANIZATION', name: 'Non-Profit Organization' },
  {
    id: 'CHARITABLE_ORGANIZATION',
    name: '501(c)(3) Charitable Organization'
  },
  { id: 'PRIVATE_LIMITED_COMPANY', name: 'Private Limited Company' }
];
export const ROUNDING_METHOD_OPTIONS = [
  { id: 'ROUND_OFF', name: 'Default Round Off' },
  { id: 'ROUND_UP', name: 'Round Up' },
  { id: 'ROUND_DOWN', name: 'Round Down' }
];

export enum DATE_FORMAT {
  DD_MM_YYYY = 'dd-mm-yyyy',
  DD_MM_YYYY_HH_MM_SS = 'dd-mm-yyyy HH-MM-SS',
  DD_MM_YYYYTHH_MM_SS = 'dd-mm-yyyyTHH-MM-SS',
  YYYY_MM_DD = 'yyyy-mm-dd',
  dd_MMM = 'dd MMM',
  yyyy_MM_dd = 'yyyy-MM-dd',
  HH_MM_SS = 'HH-MM-SS'
}

export enum DAY_OF_WEEK {
  SUNDAY = 0,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6
}

export enum BOOKS_DATE_FORMAT {
  'D MMM YYYY' = 'D MMM YYYY',
  'DD-MM-YYYY' = 'DD-MM-YYYY',
  'YYYY-MM-DD' = 'YYYY-MM-DD',
  'MM-DD-YYYY' = 'MM-DD-YYYY',
  'MM/DD/YYYY' = 'MM/DD/YYYY',
  'DD/MM/YYYY' = 'DD/MM/YYYY',
  'DATE_FORMAT_MEDIUM' = 'DD-MM-YYYY HH:mm:ss',
  'ISO-FORMAT' = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z",
  'HH:MM A' = 'hh:mm a',
  'HH:MM:SS A' = 'hh:mm:ss a',
  'ISO-8601' = "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
  'AUDIT_TIME' = 'dd-MMMM-yyyy HH:mm:ss x'
}

export const CHART_TYPE = {
  BAR_VERTICAL: 'Bar Vertical Graph',
  MULTI_BAR_VERTICAL: 'Multi Vertical Bar Graph',
  BAR_HORIZONTAL: 'Bar Horizontal Graph',
  MULTI_BAR_HORIZONTAL: 'Multi Bar Horizontal Graph',
  LINE: 'Line Chart',
  PIE: 'Pie Chart',
  DONUT: 'DONUT'
};

export const CHART_API_SOURCES = {
  INVOICES: 'INVOICES',
  AGE_RECEIVABLE: 'AGE_RECEIVABLE',
  BANK_ACCOUNTS: 'BANK_ACCOUNTS',
  BILL: 'BILL',
  CASHFLOW: 'CASHFLOW',
  PROFIT_AND_LOSS: 'PROFIT_AND_LOSS'
};

export const COLORS = {
  orange: 'rgb(240, 126, 37)',
  charts: {
    bar_vertical: 'rgb(38, 144, 240)',
    bar_horizontal: 'rgb(52, 195, 161)',
    line: {
      green: 'rgb(52, 195, 161)',
      red: 'rgb(240, 100, 111)',
      yellow: 'rgb(245, 200, 60)'
    },
    blue: 'rgb(28, 115, 232)',
    pie: [
      'rgb(130, 112, 234)',
      'rgb(250, 200, 85)',
      'rgb(225, 105, 130)',
      'rgb(52, 195, 161)',
      'rgb(143, 232, 236)',
      'rgb(38, 144, 240)',
      'rgb(240, 100, 111)',
      'rgb(245, 200, 60)',
      'rgb(160,160,160)'
    ]
  }
};

export const DESIGNER_TEMPLATE = {
  id: 0,
  templateName: DOCUMENT_DESIGNER,
  appName: APP_NAME,
  templateData: ''
};

export const DESIGNER_THERMAL_TEMPLATE = {
  id: 'THERMAL_PRINT',
  templateName: DOCUMENT_THERMAL_DESIGNER,
  appName: APP_NAME,
  templateData: '',
  pageFormat: THREE_FIVE
};
export enum SMTP_FIELD_KEY_NAMES {
  SENDERNAME = 'senderName',
  EMAILADDRESS = 'emailAddress',
  USERNAME = 'username',
  PASSWORD = 'password',
  SERVER = 'server',
  PORT = 'port',
  ALLOWNONSECURE = 'allowNonSecure',
  TOEMAIL = 'toEmail'
}
export enum SMTP_FIELD_SECTION_NAMES {
  SMTPFORMFIELD = 'smtpFormField'
}
export const COUNTRY_CODES = {
  US: 'US',
  IN: 'IN',
  ID: 'ID',
  SG: 'SG',
  PH: 'PH',
  AE: 'AE', //Used for UAE
  MY: 'MY',
  AU: 'AU',
  NL: 'NL',
  BE: 'BE',
  CA: 'CA',
  NZ: 'NZ',
  UK: 'UK',
  DE: 'DE',
  SA: 'SA',
  IL: 'IL',
  KR: 'KR'
};

export const COUNTRY_INITIALS = {
  US: 'us',
  ID: 'id',
  IN: 'ind',
  PH: 'ph',
  AE: 'uae', //Used for UAE
  MY: 'my',
  MYR: 'malaysia',
  AU: 'aus',
  NZ: 'nz',
  NL: 'nl',
  BE: 'be',
  CA: 'can',
  UK: 'uk',
  DE: 'de',
  SA: 'sa'
};

export const CURRENCIES: { [key: string]: string } = {
  SG: 'SGD',
  MY: 'MYR',
  US: 'USD',
  IN: 'INR',
  PH: 'PHP',
  ID: 'IDR',
  AE: 'AED',
  AU: 'AUD',
  NL: 'EUR',
  BE: 'EUR',
  CA: 'CAD',
  NZ: 'NZD',
  UK: 'GBP',
  DE: 'EUR'
};

export const FULFILLMENT_REPORT_TABLE_PLOTTER = {
  BATCH: 'BATCH',
  SERIAL: 'SERIAL'
};

export const COMPLAINCE_CURRENCY: { [key: string]: string } = {
  [TAX_SYSTEM.SG]: CURRENCIES.SG,
  [TAX_SYSTEM.UK]: CURRENCIES.UK,
  [TAX_SYSTEM.BE]: CURRENCIES.BE,
  [TAX_SYSTEM.DE]: CURRENCIES.DE
};

export const RECONCIL_STATUS = {
  MATCHED: 'matched',
  UNMATCHED: 'unmatched'
};
export const BANK_TRNX_STATUS = {
  MATCHED: 'matched',
  UNMATCHED: 'unmatched',
  RECONCILED: 'reconciled',
  UNRECONCILED: 'unreconcile'
};

export const BANK_TRNX_STATUS_ACTIONS = {
  MATCH: 'match',
  UNMATCH: 'unmatch',
  UNRECONCIL: 'unreconcile'
};

export const EXPENSE_OPTIONS = {
  DIRECT_EXPENSE: 'Direct Expense',
  PREPAYMENT: 'Expense Prepayment'
};

export const DEPOSIT_OPTIONS: any = {
  ADVANCE_PAYMENT: 'Deposit Advpayment',
  SALES_INVOICE: 'Sales Invoice',
  DEPOSIT_ADVPAYMENT: 'DEPOSIT_ADVPAYMENT',
  GRID_ADVANCE_PAYMENT_WITH_SPACE: 'Advance Payment',
  BANK_DEPOSIT: 'Bank Deposit'
};

export const DEPOSIT_TYPE = {
  ALL: 'ALL',
  DEPOSIT: 'DEPOSIT',
  BANK_DEPOSIT: 'BANK_DEPOSIT',
  DEPOSIT_ADVPAYMENT: 'DEPOSIT_ADVPAYMENT'
};

export const MATCH_TYPE = {
  FIND_INVOICE_TO_MATCH: 'FIND_INVOICE_TO_MATCH',
  FIND_DEPOSIT_TO_MATCH: 'FIND_DEPOSIT_TO_MATCH',
  FIND_BILL_TO_MATCH: 'FIND_BILL_TO_MATCH',
  FIND_EXPENSE_TO_MATCH: 'FIND_EXPENSE_TO_MATCH'
};

export const TRANSITION_STATUS = {
  COMPLETED: 'Completed',
  IN_PROGRESS: 'In Progress',
  ON_HOLD: 'On Hold',
  CANCELLED: 'Cancelled',
  getByName: function (name: string) {
    switch (name) {
      case 'COMPLETED':
        return this.COMPLETED;
      case 'IN_PROGRESS':
        return this.IN_PROGRESS;
      case 'ON_HOLD':
        return this.ON_HOLD;
      case 'CANCELLED':
        return this.CANCELLED;
    }
    return '';
  }
};

export enum FILTER_KEYS {
  PRODUCT_TRANSACTION_TYPE = 'productTransactionType',
  CUSTOMER_ORDER_NUMBER = 'customerOrderNumber',
  STATUS = 'status',
  TYPE = 'type',
  VENDOR = 'vendor',
  CUSTOMER = 'customer',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  BILL_OF_MATERIALS = 'bill_of_materials',
  TRACKED = 'tracked',
  NON_TRACKED = 'non_tracked',
  ACC_GROUP_ID = 'accountGroup.id',
  FULFILLMENT_STATUS = 'fulfillmentStatus',
  PAYMENT_STATUS = 'paymentStatus',
  DOCUMENT_TYPE = 'documentType',
  DOC_TYPE = 'docType',
  EXPENSE = 'EXPENSE',
  EXPENSE_PREPAYMENT = 'EXPENSE_PREPAYMENT',
  DEPOSIT = 'DEPOSIT',
  DEPOSIT_ADVPAYMENT = 'DEPOSIT_ADVPAYMENT',
  ST_RECONCILED_STATUS = 'statementReconcliedStatus',
  RECONCILED = 'reconciled',
  UNRECONCILED = 'unreconciled',
  IN_PROGRESS = 'in progress',
  ALL_STATUS = 'all status',
  RECEIVED_GOODS_STATUS = 'receiveGoodsStatus',
  RECEIPT_STATUS = 'receiptStatus',
  CREDIT = 'credit',
  DEBIT = 'debit',
  CD_TYPE = 'cdType',
  MATCHED = 'matched',
  UNMATCHED = 'unmatched',
  IS_MATCH_BY_USER = 'isMatchedByUser',
  ALL_TYPE = 'alltype',
  ALLSTATUS = 'allstatus',
  PENDING = 'pending',
  SCHEDULED = 'scheduled',
  DELAYED = 'delayed',
  COMPLETED = 'completed',
  TRANSACTIONS_TYPE = 'transactionType',
  ALL = 'all',
  PAYMENT_TYPE = 'paymentType',
  PAY_FROM = 'accountName',
  PAY_FROM_ACCOUNT_CODE = 'accountCode',
  PAY_TO = 'accountName',
  PAY_TO_ACCOUNT_CODE = 'accountCode',
  IS_CREATED_BY_RULE = 'isCreateByRule',
  CREATED_BY_RULE = 'createdByRule',
  AUTO_CREATED = 'auto_created',
  NOT_AUTO_CREATED = 'not_auto_create',
  CUSTOM_FIELD = 'customfield',
  RECURRING_JE = 'recurringJE',
  NON_RECURRING_JE = 'nonRecurringJE',
  APPROVAL_STATUS = 'approvalStatus',
  INVOICE_NOW = 'invoiceNow',
  SALES_INVOICE_DATE = 'salesInvoiceDate',
  INVOICE_DUE_DATE = 'salesInvoiceDueDate',
  BILL_DUE_DATE = 'purchaseInvoiceDueDate',
  CREATED_BY = 'createdBy',
  PURCHASE_INVOICE_TYPE = 'purchaseInvoiceType',
  QUOTE_DUE_DATE = 'validTillDate',
  SALES_ORDER_DUE_DATE = 'salesOrderDueDate',
  RESERVED_STOCK = 'reservedStock',
  RECURRING = 'recurring',
  TOTAL_AMOUNT = 'totalAmount',
  DUE_AMOUNT = 'dueAmount',
  FIELD_TYPE = 'fieldType',
  MODULES = 'modules',
  CODE = 'code',
  JOB_CARD_CODE = 'jobCardCode',
  DOCUMENT_SEQUENCE_CODE = 'documentSequenceCode',
  WORK_ORDER = 'workOrderSeqCode',
  LINKED_WORK_ORDER = 'linkedWorkOrder',
  SALES_ORDER = 'salesOrderSequenceCode',
  WORK_ORDER_NAME = 'workOrderItemName',
  WORK_ORDER_ACTUAL_START_DATE = 'actualStartDate',
  WORK_ORDER_ACTUAL_END_DATE = 'actualEndDate',
  OPERATION_NAME = 'operationName',
  WORKSTARION_NAME = 'workStationName',
  WORK_STATION_NAME = 'workstationName',
  PRODUCTION_CAPACITY = 'productionCapacity',
  WORK_ORDER_NUMBER = 'documentSequenceCode',
  PARENT_WORK_ORDER_NUMBER = 'documentSequenceCode',
  PARENT_WORK_ORDER_NUMBER_KEY = 'parentWorkOrderSeqCode',
  WORK_ORDER_PRODUCT_NAME = 'productName',
  NAME = 'name',
  DEFAULT_WORK_STATION = 'defaultWorkstation',
  DESCRIPTION = 'description',
  COST_PER_HOUR = 'costPerHour',
  FIXED_RATE = 'fixedRate',
  CONTACT_NUMBER = 'contactNumber',
  JOB_TITLE = 'jobTitle',
  PURCHASE_PRICE = 'purchasePrice',
  RESTOCK_LEVEL = 'restocklevel',
  RESTOCK_LEVEL_API_KEY = 'restockLevel',
  BARCODE = 'barcode',
  WAREHOUSE = 'warehouseName',
  ADJUSTMENT_REASON = 'adjustmentReason',
  REASON = 'reason',
  STOCK_TYPE = 'adjustmentType',
  BILL_STATUS = 'billedStatus',
  DISPATCHED_STATUS = 'dispatchStatus',
  DOCUMENT_DATE = 'documentDate',
  CONTACT_NAME = 'contactName',
  CONTACT = 'contact',
  CONTACT_CODE = 'contactCode',
  PAYMENT_DATE = 'paymentDate',
  SUPPLIER_INVOICE_NO = 'supplierInvoiceNo',
  BILL_TO = 'billTo',
  SHIP_TO = 'shipTo',
  SEQUENCE_NO_GR = 'sequenceNoGR',
  GRN_DATE = 'grnDate',
  SEQUENCE_NO_FF = 'sequenceNoFF',
  FULFILLMENT_DATE = 'fulfillmentDate',
  REORDER_LEVEL = 'reorder',
  PRODUCT_DOC_SEQ_CODE = 'productDocSeqCode',
  PRODUCT_CODE = 'productCode',
  WO_DELIVERY_DATE = 'deliveryDate',
  CATEGORY = 'type',
  REQUISITION_REQUEST_DATE = 'purchaseRequestDate',
  REQUISITION_NUMBER = 'number',
  REQUISITION_SHIP_DATE = 'dueDate',
  REQUISITION_LINKED_PO = 'LinkedPO',
  REQUISITION_RECEIPT_STATUS = 'receipt_status',
  REQUISITION_APPROVAL_STATUS = 'approvalStatus',
  REQUISITION_APPROVAL_STATUS_FILTER = 'approval_status_filter',
  REQUISITION_REQUESTED_BY = 'requested_by',
  REQUISITION_PRODUCT_NAME = 'productName',
  REQUISITION_PRODUCT_CODE = 'productCode',
  E_WAY_BILL_NUMBER = 'ewayBillNumber',
  E_WAY_BILL_STATUS = 'ewayBillStatus',
  E_WAY_BILL_NO = 'ewayBillNo',
  RFQ_DOC_SEQ_CODE = 'documentSequenceCode',
  RFQ_RFQ_DATE = 'rfqDate',
  SALES_ORDER_SHIP_BY_DATE = 'shipByDate'
}

export const REQUISTIION_ENTITY_MAPPING = {
  [FILTER_KEYS.REQUISITION_REQUEST_DATE]: 'purchaseRequestDate',
  [FILTER_KEYS.REQUISITION_NUMBER]: 'documentSequenceCode',
  [FILTER_KEYS.REQUISITION_SHIP_DATE]: 'shipByDate',
  [FILTER_KEYS.REQUISITION_LINKED_PO]: 'LinkedPO',
  [FILTER_KEYS.REQUISITION_RECEIPT_STATUS]: 'status',
  [FILTER_KEYS.REQUISITION_APPROVAL_STATUS_FILTER]: 'approveStatus',
  [FILTER_KEYS.REQUISITION_REQUESTED_BY]: 'createdBy',
  [FILTER_KEYS.REQUISITION_PRODUCT_CODE]:
    'purchaseRequestItems.documentSequenceCode',
  [FILTER_KEYS.REQUISITION_PRODUCT_NAME]: 'purchaseRequestItems.productName'
};

const authData = {
  _isOwner: false
};
export default authData;

export const FIELD_TYPE = {
  SELECT: 'select',
  TEXTFIELD: 'textField',
  LABEL: 'label',
  CHECKBOX: 'checkBox',
  RADIO: 'radio',
  LIST_PICKER: 'listPicker',
  LIST_PICKER2: 'listPicker2',
  RADIO_SELECTION: 'radioSelection'
};

export const TOKEN_EXPIRED_IDENTIFIER = 'Invalid access token:';

export const COUNTRY_SPECIFIC_URL_CODE = {
  IN: '/in',
  IL: '/il',
  SG: '/sg',
  US: '/us',
  INDONESIA: '/id',
  MALAYSIA: '/my',
  UAE: '/ae',
  PHILIPPINES: '/ph',
  CANADA: '/ca',
  UK: '/uk',
  ISRAEL: '/il'
};

export const EXPORT_FILE_TYPE = ['PDF', 'XLS', 'CSV'];

export const DEFAULT_ACCOUNTS_FOR_CONTACT = {
  RECEIVEABLE_ACCOUNT: 'AC-0000003',
  PAYABLE_ACCOUNT: 'AC-0000013',
  PAYMENT_TERM: 4
};

export const DEFAULT_CODES_FOR_PRODUCT = {
  SG_PURCHASE_TAX: 'TX-0000016',
  SG_SALES_TAX: 'TX-0000001'
};

export const BOOLEAN_TYPE = {
  TRUE: 'true',
  FALSE: 'false',
  true: 'true',
  false: 'false'
};

export const currencyDropdownStyle = {
  menu: (provided: any, state: any) => ({
    ...provided,
    width: '100%'
  }),
  menuPortal: (provided: any, state: any) => ({
    ...provided,
    zIndex: 650
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: '#f4f4f6',
    border: '1px solid rgb(235, 235, 235)'
  }),
  dropdownIndicator: (prevStyle: any, state: any) =>
    state.isDisabled
      ? {
          ...prevStyle,
          display: 'none'
        }
      : {
          ...prevStyle
        }
};

export const gstTreatmentWithoutGSTIN = [
  'UNREGISTERED_BUSINESS',
  'CUSTOMER',
  'OVERSEAS'
];

export const COMPOSTION_TAX_PERCENT: any = {
  ONE_PERCENT: 1,
  TWO_PERCENT: 2,
  FIVE_PERCENT: 5,
  SIX_PERCENT: 6
};

export const PICK_PACK_SHIP_TABS: any = {
  ALL: 'all',
  PENDING: 'pending',
  PICKING_LIST: 'pickinglist',
  PACKING_LIST: 'packinglist',
  SHIPPING_LIST: 'shippinglist'
};

export const PPS_STATUSES: any = {
  SHIP: {
    IN_PROGRESS: 'SHIPPING_IN_PROGRESS',
    SHIPPED: 'SHIPPED',
    READY_TO_SHIP: 'READY_TO_SHIP',
    PARTIAL_SHIPPED: 'PARTIAL_SHIPPED'
  },
  PACK: {
    READY_TO_PACK: 'READY_TO_PACK',
    PACKED: 'PACKED',
    IN_PROGRESS: 'PACKING_IN_PROGRESS'
  },
  PICK: {
    PICKED: 'PICKED',
    IN_PROGRESS: 'PICKING_IN_PROGRESS',
    READY_TO_PICK: 'READY_TO_PICK'
  }
};

const envURLs = process.env.REACT_APP_PAYMENT_METHODS
  ? process.env.REACT_APP_PAYMENT_METHODS
  : '';
const envUrlObj = JSON.parse(envURLs);
export const PAYMENT_METHODS: any = {
  STRIPE: {
    label: 'Stripe',
    link: envUrlObj.stripe,
    icon: ic_stripe,
    isConnected: false,
    key: 'stripe',
    paymentInfo: null
  },
  CASHFREE: {
    label: 'Cashfree',
    link: envUrlObj.cashfree,
    icon: ic_cashfree,
    isConnected: false,
    key: 'cashfree',
    paymentInfo: null
  },
  RAZORPAY: {
    label: 'Razorpay',
    link: envUrlObj.razorpay,
    icon: ic_razorpay,
    isConnected: false,
    key: 'razorpay',
    paymentInfo: null
  },
  PAYTM: {
    label: 'Paytm',
    link: envUrlObj.paytm,
    icon: ic_paytm,
    isConnected: false,
    key: 'paytm',
    paymentInfo: null
  },
  PAYPAL: {
    label: 'Paypal',
    link: envUrlObj.paypal,
    icon: ic_paypal,
    isConnected: false,
    key: 'paypal',
    paymentInfo: null
  },
  VEEM: {
    label: 'Veem',
    link: envUrlObj.veem,
    icon: ic_veem,
    isConnected: false,
    key: 'veem',
    paymentInfo: null
  },
  // WISE: {
  //   label: 'Wise',
  //   link: envUrlObj.wise,
  //   icon: ic_paypal, //need to change
  //   isConnected: false,
  //   key: 'wise',
  //   paymentInfo: null
  // },
  OPENNODE: {
    label: 'Opennode',
    link: envUrlObj.opennode,
    icon: ic_opennode,
    isConnected: false,
    key: 'opennode',
    paymentInfo: null
  },
  NOT_APPLICABLE: {
    label: 'Not applicable',
    link: null,
    icon: null,
    isConnected: false,
    key: 'notApplicable',
    paymentInfo: null
  }
};

export const SETUP_PAYMENT: any = {
  DEFAULT: [
    PAYMENT_METHODS.STRIPE,
    PAYMENT_METHODS.CASHFREE,
    PAYMENT_METHODS.PAYTM,
    PAYMENT_METHODS.RAZORPAY,
    PAYMENT_METHODS.OPENNODE,
    PAYMENT_METHODS.PAYPAL,
    PAYMENT_METHODS.NOT_APPLICABLE
  ],
  IN: [
    PAYMENT_METHODS.STRIPE,
    PAYMENT_METHODS.CASHFREE,
    PAYMENT_METHODS.PAYTM,
    PAYMENT_METHODS.RAZORPAY,
    PAYMENT_METHODS.OPENNODE,
    PAYMENT_METHODS.PAYPAL,
    PAYMENT_METHODS.NOT_APPLICABLE
  ],
  US: [
    PAYMENT_METHODS.VEEM,
    PAYMENT_METHODS.STRIPE,
    PAYMENT_METHODS.OPENNODE,
    PAYMENT_METHODS.PAYPAL,
    PAYMENT_METHODS.NOT_APPLICABLE
  ],
  SG: [
    PAYMENT_METHODS.STRIPE,
    PAYMENT_METHODS.RAZORPAY,
    PAYMENT_METHODS.OPENNODE,
    PAYMENT_METHODS.NOT_APPLICABLE
  ],
  ID: [
    PAYMENT_METHODS.STRIPE,
    PAYMENT_METHODS.RAZORPAY,
    PAYMENT_METHODS.NOT_APPLICABLE
  ],
  PH: [
    PAYMENT_METHODS.STRIPE,
    PAYMENT_METHODS.RAZORPAY,
    PAYMENT_METHODS.NOT_APPLICABLE
  ],
  MY: [
    PAYMENT_METHODS.STRIPE,
    PAYMENT_METHODS.RAZORPAY,
    PAYMENT_METHODS.NOT_APPLICABLE
  ],
  AE: [
    PAYMENT_METHODS.STRIPE,
    PAYMENT_METHODS.RAZORPAY,
    PAYMENT_METHODS.NOT_APPLICABLE
  ],
  AU: [
    PAYMENT_METHODS.STRIPE,
    PAYMENT_METHODS.RAZORPAY,
    PAYMENT_METHODS.NOT_APPLICABLE
  ],
  CA: [
    PAYMENT_METHODS.STRIPE,
    PAYMENT_METHODS.RAZORPAY,
    PAYMENT_METHODS.NOT_APPLICABLE
  ],
  NL: [
    PAYMENT_METHODS.STRIPE,
    PAYMENT_METHODS.RAZORPAY,
    PAYMENT_METHODS.NOT_APPLICABLE
  ],
  BE: [
    PAYMENT_METHODS.STRIPE,
    PAYMENT_METHODS.RAZORPAY,
    PAYMENT_METHODS.NOT_APPLICABLE
  ],
  NZ: [
    PAYMENT_METHODS.STRIPE,
    PAYMENT_METHODS.RAZORPAY,
    PAYMENT_METHODS.NOT_APPLICABLE
  ],
  UK: [
    PAYMENT_METHODS.STRIPE,
    PAYMENT_METHODS.RAZORPAY,
    PAYMENT_METHODS.NOT_APPLICABLE
  ],
  DE: [
    PAYMENT_METHODS.STRIPE,
    PAYMENT_METHODS.RAZORPAY,
    PAYMENT_METHODS.NOT_APPLICABLE
  ],
  SA: [
    PAYMENT_METHODS.STRIPE,
    PAYMENT_METHODS.RAZORPAY,
    PAYMENT_METHODS.NOT_APPLICABLE
  ]
};

export const PpsStatus = {
  READY_TO_PICK: 'READY_TO_PICK',
  PICKING_IN_PROGRESS: 'PICKING_IN_PROGRESS',
  PICKED: 'PICKED',
  READY_TO_PACK: 'READY_TO_PACK',
  PACKING_IN_PROGRESS: 'PACKING_IN_PROGRESS',
  PACKED: 'PACKED',
  READY_TO_SHIP: 'READY_TO_SHIP',
  SHIPPING_IN_PROGRESS: 'SHIPPING_IN_PROGRESS',
  PARTIAL_SHIPPED: 'PARTIAL_SHIPPED',
  SHIPPED: 'SHIPPED'
};

export const MOBILE_DOWNLOAD_POPUP_CLOSED = 'mobile-download-popup-closed';
export const MOBILE_DOWNLOAD_BANNER_CLOSED = 'mobile-download-banner-closed';

export const LOCAL_STORAGE_KEYS = {
  BOM_EXPLOSION_SUBSTITUTE_ONLY: 'bomExplosionSubstituteOnly'
};

export const LOCATION_CLASS_ENUM = {
  LOCATION: 'LOCATION',
  CLASS: 'CLASS'
};

export const CLASS_ASSIGNMENT = {
  ROW: 'ROW',
  TRANSACTION: 'TRANSACTION'
};
export const PRINT_STATUS: any = {
  NOT_PRINTED: 'Not Printed',
  PRINT_FAILURE: 'Print Failed',
  PRINT_SUCCESS: 'Printed'
};

export const REQUEST_PRINT_STATUS: any = {
  NOT_PRINTED: 'NOT_PRINTED',
  PRINT_FAILURE: 'PRINT_FAILURE',
  PRINT_SUCCESS: 'PRINT_SUCCESS'
};

export const ROUNDING_METHOD: any = {
  ROUND_OFF: 'ROUND_OFF',
  ROUND_UP: 'ROUND_UP',
  ROUND_DOWN: 'ROUND_DOWN'
};

export const STOCK_URL: any = process.env.REACT_APP_STOCK_URL;
export const STOCK_URL_API: any = process.env.REACT_APP_BASE_URL;
export const STOCK_URL_DEV: any = 'https://stock-dev.deskera.xyz/';

export enum BOM_ALLOCATION_TYPE {
  SAME_WAREHOUSE = 'SAMEWAREHOUSE',
  INDIVIDUAL_WAREHOUSE = 'INDIVIDUAL_WAREHOUSE'
}
export const BOOKKEEPER_TYPE: any = {
  CLIENT: 'CLIENT',
  TEAM: 'TEAM'
};

export const dateFormatRegexList = [
  {
    key: 'D MMM YYYY',
    format: 'dd MMM yyyy',
    value:
      '(0[1-9]|[12][0-9]|3[01]) (Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) (19|20)\\d\\d'
  },
  {
    key: 'DD-MM-YYYY',
    format: 'dd-MM-yyyy',
    value: '(0[1-9]|[12][0-9]|3[01])[-](0[1-9]|1[012])[-](19|20)\\d\\d'
  },
  {
    key: 'YYYY-MM-DD',
    format: 'yyyy-MM-dd',
    value: '(19|20)\\d\\d[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])'
  },
  {
    key: 'MM-DD-YYYY',
    format: 'MM-dd-yyyy',
    value: '(0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])[-](19|20)\\d\\d'
  },
  {
    key: 'MM/DD/YYYY',
    format: 'MM/dd/yyyy',
    value: '(0[1-9]|1[012])[/](0[1-9]|[12][0-9]|3[01])[/](19|20)\\d\\d'
  },
  {
    key: 'DD/MM/YYYY',
    format: 'dd/MM/yyyy',
    value: '(0[1-9]|[12][0-9]|3[01])[/](0[1-9]|1[012])[/](19|20)\\d\\d'
  }
];

export const otherDateFormatRegexList = [
  {
    key: 'M/D/YY',
    format: 'M/d/yy',
    value: '^([1-9]|1[012])[/]([1-9]|[12][0-9]|3[01])[/]\\d\\d$'
  }
];

export enum STORE_FILTER_DATA_TYPES {
  DEBIT_NOTES = 'DEBIT_NOTES',
  CONTACT = 'CONTACT',
  CUSTOM_FIELD = 'CUSTOM_FIELD',
  CREDIT_NOTES = 'CREDIT_NOTES',
  EXPENSES = 'EXPENSES',
  DEPOSITS = 'DEPOSITS',
  JOURNAL = 'JOURNAL',
  OB_ACCOUNT = 'OB_ACCOUNT',
  OB_INVENTORY = 'OB_INVENTORY',
  OB_CONTACTS = 'OB_CONTACTS',
  FINANCIAL_CLOSING_YEAR = 'FINANCIAL_CLOSING_YEAR',
  STOCK_ADJUSTMENTS = 'STOCK_ADJUSTMENTS',
  JOB_CARD = 'JOB_CARD',
  JOB_WORK_OUT = 'JOB_WORK_OUT',
  QUALITY_CHECK = 'QUALITY_CHECK'
}

export enum PAGE_WATERMARK_TYPE {
  NONE = 'None',
  ORIGINAL = 'Original',
  DUPLICATE = 'Duplicate',
  TRIPLICATE = 'Triplicate',
  QUADRUPLICATE = 'Quadruplicate',
  CUSTOM = 'Custom',
  ALL = 'All'
}

export const BARCODE_SAMPLE_VALUE: any = {
  CODE_128: 'BR-00001',
  CODE_39: 'BR-0001',
  EAN_8: '0123456',
  EAN_13: '012345678999',
  UPC_A: '01234567899'
};

export enum DIRECTION {
  RIGHT = 'RIGHT',
  LEFT = 'LEFT',
  TOP = 'TOP',
  BOTTOM = 'BOTTOM'
}
export const YEAR = {
  start: 1900,
  end: 2050
};

export const GSTR2B_CATEGORY = {
  MATCHED: 'Matched',
  MISMATCHED: 'Mismatched',
  MISSING: 'Missing',
  MISSING_2B: 'Missing 2B',
  MISSING_PR: 'Missing PR'
};

export const GSTR2B_ACTION_BUTTON = {
  ACCEPT: 'Accept ',
  ACCEPT_MY_VALUE: 'Accept My Value',
  ACCEPT_SUPP_VALUE: 'Accept Supplier Value',
  NO_ACTION: 'No Action',
  UNLINK: 'Unlink',
  LINK: 'Link',
  PENDING: 'Pending',
  CREATE_BILL: 'Create Bill',
  CREATE_JE: 'Create JE',
  IGNORE: 'Ignore',
  ITC_CLAIM_STATUS: 'ITC Claim Status',
  ITC_CLAIM: 'ITC Claim',
  ITC_UNCLAIM: 'ITC Unclaim',
  RESET: 'Reset',
  SAVE: 'Save'
};

export const GSTR2B_ACTIONS = {
  MATCH: 'MATCH',
  UNLINK: 'UNLINK',
  IGNORE: 'IGNORE',
  ITC_UNCLAIM: 'ITC_UNCLAIM',
  ITC_CLAIM: 'ITC_CLAIM',
  GSTR3B_CLAIM: 'GSTR3B_CLAIM'
};

export const GSTR2B_ACTION_TYPES = {
  LINK: 'LINK',
  MATCH: 'MATCH',
  IGNORE: 'IGNORE',
  PENDING: 'PENDING',
  ACCEPT_MY_VALUE: 'ACCEPT_MY_VALUE',
  ACCEPT_SUPP_VALUE: 'ACCEPT_SUPP_VALUE',
  NO_ACTION: 'NO_ACTION',
  ITC_UNCLAIMED: 'ITC_UNCLAIMED',
  ITC_CLAIMED: 'ITC_CLAIMED',
  ACCEPT_PR: 'ACCEPT_PR',
  ACTION_NONE: 'ACTION_NONE',
  ACCEPT_2B: 'ACCEPT_2B',
  ACTION_PENDING: 'ACTION_PENDING'
};

export const GSTR2B_ITC_ACTION = {
  ACCEPT_PR: 'ACCEPT_PR',
  ACCEPT_2B: 'ACCEPT_2B'
};

export const GSTR3B_TYPE = {
  GSTR3B_UNCLAIMED: 'GSTR3B_UNCLAIMED',
  GSTR3B_CLAIMED: 'GSTR3B_CLAIMED'
};

export const GSTR2B_MATCHING_SUMMARY_DESCRIPTION = {
  MATCHED:
    'These are the documents for which we have been able to find an exact match',
  MISMATCHED:
    'These are the document in which our system has found a mismatch in one or more values',
  MISSING: 'There are the documents that are missing ',
  MISSING_2B: 'There are the documents that are missing in your GSTR-2B ',
  MISSING_PR:
    'These are the documents for which we have been able to find an exact match'
};

export const GSTR2B_START_DATE = '2022/3/1';

export const GSTR2B = 'gstr2B';

export const PR = 'pr';

export const GSTR2B_STORAGE_VALUE = {
  GSTR2B_START_DATE: 'GSTR2B_START_DATE',
  GSTR2B_END_DATE: 'GSTR2B_END_DATE',
  BILLS_START_DATE: 'BILLS_START_DATE',
  BILLS_END_DATE: 'BILLS_END_DATE'
};

export enum RECURRING_DOCUMENT_TYPE {
  RECURRING = 'RECURRING',
  NON_RECURRING = 'NON_RECURRING'
}

export enum RESERVED_STOCK_TYPE {
  RESERVED = 'RESERVED',
  UNRESERVED = 'UNRESERVED'
}

export enum BUDGET_TYPE {
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  YEARLY = 'YEARLY'
}

export const PAGE_WATERMARK_OPTIONS = [
  PAGE_WATERMARK_TYPE.ORIGINAL,
  PAGE_WATERMARK_TYPE.DUPLICATE,
  PAGE_WATERMARK_TYPE.TRIPLICATE,
  PAGE_WATERMARK_TYPE.QUADRUPLICATE,
  PAGE_WATERMARK_TYPE.CUSTOM,
  PAGE_WATERMARK_TYPE.ALL
];

export const ALL_WATERMARK_OPTIONS_INDEX = PAGE_WATERMARK_OPTIONS.length - 1;

export const TAX_PARSING_ERROR_SUB_MSG =
  'Cannot construct instance of `com.deskera.erp.common.dto.tax.TaxDto`';

export const allowedProductImageType = ['image/jpeg', 'image/png', 'image/jpg'];

export const BARCODE_FORMAT: any = {
  CODE_128: 'CODE128',
  CODE_39: 'CODE39',
  EAN_8: 'EAN8',
  EAN_13: 'EAN13',
  UPC_A: 'UPC'
};

export const VIEWPORT_SIZES = {
  EXTRA_SMALL: 'xs',
  SMALL: 'sm',
  LARGE: 'lg'
};

export const BREAKPOINTS = {
  SMALL: 560,
  LARGE: 1000,
  XLARGE: 1280
};

export const DEFAULT_MINIMUMM_SIZE_BARCODE = 22;
export const BARCODE_FONT_SIZE = 12;

export const MOBILE_APP_ACTIONS = {
  HOME: 'HOME',
  LOGOUT: 'LOGOUT',
  ATTENDANCE: 'ATTENDANCE',
  APP_SETTINGS: 'APP_SETTINGS',
  CYCLE_COUNT: 'CYCLE_COUNT',
  MRP: 'MRP',
  SWITCH_TENANT: 'SWITCH_TENANT',
  DOC_BARCODE_SCANNER: 'DOC_BARCODE_SCANNER',
  APP_BARCODE_SCANNER: 'APP_BARCODE_SCANNER',
  MOBILE_PRINT: 'MOBILE_PRINT'
};
export const TITLE_MRP = 'MRP';

export const MOBILE_APP_SUPPORTED_FEATURES = [TITLE_MRP];

export const HIDE_CHAT_BUBBLE_CLASS = 'hide-chat-bubble';

export const TIME_TYPES_CONSTANT = {
  SECONDS: 'seconds',
  MINUTES: 'minutes',
  HOURS: 'hours',
  DAYS: 'days'
};

export const TIME_TYPES = [
  {
    label: 'SECONDS',
    value: TIME_TYPES_CONSTANT.SECONDS
  },
  {
    label: 'MINUTES',
    value: TIME_TYPES_CONSTANT.MINUTES
  },
  {
    label: 'HOURS',
    value: TIME_TYPES_CONSTANT.HOURS
  },
  {
    label: 'DAYS',
    value: TIME_TYPES_CONSTANT.DAYS
  }
];

export const CHAR_CODES = {
  ENTER: 13,
  BACKSPACE: 8,
  DELETE: 46,
  LEFT_ARROW: 37,
  RIGHT_ARROW: 39,
  TOP_ARROW: 38,
  DOWN_ARROW: 40
};
export const ROUTING_APPLICATION_MODULE = {
  ERP: 'ERP',
  MRP: 'MRP'
};
export const DUMMY_IMAGE_URLS = [
  'https://res.cloudinary.com/dj3dibvfz/image/upload/v1667814840/ic_dummy_coffee_bnmv3t.png',
  'https://res.cloudinary.com/dj3dibvfz/image/upload/v1667814841/ic_dummy_table_wkywq8.png',
  'https://res.cloudinary.com/dj3dibvfz/image/upload/v1667814840/ic_dummy_shoe_eqd8tt.png'
];

export const generateArrayOfYears = (minYears: any, maxYears: any) => {
  var max = maxYears;
  var min = minYears;
  var years = [];

  for (var i = min; i <= max; i++) {
    years.push({
      name: i
    });
  }
  years.forEach((year: any, index: number) => {
    year.num = index;
  });
  return years;
};

export const WIP_CONSUMPTION_TYPES = {
  PRODUCTION: 'PRODUCTION',
  CONSUMPTION: 'CONSUMPTION',
  WASTAGE: 'WASTAGE',
  SCRAP: 'SCRAP',
  CO_PRODUCT: 'CO_PRODUCT',
  NONE: 'NONE'
};

export const SMTP_HELP_LINKS = {
  GMAIL:
    'https://support.google.com/mail/answer/7126229?authuser=4&visit_id=637811109035473969-476327719&hl=en&rd=1#zippy=%2Cstep-change-smtp-other-settings-in-your-email-client',
  OUTLOOK:
    'https://support.microsoft.com/en-us/office/pop-imap-and-smtp-settings-for-outlook-com-d088b986-291d-42b8-9564-9c414e2aa040',
  YAHOO:
    'https://in.help.yahoo.com/kb/SLN4724.html?guccounter=1&guce_referrer=aHR0cHM6Ly93d3cuZ29vZ2xlLmNvbS8&guce_referrer_sig=AQAAAGLpxTbNqVoMI3lYE12umJrOlUI12VmRGHfEwv8BIMlc9l0coGOQwHCI85aPYS1u9vBeTkosjbb0XQOYrJRoP2xBfyyTNC0HDnFOQMBkronkzgO6mCqlt8kBYE_fNhPHbPd-oulXORYHemicr9sV7SbFbfh1qgf8MRugE9kZnMfC'
};

// Tenant ids with access to pronto dashboard on prod
export const TENANT_IDS_FOR_PRONTO_DASHBOARD = [
  301078, // Pronto tenant id
  157797, // Jatinder's tenant id (IndiaTest1)
  130891, // Kamlesh's tenant id (USA)
  273526 // Vaibhav's tenant id (US- 09-09-22)
];

export const ORG_NATURE: any = {
  PARENT: 'Parent',
  SUBSIDIARY: 'Subsidiary'
};

export const ROW_RACK_BIN_CONSTANT = {
  ROW: 'ROW',
  RACK: 'RACK',
  BIN: 'BIN'
};

export const SWITCH_CURRENCY_OPTIONS = {
  BASE_CURRENCY: 'BASE_CURRENCY',
  PRIMARY_CURRENCY: 'PRIMARY_CURRENCY',
  BOTH: 'BOTH'
};
export enum PAYMENT_MILESTONE_STATUS {
  PAYMENT_DUE = 'Payment Due',
  PAYMENT_ON_TIME = 'Payment On Time',
  DELAYED = 'Delayed'
}
export const PAYMENT_MILESTONE_ENABLED_DOCS = [DOC_TYPE.BILL, DOC_TYPE.ORDER];

export const IRAS_GST_FIELDS: any = {
  ['fieldInfoList']: 'Field info List',
  ['taxRefNo']: 'Tax Ref No',
  ['filingInfo']: 'Filing Info',
  ['formType']: 'Form Type',
  ['dtPeriodStart']: 'Start Period',
  ['dtPeriodEnd']: 'End Period',
  ['supplies']: 'Supplies',
  ['purchases ']: 'Purchases ',
  ['taxes']: 'Taxes',
  ['schemes']: 'Schemes',
  ['revenue']: 'Revenue',
  ['RevChargeLVG']: 'Reverse Charge LVG',
  ['ElectronicMktplaceOprRedlvr']: 'Electronic Mkt place Opr Redlvr',
  ['SupplierOfImpLVG']: 'Supplier of Imp LVG',
  ['contactEmail']: 'Contact Email',
  ['contactNumber']: 'Contact Number',
  ['contactPerson']: 'Contact Person',
  ['declarantDesgtn']: 'Declarant Designation',
  ['access_token']: 'Access token'
};
export const recognitionMethodOptions = [
  {
    title: 'Straight-line, by even periods',
    value: 'EVEN_PERIODS'
  },
  {
    title: 'Straight-line, prorate first & last period',
    value: 'FIRST_LAST_PERIOD'
  },
  {
    title: 'Straight-line, using exact days',
    value: 'EXACT_DAYS'
  }
];
export const amountSourceOptions = [
  {
    title: 'Event-Percent based on amount',
    value: 'EVENT_PERC_AMOUNT'
  },
  {
    title: 'Event-Percent based on quantity',
    value: 'EVENT_PERC_QUANTITY'
  },
  { title: 'Event-Percent Complete', value: 'EVENT_PERC_COMPLETE' }
];
export const endDateSourceOptions = [
  { title: 'Event Date', value: 'EVENT_DATE' },
  { title: 'Revenue element end date', value: 'REVENUE_END_DATE' },
  {
    title: 'Term in months (start date + term in months)',
    value: 'TERM_IN_MONTH'
  }
];
export const startDateSourceOptions = [
  { title: 'Event Date', value: 'EVENT_DATE' },
  {
    title: 'Revenue Element Start Date',
    value: 'REVENUE_START_DATE'
  },
  {
    title: 'Arrangement Transaction Date',
    value: 'ARRANGEMENT_TRANSACTION_DATE'
  }
];
export const changeImpactOptions = [
  {
    title: 'Update Remaining Periods Only',
    value: 'UPDATE_REMAINING_PERIODS'
  },
  { title: 'None', value: 'NONE' }
];
export const reForecastMethodOptions = [
  { title: 'Next Period', value: 'NEXT_PERIOD' },
  { title: 'Remaining Period', value: 'REMAINING_PERIOD' }
];

export const FILE_EXT = [
  'DOC',
  'DOCX',
  'HTML',
  'HTM',
  'ODT',
  'PDF',
  'XLS',
  'XLSX',
  'ODS',
  'PPT',
  'PPTX',
  'TXT',
  'CSV'
];

export const IMAGE_EXT = ['PNG', 'JPG', 'JPEG', 'GIF', 'SVG'];

export const VIDEO_EXT = [
  'MP4',
  'MOV',
  'WMV',
  'AVI',
  'AVCHD',
  'FLV',
  'MKV',
  'WEBM',
  'MPEG-2',
  'F4V',
  'SWF'
];

export const FILTER_OPERATORS: object = {
  eq: '=',
  gt: '>',
  lt: '<',
  sw: 'a_',
  ew: '_a',
  c: '_a_'
};

export const AMORTIZATION_METHODS = {
  EXACT_DAYS: 'Straight-line, using exact days',
  EVEN_PERIODS: 'Straight-line, by even periods',
  FIRST_LAST_PERIOD: 'Straight-line, prorate first & last period'
};

export const AMORTIZATION_TERM_SOURCE = {
  TRANSACTION_DATE: 'Transaction Date',
  RECEIPT_DATE: 'Receipt Date'
};

export const AMORTIZATION_TYPES = {
  STANDARD: 'Standard'
  // VARIABLE: 'Variable'
};
export const ALLOCATION_TYPE_REV_REC: any = {
  EXCLUDE: 'Exclude',
  NORMAL: 'Normal'
};
export const REVENUE_PLAN_TYPE: any = {
  ACTUAL: 'Actual',
  FORECAST: 'Forecast'
};
export const AMORTIZATION_SCHEDULE_STATUS = {
  NOT_STARTED: 'Not Started',
  STARTED: 'Started',
  COMPLETED: 'Completed'
};

export const UK_DEFAULT_TAX_CODE = {
  PURCHASE_TAX: 'TX-0000001',
  SALES_TAX: 'TX-0000018'
};

export const EXPENSE_ALLOCATION_FREQUENCY = {
  TWICE_A_YEAR: 'Twice a year',
  ANNUALLY: 'Annually',
  EVERY_TWO_YEAR: 'Every Two Year',
  EVERY_THREE_YEAR: 'Every Three Year',
  ONE_TIME: 'One Time',
  END_OF_PERIOD: 'End of Period',
  START_OF_PERIOD: 'Start of Period',
  RUN_BY_BATCH: 'Run By Batch'
};

export const EXPENSE_ALLOCATION_MODE = {
  FIXED: 'Fixed Allocation',
  DYNAMIC: 'Dynamic Allocation'
};

export const REVALUATION_PERSPECTIVE = {
  STATUTORY: 'Statutory',
  PRIMARY: 'Primary'
};

export const REVALUATION_PERSPECTIVE_VALUE = {
  STATUTORY: 'STATUTORY',
  PRIMARY: 'PRIMARY'
};

export const MULTI_BOOKS_PERSPECTIVE = {
  STATUTORY: 'Statutory Books',
  PRIMARY: 'Primary Books'
};

export const IMPORT_DRAFT_STATUS = {
  DRAFT: 'DRAFT',
  PROCESSED: 'PROCESSED',
  DELETED: 'DELETED'
};

export const GENERATE_CN_DN_LIST = {
  NONE: 'None',
  GENERATE_CN: 'Generate CN',
  GENERATE_DN: 'Generate DN'
};

export const PRODUCT_EMPTY = {
  offeringType: 'GOODS',
  type: 'TRACKED',
  name: '',
  productNumberFormat: 'Manual',
  barcode: '',
  description: '',
  purchaseAccountCode: '',
  purchaseTaxCode: '',
  purchasePrice: 0,
  salesAccountCode: '',
  salesPrice: 0,
  salesTaxCode: '',
  salesReturnAccountCode: '',
  purchaseReturnAccountCode: '',
  stockUom: 2,
  advancedTracking: 'NONE',
  inventory: {
    openingQuantity: 0,
    availableQuantity: 0,
    openingValuation: 0,
    landedCostWeight: 0
  },
  whtApplicable: false,
  exciseApplicable: false,
  exciseRate: 0,
  exciseRateIndo: 0,
  exciseType: 'FLAT',
  multipleUomSchema: false,
  images: [],
  incomingQty: 0,
  outgoingQty: 0,
  manufacturingCostAccountCode: '',
  attributes: [],
  variantAttributes: {},
  isVariant: false,
  hasVariants: false,
  reorderLevelReached: false,
  variantCount: 0,
  taxPreference: true,
  cessRuleDescription: null,
  cessNonAdvol: null,
  itcAdjustment: 'NA',
  glAccountCode: 'NA',
  uqcIndia: 'NOT',
  tdsApplicableIndia: false,
  reorderVendorCode: '',
  tdsNatureOfPaymentIndia: '',
  taxable: true,
  landedCostCategory: [],
  barcodeDetails: {
    barcodeField: 'PRODUCT_ID',
    hideBarcodeLabel: false
  },
  exemptedMalaysia: true,
  productSubstitutesDetails: [],
  valuationMethod: '',
  deferredExpenseAccountCode: null,
  amortizationPeriod: null,
  amortizationTemplateCode: null
};

export const STOCK_TRANSFER_EMPTY = {
  rowIndex: 0,
  rowData: {
    productName: '',
    productVariantCode: '',
    documentUom: 0,
    documentUOMSchemaDefinition: {},
    quantity: 0,
    requiredQuantity: 0,
    availableQuantity: 0,
    stockTransferWarehouseInventoryData: '',
    type: null,
    product: {},
    rowButtons: [],
    selected: false
  },
  columnKey: 'product',
  columnData: {
    name: 'Product Name',
    index: 0,
    options: null,
    required: false,
    editable: true,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: 'product',
    id: 'product',
    datasource: [],
    key: 'product',
    lineItem: false,
    type: 'dropdown',
    width: 250,
    dropdownConfig: {
      title: 'Select Product',
      allowSearch: true,
      searchableKey: 'name',
      style: {
        minWidth: 250
      },
      className: 'shadow-m width-auto',
      searchApiConfig: {
        debounceTime: 300
      },
      data: []
    }
  }
};

export const EXPORT_ERROR = {
  EXPORT_LOCK_WARNING_MSG:
    'Your previous export request is still in-progress. Please check after sometime.',
  EXPORT_LOCK_CODE: 423
};

export const CLIENT_PORTAL_DEFAULT_THEME = '#344982';
export const DEFAULT_PAGE_NO = 1;
export const DEFAULT_PAGE_SIZE = 25;
export const QTY_ROUNDOFF_PRECISION = 6;

export const PRODUCT_TRANSACTION_TYPE_SALES = 'BOTH,SALES';
export const PRODUCT_TRANSACTION_TYPE_PURCHASE = 'BOTH,PURCHASE';
export const DEFAULT_PRICE_BOOK_CURRENCY = 'INR';
export const DEFAULT_PRICE_BOOK_COUNTRY = 'IN';

export const PRODUCT_TRANSACTION_TYPE = 'Transaction Type';

export const E_INVOICE_RESPONSE_MAP = {
  AB: 'Message acknowledgement',
  IP: 'In Process',
  UQ: 'Under query (may be repeated before moving forward)',
  CA: 'Conditionally accepted',
  RE: 'Rejected',
  AP: 'Accepted',
  PD: 'Paid',
  Accepted: 'Accepted',
  Rejected: 'Rejected'
};

export const E_INVOICE_RESPONSE_LIST_MAP = {
  AB: 'Message acknowledgement',
  IP: 'In Process',
  UQ: 'Under query (may be repeated before moving forward)',
  CA: 'Conditionally accepted',
  RE: 'Rejected',
  AP: 'Accepted',
  PD: 'Paid'
};
export const E_INVOICE_RESPONSE_COLOR_MAP = {
  AB: 'bg-chip-orange text-orange',
  IP: 'bg-chip-blue text-blue',
  UQ: 'bg-chip-blue text-blue',
  CA: 'bg-chip-orange text-orange',
  RE: 'bg-chip-red text-red',
  AP: 'bg-chip-green text-green',
  PD: 'bg-chip-green text-green',
  Accepted: 'bg-chip-green text-green',
  Rejected: 'bg-chip-red text-red'
};

export const E_INVOICE_STATUS_REASON_MAP = {
  NON: 'No Issue',
  REF: 'References incorrect',
  LEG: 'Legal information incorrect',
  REC: 'Receiver unknown',
  QUA: 'Item quality insufficient',
  DEL: 'Delivery issues',
  PRI: 'Prices incorrect',
  QTY: 'Quantity incorrect',
  ITM: 'Items incorrect',
  PAY: 'Payment terms incorrect',
  UNR: 'Not recognized',
  FIN: 'Finance incorrect',
  PPD: 'Partially Paid',
  OTH: 'Other'
};

export type EMPTY_TYPE = null | undefined;

export type TReportResponse<T> = {
  next: boolean;
  pageNumber: number;
  last: boolean;
  totalPages: number;
  pageSize: number;
  content: T[];
  first: boolean;
  totalElements: number;
  empty: boolean;
};

export const AddressDTO = {
  contactName: '',
  address1: '',
  address2: '',
  country: '',
  state: '',
  city: '',
  postalCode: '',
  preferred: true,
  placeOfSupply: '',
  destinationOfSupply: '',
  stateCode: '',
  mobileCountryCode: '',
  mobileNumber: '',
  emailId: '',
  customFields: []
};

export type DKGridRowEventT<T> = {
  rowIndex: number;
  rowData: any;
  columnData?: any;
};

export enum DEPRECIATION_METHOD_IDS {
  NO_DEPRECIATION = 'NO_DEPRECIATION',
  STRAIGHT_LINE = 'STRAIGHT_LINE',
  INSTANT_ASSET_WRITE_OFF = 'INSTANT_ASSET_WRITE_OFF'
}

export const DEPRECIATION_METHODS = [
  {
    id: DEPRECIATION_METHOD_IDS.NO_DEPRECIATION,
    value: 'No Depreciation'
  },
  {
    id: DEPRECIATION_METHOD_IDS.STRAIGHT_LINE,
    value: 'Straight Line'
  },
  {
    id: DEPRECIATION_METHOD_IDS.INSTANT_ASSET_WRITE_OFF,
    value: 'Instant Asset Write-off'
  }
];

export enum STRAIGHT_LINE_METHODS {
  FULL_MONTH = 'FULL_MONTH',
  ACTUAL_DAYS = 'ACTUAL_DATE'
}

export const DEPRECIATION_STRAIGHT_LINE_METHODS = [
  { id: STRAIGHT_LINE_METHODS.FULL_MONTH, value: 'Full Month' },
  { id: STRAIGHT_LINE_METHODS.ACTUAL_DAYS, value: 'Actual Date' }
];

export enum ITC_OPTIONS {
  ITC_INELIGIBLE_SECTION_17 = 'Ineligible - As per section 17(5)',
  ITC_INELIGIBLE_OTHERS = 'Ineligible - Others'
}

export const TAX_AMOUNT_READ_ONLY_MODULES: any = [
  IMPORT_MODULE.DEBIT_NOTE,
  IMPORT_MODULE.CREDIT_NOTE
];
export const PricingColumnKeys: any = [
  'unitPrice',
  'discount',
  'tax',
  'totalAmount',
  'amount',
  'taxAmount'
];

export const COMPONENT_LIST_SUPPORTED_DOCS = [
  DOC_TYPE.INVOICE,
  DOC_TYPE.QUOTE,
  DOC_TYPE.SALES_ORDER
];

export const COMPONENTLIST_GROUP_IDS = {
  OPERATION_ID: 'operationId',
  ADDITIONAL_CHARGE_ID: 'additionalChargeId'
};
export const BILL_RCM_ITC_ERROR = (productNames: string[]) =>
  `<div>GL Account for selected product(s) <strong>${productNames.join(
    ', '
  )}</strong> cannot be empty</div>`;

export const TOAST_TYPES = {
  DEFAULT: 'default',
  SUCCESS: 'success',
  FAILURE: 'failure',
  WARNING: 'warning'
};

export const RCM_DOCUMENTS = [
  DOC_TYPE.BILL,
  DOC_TYPE.EXPENSE_BILL,
  DOC_TYPE.Direct_S_Expense
];

export const BACKGROUND_COLOR_PALLETE = {
  BG_GRAY1: 'bg-gray1',
  BG_GRAY2: 'bg-gray2',
  BG_GRAY3: 'bg-gray3',
  BG_GRAY4: 'bg-gray4',
  BG_GRAY: 'bg-gray',
  BG_DARK_GRAY: 'bg-dark-gray',
  BG_RED: 'bg-red',
  BG_BLUE: 'bg-blue',
  BG_GREEN: 'bg-green',
  BG_YELLOW: 'bg-yellow',
  BG_ORANGE: 'bg-orange',
  BG_TRANSPARENT: 'bg-transparent',
  BG_WHATS_APP: 'bg-whats-app',
  BG_TELEGRAM: 'bg-telegram',
  BG_DESKERA: 'bg-deskera',
  BG_ERP: 'bg-erp',
  BG_CRM: 'bg-crm',
  BG_PEOPLE: 'bg-people',
  BG_AIO: 'bg-aio',
  BG_LINE: 'bg-line',
  BG_DESKERA_SECONDARY: 'bg-deskera-secondary',
  BG_ERP_SECONDARY: 'bg-erp-secondary',
  BG_CRM_SECONDARY: 'bg-crm-secondary',
  BG_PEOPLE_SECONDARY: 'bg-people-secondary',
  BG_CHIP_GRAY: 'bg-chip-gray',
  BG_CHIP_GREEN: 'bg-chip-green',
  BG_CHIP_BLUE: 'bg-chip-blue',
  BG_CHIP_ORANGE: 'bg-chip-orange',
  BG_CHIP_RED: 'bg-chip-red'
};

export const BACKGROUND_COLOR_PALLETE_DARK = {
  BG_RED: 'bg-red',
  BG_BLUE: 'bg-blue',
  BG_GREEN: 'bg-green',
  BG_YELLOW: 'bg-yellow',
  BG_ORANGE: 'bg-orange',
  BG_WHATS_APP: 'bg-whats-app',
  BG_TELEGRAM: 'bg-telegram',
  BG_DESKERA: 'bg-deskera',
  BG_ERP: 'bg-erp',
  BG_CRM: 'bg-crm',
  BG_PEOPLE: 'bg-people',
  BG_AIO: 'bg-aio'
};

export const TALK_TO_SALES_URL =
  process.env.REACT_APP_BASE_URL_WEBSITE + '/contact-us?prefilltext=';

export const GRN = {
  IS_QC_ENABLED: 'isQcEnabled',
  TOLERANCE_QC_IS_NEEDED: 'Tolerance QC Is Needed'
};

export const PAYMENT_INFO_TYPE_OPTIONS = [
  { value: PAYMENT_INFO_TYPE.PRIMARY, label: 'Primary' },
  { value: PAYMENT_INFO_TYPE.SECONDARY, label: 'Secondary' },
  { value: PAYMENT_INFO_TYPE.TERTIARY, label: 'Tertiary' }
];

export const BANK_ACCOUNT_TYPE_OPTIONS = [
  { value: BANK_ACCOUNT_TYPE.CHECKING, label: 'Checking' },
  { value: BANK_ACCOUNT_TYPE.SAVINGS, label: 'Saving' }
];

export const PAYMENT_FILE_FORMAT_OPTIONS = [
  { value: PAYMENT_FILE_FORMAT.EFT, label: 'EFT' },
  { value: PAYMENT_FILE_FORMAT.NONE, label: 'None' }
];

export const BANK_FILE_GENERATION_TYPE = {
  CHASE: 'CHASE'
};

export const BANK_FILE_GENERATION_TYPE_OPTIONS = [
  {
    label: 'Chase',
    value: BANK_FILE_GENERATION_TYPE.CHASE
  }
];

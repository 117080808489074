import { PAGE_ROUTES } from '../Managers/RouteManager';
import { APP_NAME, MRP_APP_NAME } from './Constant';

export const APPS = [
  'ERP',
  'CRM',
  'CRM_PLUS',
  'PEOPLE',
  'REPORT_BUILDER',
  'SHOP',
  'WORKFLOW_ENGINE',
  'CONSOLE',
  'MRP'
];

export enum INVITE_USER_STATUS {
  SENT = 'SENT',
  JOINED = 'JOINED',
  ACTIVE = 'active'
}

export const PERMISSION_MODULE_NAME = {
  INVOICES: 'INVOICES',
  QUOTES: 'QUOTES',
  RFQ: 'RFQ',
  CHART_OF_ACCOUNTS: 'CHART_OF_ACCOUNTS',
  JOURNAL_ENTRY: 'JOURNAL_ENTRY',
  CREDIT_NOTES: 'CREDIT_NOTES',
  DEBIT_NOTES: 'DEBIT_NOTES',
  PRODUCTS: 'PRODUCTS',
  BILL: 'BILL',
  PURCHASE_ORDER: 'PURCHASE_ORDER',
  REQUISITION: 'REQUISITION'
};

export enum USER_ACTION_TYPES {
  REC_VIEW = 'REC_VIEW',
  REC_CREATE = 'REC_CREATE',
  REC_UPDATE = 'REC_UPDATE',
  REC_DELETE = 'REC_DELETE',
  REC_IMPORT = 'REC_IMPORT',
  REC_EXPORT = 'REC_EXPORT',
  REC_EMAIL = 'REC_EMAIL',
  EDIT_TEMPLATE = 'EDIT_TEMPLATE',
  TICKET_REPLY = 'TICKET_REPLY',
  LINK_EMAIL = 'LINK_EMAIL',
  UNLINK_EMAIL = 'UNLINK_EMAIL',
  ASSIGN_ROLE = 'ASSIGN_ROLE',
  REC_PAYMENT = 'REC_PAYMENT',
  REC_PAYMENT_RECORD = 'REC_PAYMENT_RECORD',
  REC_FULFILL = 'REC_FULFILL',
  REC_FULFILL_RECORD = 'REC_FULFILL_RECORD',
  REC_SALES_RETURN = 'REC_SALES_RETURN',
  REC_OFFSET_PAYMENT = 'REC_OFFSET_PAYMENT',
  REC_RECURRING = 'REC_RECURRING',
  REC_PRINT = 'REC_PRINT',
  REC_DESIGN = 'REC_DESIGN',
  REC_ATTACHMENT = 'REC_ATTACHMENT',
  REC_GENERATE_EWAY_BILL = 'REC_GENERATE_EWAY_BILL',
  EDIT_STATUS = 'EDIT_STATUS',
  REC_CONVERT = 'REC_CONVERT',
  REC_INVOICE_NOW = 'REC_INVOICE_NOW',
  REC_EINVOICE = 'REC_EINVOICE',
  REC_COPY = 'REC_COPY',
  REC_SET_REMINDER = 'REC_SET_REMINDER'
}

export const PERMISSIONS_BY_MODULE: any = {
  INVOICE: {
    VIEW: 'erp_invoice_view',
    CREATE: 'erp_invoice_create',
    EDIT: 'erp_invoice_edit',
    DELETE: 'erp_invoice_delete',
    FULFILL: 'erp_invoice_fulfill',
    SET_RECURRING: 'erp_invoice_set_recurring',
    DESIGN: 'erp_invoice_design',
    EMAIL: 'erp_invoice_email',
    PRINT: 'erp_invoice_print',
    IMPORT: 'erp_invoice_import',
    EXPORT: 'erp_invoice_export',
    VIEW_PRICE: 'erp_invoice_view_price',
    EDIT_PRICE: 'erp_invoice_edit_price',
    EDIT_DISCOUNT: 'erp_invoice_edit_discount',
    EDIT_TAX: 'erp_invoice_edit_tax'
  },
  QUOTATION: {
    VIEW: 'erp_quote_view',
    CREATE: 'erp_quote_create',
    EDIT: 'erp_quote_edit',
    DELETE: 'erp_quote_delete',
    CONVERT: 'erp_quote_convert',
    FULFILL: 'erp_quote_fulfill',
    IMPORT: 'erp_quote_import',
    EXPORT: 'erp_quote_export',
    VIEW_PRICE: 'erp_quote_view_price',
    EDIT_PRICE: 'erp_quote_edit_price',
    EDIT_DISCOUNT: 'erp_quote_edit_discount',
    EDIT_TAX: 'erp_quote_edit_tax',
    PRINT: 'erp_quote_print',
    EMAIL: 'erp_quote_email',
    DESIGN: 'erp_quote_design',
    RECURRING: 'erp_quote_set_recurring'
  },
  SALES_ORDER: {
    VIEW: 'erp_sales_order_view',
    CREATE: 'erp_sales_order_create',
    EDIT: 'erp_sales_order_edit',
    DELETE: 'erp_sales_order_delete',
    CONVERT: 'erp_sales_order_convert',
    FULFILL: 'erp_sales_order_fulfill',
    IMPORT: 'erp_sales_order_import',
    EMAIL: 'erp_sales_order_email',
    EXPORT: 'erp_sales_order_export',
    VIEW_PRICE: 'erp_sales_order_view_price',
    PRINT: 'erp_sales_order_print'
  },
  BILL: {
    VIEW: 'erp_bill_view',
    CREATE: 'erp_bill_create',
    EDIT: 'erp_bill_edit',
    DELETE: 'erp_bill_delete',
    PRINT: 'erp_bill_print',
    EMAIL: 'erp_bill_email',
    RECEIVE_GOODS: 'erp_bill_receive_goods',
    OFFSET_PAYMENTS: 'erp_bill_offset_payments',
    DESIGN: 'erp_bill_design',
    RECURRING: 'erp_bill_set_recurring',
    VIEW_PRICE: 'erp_bill_view_price',
    EDIT_PRICE: 'erp_bill_edit_price',
    EDIT_DISCOUNT: 'erp_bill_edit_discount',
    EDIT_TAX: 'erp_bill_edit_tax',
    CREATE_PAYMENT_TXN: 'erp_bill_create_payment_txn'
  },
  PURCHASE_ORDER: {
    VIEW: 'erp_po_view',
    CREATE: 'erp_po_create',
    EDIT: 'erp_po_edit',
    DELETE: 'erp_po_delete',
    RECEIVE_GOODS: 'erp_po_receive_goods',
    PRINT: 'erp_po_print',
    EMAIL: 'erp_po_email',
    IMPORT: 'erp_po_import',
    EXPORT: 'erp_po_export',
    VIEW_PRICE: 'erp_po_view_price',
    EDIT_PRICE: 'erp_po_edit_price',
    RECURRING: 'erp_purchase_order_set_recurring',
    DESIGN: 'erp_purchase_order_design',
    EDIT_DISCOUNT: 'erp_po_edit_discount',
    EDIT_TAX: 'erp_po_edit_tax'
  },
  PURCHASE_INWARD_QUOTATION: {
    VIEW: 'erp_po_view',
    CREATE: 'erp_po_create',
    EDIT: 'erp_po_edit',
    DELETE: 'erp_po_delete',
    RECEIVE_GOODS: 'erp_po_receive_goods',
    PRINT: 'erp_po_print',
    EMAIL: 'erp_po_email',
    IMPORT: 'erp_po_import',
    EXPORT: 'erp_po_export',
    VIEW_PRICE: 'erp_po_view_price',
    EDIT_PRICE: 'erp_po_edit_price',
    RECURRING: 'erp_purchase_order_set_recurring',
    DESIGN: 'erp_purchase_order_design',
    EDIT_DISCOUNT: 'erp_po_edit_discount',
    EDIT_TAX: 'erp_po_edit_tax'
  },
  SUPPLIER_QUOTE: {
    VIEW: 'erp_po_view',
    CREATE: 'erp_po_create',
    EDIT: 'erp_po_edit',
    DELETE: 'erp_po_delete',
    RECEIVE_GOODS: 'erp_po_receive_goods',
    PRINT: 'erp_po_print',
    EMAIL: 'erp_po_email',
    IMPORT: 'erp_po_import',
    EXPORT: 'erp_po_export',
    VIEW_PRICE: 'erp_po_view_price',
    EDIT_PRICE: 'erp_po_edit_price',
    EDIT_DISCOUNT: 'erp_po_edit_discount',
    EDIT_TAX: 'erp_po_edit_tax'
  },
  ASSET_MANAGEMENT: {
    VIEW: 'acc_gr_rwd'
  },
  DEPRECIATION_SCHEDULE: {
    VIEW: 'erp_po_view',
    CREATE: 'erp_po_create',
    EDIT: 'erp_po_edit',
    DELETE: 'erp_po_delete',
    PRINT: 'erp_po_print',
    EMAIL: 'erp_po_email',
    IMPORT: 'erp_po_import',
    EXPORT: 'erp_po_export',
    VIEW_PRICE: 'erp_po_view_price',
    EDIT_PRICE: 'erp_po_edit_price'
  },
  RFQ: {
    VIEW: 'erp_quote_view',
    CREATE: 'erp_quote_create',
    EDIT: 'erp_quote_edit',
    DELETE: 'erp_quote_delete',
    CONVERT: 'erp_quote_convert',
    FULFILL: 'erp_quote_fulfill',
    IMPORT: 'erp_quote_import',
    EXPORT: 'erp_quote_export',
    VIEW_PRICE: 'erp_quote_view_price',
    EDIT_PRICE: 'erp_quote_edit_price',
    EDIT_DISCOUNT: 'erp_quote_edit_discount',
    EDIT_TAX: 'erp_quote_edit_tax'
  },
  CONTACTS: {
    VIEW: 'erp_contact_view',
    CREATE: 'erp_contact_create',
    EDIT: 'erp_contact_edit',
    DELETE: 'erp_contact_delete',
    IMPORT: 'erp_contact_import',
    EXPORT: 'erp_contact_export',
  },
  PRODUCTS: {
    VIEW: 'erp_product_view',
    CREATE: 'erp_product_create',
    EDIT: 'erp_product_edit',
    DELETE: 'erp_product_delete',
    IMPORT: 'erp_product_import',
    EXPORT: 'erp_product_export'
  },
  PURCHASE_SELL_RETURN: {
    VIEW: 'erp_pur_sell_view',
    CREATE: 'erp_pur_sell_create',
    EDIT: 'erp_pur_sell_edit',
    DELETE: 'erp_pur_sell_delete',
    CONVERT: 'erp_pur_sell_convert',
    IMPORT: 'erp_pur_sell_import',
    EXPORT: 'erp_pur_sell_export'
  },
  REPORTS: {
    VIEW: 'erp_rpt_view',
    EXPORT: 'erp_rpt_export',
    CREATE: 'erp_rpt_create_smart',
    EDIT: 'erp_rpt_edit_smart',
    DELETE: 'erp_rpt_delete_smart',
    VIEW_SMART: 'erp_rpt_view_smart',
    //Amortization
    AMOR_FORECAST: 'erp_amor_forecast__report_view',
    DEF_EXP_WATERFALL: 'erp_def_exp_waterfall_report_view',
    DEF_EXP_ROLL_FORWARD_SUM:'erp_def_exp_rollforward_sum_report_view',

    //Business reports
    PROFIT_AND_LOSS:'erp_profit_n_loss_report_view',
    CASH_FLOW_STATEMENT:'erp_cash_flow_stmt_report_view',
    BANK_RECONCILIATION:'erp_bank_recon_report_view',
    BALANCE_SHEET:'erp_balance_sheet_report_view',
    STATEMENT_OF_ACCOUNTS_VENDORS:'erp_stmt_acc_vendors_report_view',
    STATEMENT_OF_ACCOUNTS:'erp_stmt_of_acc_report_view',
    TRIAL_BALANCE:'erp_trial_balance_report_view',
    DOCUMENTS_EMAILED:'erp_docs_emailed_report_view',
    BANK_BOOK:'erp_bank_book_report_view',
    GENERAL_LEDGER:'erp_general_ledger_report_view',

    //Sales reports
    AGED_RECEIVABLES:'erp_aged_receivables_report_view',
    CREATE_REVENUE_RECOGNITION_JE:'erp_crt_rev_rec_je_report_view',
    FULFILLMENT:'erp_fulfillment_report_view',
    SALES_BY_CONTACT:'erp_sales_by_contact_report_view',
    SALES_BY_PRODUCT:'erp_sales_by_product_report_view',
    INVOICES:'erp_invoices_report_view',
    QUOTATION:'erp_quotation_report_view',
    GOODS_RECEIPT:'erp_goods_receipt_report_view',
    RECEIPT_LISTt:'erp_receipt_list_report_view',
    CREDIT_NOTES:'erp_credit_notes_report_view',
    E_WAY_BILL:'erp_e-way_bill_report_view',
    OUTSTANDING_DELIVERY_ORDER:'erp_out_delivery_ord_report_view',
    RATE_ANALYSIS:'erp_rate_analysis_report_view',
    SALES_ORDER_DETAILS: 'erp_sales_order_details_report_view',
    STOCK_STATUS_REPORT: 'erp_stock_status_report_view',

    //Purchase reports
    AGED_PAYABLES:'erp_aged_payables_report_view',
    OUTSTANDING_PURCHASES_GOOD_RECEIVED:'erp_out_purch_gd_rec_report_view',
    PURCHASE_BY_CONTACT:'erp_purch_by_contct_report_view',
    BILL_LIST:'erp_bill_list_report_view',
    PO_LIST:'erp_po_list_report_view',
    PURCHASE_BY_PRODUCT:'erp_purch_by_product_report_view',
    PAYMENT_LIST:'erp_payment_list_report_view',
    DEBIT_NOTES:'erp_debit_notes_report_view',
    ITEM_LANDED_COST:'erp_item_landed_cost_report_view',
    PAYMENT_MILESTONE_TRACKER:'erp_paymilest_track_report_view',
    GOODS_RECEIVED:'erp_goods_received_report_view',
    PURCHASE_ORDER_DETAIL:'erp_purch_ord_detail_report_view',

    //Tax reports
    GSTR_ONE:'erp_gstr_one_report_view',
    SST02:'erp_sst02_report_view',
    GSTR_2B_RECONCILIATION:'erp_gstr_2b_recon_report_view',
    VAT_RETURN_UK:'erp_vat_return_uk_report_view',
    GSTR_3B_REPORT:'erp_gstr_3b_report_report_view',
    TDS_DETAILS:'erp_tds_details_report_view',
    TDS_NOT_DEDUCTED:'erp_tds_not_deducted_report_view',
    TDS_CUSTOMER:'erp_tds_customer_report_view',
    FAILED_E_INVOICE_LOG:'erp_failed_e_inv_log_report_view',
    GSTR1_RETURN_SUMMARY:'erp_gstr1_ret_sum_report_view',
    SALES_TAX_LIABILITY:'erp_sales_tax_liab_report_view',
    SALES_TAX:'erp_sales_tax_report_view',
    GST_REPORT_LOG_SG:'erp_gst_report_log_sg_report_view',
    IRAS_AUDIT_FILE:'erp_iras_audit_file_report_view',
    SALES_TAX_RETURN_MY:'erp_sales_tax_return_my_report_view',
    SERVICE_TAX_RETURN_MY:'erp_service_tax_return_my_report_view',

    //Inventory reports
    STOCK_AVAILABILITY_BY_WAREHOUSE:'erp_stck_avl_by_whse_report_view',
    RESERVED_STOCK:'erp_reserved_stock_report_view',
    BATCH_STATUS:'erp_batch_status_report_view',
    STOCK_LEDGER:'erp_stock_ledger_report_view',
    STOCK_AGEING:'erp_stock_ageing_report_view',
    SALES_RETURN:'erp_sales_return_report_view',
    PURCHASE_RETURN:'erp_purchase_return_report_view',
    STOCK_MOVEMENT:'erp_stock_movement_report_view',
    BATCH_SERIAL_LEDGER:'erp_batch_serial_lgr_report_view',
    BATCHWISE_STOCK_TRACKING:'erp_batch_w_stk_track_report_view',
    STOCK_VALUATION:'erp_stock_valuation_report_view',
    REV_REC_WATERFALL:'erp_rev_rec_waterfall_report_view',
    DEFERRED_REVENUE_BY_ITEM:'erp_def_rev_by_item_report_view',
    DEFERRED_REVENUE_BY_CUSTOMER:'erp_def_rev_by_cust_report_view',
    BOM:'erp_bom_report_view',
    REORDER_LEVEL:'erp_reorder_level_report_view',

    //Advanced Financial report
    RECEIVABLE:'erp_receivable_report_view',
    DAY_BOOK:'erp_day_book_report_view',
    RECEIPT_SUMMARY:'erp_receipt_summary_report_view',
    CASHFLOW:'erp_cashflow_report_view',
    DETAILED_SALES:'erp_detailed_sales_report_view',
    VENDOR_STATEMENT_OF_ACCOUNT:'erp_vdr_stmt_of_acc_report_view',
    DETAILED_PURCHASE:'erp_detailed_purch_report_view',
    CUSTOMER_STATEMENT_OF_ACCOUNT:'erp_cust_stmt_of_acc_report_view',
    STOCK_SUMMARY:'erp_stock_summary_report_view',
    SALES_REGISTER_SUMMARY:'erp_sales_regi_sum_report_view',
    VALUATION_BY_WAREHOUSE:'erp_valuation_by_whse_report_view',

    //Additional report
    PURCHASE_REPORT:'erp_purchase_report_view',
    SALES_REPORT:'erp_sales_report_view',

  },
  WAREHOUSE: {
    CREATE: 'erp_warehouse_create',
    EDIT: 'erp_warehouse_edit',
    DELETE: 'erp_warehouse_delete'
  },
  COA: {
    VIEW: 'erp_coa_view',
    CREATE: 'erp_coa_create',
    EDIT: 'erp_coa_edit',
    DELETE: 'erp_coa_delete',
    CREATE_SUBTYPE: 'erp_coa_create_subtype',
    IMPORT: 'erp_coa_import',
    EXPORT: 'erp_coa_export'
  },
  BUDGETING: {
    CREATE: 'erp_budget_create',
    EDIT: 'erp_budget_edit',
    DELETE: 'erp_budget_delete',
    ALLOCATE: 'erp_budget_allocate',
    VIEW: 'erp_budget_view'
  },
  CREDIT_NOTES: {
    VIEW: 'erp_cndn_view',
    CREATE: 'erp_cndn_create',
    EDIT: 'erp_cndn_edit',
    DELETE: 'erp_cndn_delete',
    IMPORT: 'erp_cndn_import',
    EXPORT: 'erp_cndn_export',
    APPLY_INV_BILL: 'erp_cndn_apply_inv_bill'
  },
  DEBIT_NOTES: {
    VIEW: 'erp_cndn_view',
    CREATE: 'erp_cndn_create',
    EDIT: 'erp_cndn_edit',
    DELETE: 'erp_cndn_delete',
    IMPORT: 'erp_cndn_import',
    EXPORT: 'erp_cndn_export',
    APPLY_INV_BILL: 'erp_cndn_apply_inv_bill'
  },
  JOURNAL_ENTRY: {
    VIEW: 'erp_je_view',
    CREATE: 'erp_je_create',
    EDIT: 'erp_je_edit',
    DELETE: 'erp_je_delete',
    IMPORT: 'erp_je_import',
    EXPORT: 'erp_je_export'
  },
  CUSTOM_FIELDS: {
    CREATE: 'erp_cf_create',
    EDIT: 'erp_cf_edit',
    DELETE: 'erp_cf_delete'
  },
  BANK: {
    RECONCILE: 'erp_bank_reconcile',
    BANK_CONNECT: 'erp_bank_connect',
    IMPORT_STATEMENT: 'erp_bank_import_stmnt',
    DELETE: 'erp_bank_delete',
    EXPORT_BANK_TRANSACTION: 'erp_bank_export_bank_txn'
  },
  EXPENSE: {
    VIEW: 'erp_expense_view',
    CREATE: 'erp_expense_create',
    EDIT: 'erp_expense_edit',
    DELETE: 'erp_expense_delete',
    IMPORT: 'erp_expense_import',
    EXPORT: 'erp_expense_export',
    ATTACH_FILE: 'erp_expense_attachfile'
  },
  DEPOSIT: {
    VIEW: 'erp_deposit_view',
    CREATE: 'erp_deposit_create',
    EDIT: 'erp_deposit_edit',
    DELETE: 'erp_deposit_delete',
    IMPORT: 'erp_deposit_import',
    EXPORT: 'erp_deposit_export',
    ATTACH_FILE: 'erp_deposit_attachfile'
  },
  STOCK_ADJUSTMENT: {
    VIEW: 'erp_stock_adjustment_view',
    CREATE: 'erp_stock_adjustment_create',
    EDIT: 'erp_stock_adjustment_edit',
    PRINT: 'erp_stock_adjustment_print',
    DELETE: 'erp_stock_adjustment_delete',
    IMPORT: 'erp_stock_adjustment_import',
    EXPORT: 'erp_stock_adjustment_export'
  },
  STOCK_TRANSFER: {
    VIEW: 'erp_stock_transfer_view',
    CREATE: 'erp_stock_transfer_create',
    EDIT: 'erp_stock_transfer_edit',
    PRINT: 'erp_stock_transfer_print',
    DELETE: 'erp_stock_transfer_delete',
    IMPORT: 'erp_stock_transfer_import',
    EXPORT: 'erp_stock_transfer_export'
  },
  STOCK_REQUEST: {
    VIEW: 'mrp_stock_request_view',
    CREATE: 'mrp_stock_request_create',
    EDIT: 'mrp_stock_request_edit',
    DELETE: 'mrp_stock_request_delete',
    PRINT: 'mrp_stock_request_print',
    IMPORT: 'mrp_stock_request_import',
    EXPORT: 'mrp_stock_request_export'
  },
  STOCK_ISSUE: {
    VIEW: 'mrp_stock_issue_view',
    CREATE: 'mrp_stock_issue_create',
    EDIT: 'mrp_stock_issue_edit',
    DELETE: 'mrp_stock_issue_delete'
  },
  QUALITY_CHECK: {
    VIEW: 'mrp_quality_check_view',
    EDIT: 'mrp_quality_check_edit',
    PRINT: 'mrp_quality_check_print'
  },
  JOB_CARD: {
    VIEW: 'mrp_job_cards_view',
    CREATE: 'mrp_job_cards_create',
    EDIT: 'mrp_job_cards_edit',
    DELETE: 'mrp_job_cards_delete',
    VIEW_PRICE: 'mrp_job_cards_view_price',
    IMPORT: 'mrp_job_cards_import',
    EXPORT: 'mrp_job_cards_export'
  },
  OPERATORS: {
    VIEW: 'mrp_operators_view',
    CREATE: 'mrp_operators_create',
    EDIT: 'mrp_operators_edit',
    DELETE: 'mrp_operators_delete',
    VIEW_PRICE: 'mrp_operators_view_price',
    IMPORT: 'mrp_operators_import',
    EXPORT: 'mrp_operators_export'
  },
  OPERATIONS: {
    VIEW: 'mrp_operations_view',
    CREATE: 'mrp_operations_create',
    EDIT: 'mrp_operations_edit',
    DELETE: 'mrp_operations_delete',
    VIEW_PRICE: 'mrp_operations_view_price',
    IMPORT: 'mrp_operations_import',
    EXPORT: 'mrp_operations_export'
  },
  WORKSTATIONS: {
    VIEW: 'mrp_workstation_view',
    CREATE: 'mrp_workstation_create',
    EDIT: 'mrp_workstation_edit',
    DELETE: 'mrp_workstation_delete',
    VIEW_PRICE: 'mrp_workstation_view_price',
    IMPORT: 'mrp_workstation_import',
    EXPORT: 'mrp_workstation_export'
  },
  FORECASTING: {
    VIEW: 'mrp_forecasting_view',
    CREATE: 'mrp_forecasting_create',
    EDIT: 'mrp_forecasting_edit',
    DELETE: 'mrp_forecasting_delete',
    VIEW_PRICE: 'mrp_forecasting_view_price',
    IMPORT: 'mrp_forecasting_import',
    EXPORT: 'mrp_forecasting_export'
  },
  WORK_ORDER: {
    VIEW: 'mrp_work_order_view',
    CREATE: 'mrp_work_order_create',
    EDIT: 'mrp_work_order_edit',
    DELETE: 'mrp_work_order_delete',
    VIEW_PRICE: 'mrp_work_order_view_price',
    PRINT: 'mrp_work_order_print',
    IMPORT: 'mrp_work_order_import',
    EXPORT: 'mrp_work_order_export',
    COPY: 'mrp_work_order_copy'
  },
  JOB_WORKOUTS: {
    VIEW: 'mrp_job_workouts_view',
    CREATE: 'mrp_job_workouts_create',
    EDIT: 'mrp_job_workouts_edit',
    DELETE: 'mrp_job_workouts_delete',
    VIEW_PRICE: 'mrp_job_workouts_view_price',
    PRINT: 'mrp_job_workouts_print',
    IMPORT: 'mrp_job_workouts_import',
    EXPORT: 'mrp_job_workouts_export'
  },
  SCHEDULING: {
    VIEW: 'mrp_scheduling_view',
    CREATE: 'mrp_scheduling_create',
    EDIT: 'mrp_scheduling_edit',
    DELETE: 'mrp_scheduling_delete',
    VIEW_PRICE: 'mrp_scheduling_view_price',
    PRINT: 'mrp_scheduling_print',
    IMPORT: 'mrp_scheduling_import',
    EXPORT: 'mrp_scheduling_export'
  },
  REQUISITION: {
    VIEW: 'erp_requisition_view',
    CREATE: 'erp_requisition_create',
    EDIT: 'erp_requisition_edit',
    DELETE: 'erp_requisition_delete',
    PRINT: 'erp_requisition_print',
    IMPORT: 'erp_requisition_import',
    EXPORT: 'erp_requisition_export'
  },
  MRP_REPORTS: {
    VIEW: 'mrp_reports_view',
    CREATE: 'mrp_reports_create',
    EDIT: 'mrp_reports_edit',
    DELETE: 'mrp_reports_delete',
    VIEW_SMART: 'mrp_reports_view_smart',
    EXPORT: 'mrp_reports_export',
    // Reports
    JOB_CARD_TIME_TRACKING:'mrp_job_crd_time_trac_report_view',
    SKU_WISE_ORDER_TRACKING:'mrp_sku_wise_ord_trac_report_view',
    FORECAST_DRIVEN_PURCHASE_ORDER:'mrp_fc_drv_purch_ord_report_view',
    FG_COMPONENT_TRACKER:'mrp_fg_comp_tracker_report_view',
    PRODUCT_WISE_QC:'mrp_product_wise_qc_report_view',
    DAILY_CONSUMPTION_DETAILED:'mrp_daily_cons_dtl_report_view',
    DAILY_CONSUMPTION_SUMMARY:'mrp_daily_cons_sum_report_view',
    VENDOR_WISE_QC:'mrp_vendor_wise_qc_report_view',
    RESTOCKING_LEVELS:'mrp_restock_levels_report_view',
    YIELD_SUMMARY:'mrp_yield_summary_report_view',
    YIELD_SUMMARY_DETAILED:'mrp_yield_sum_dtl_report_view',
    WORK_ORDER_PLANNING:'mrp_work_ord_plan_report_view',
    PRODUCTION_SUMMARY:'mrp_prod_summary_report_view',
    PRODUCTION_COSTING:'mrp_prod_costing_report_view',
    ORDER_LEVELS:'mrp_order_levels_report_view',
    QC_DETAILED:'mrp_qc_detailed_report_view',
    JOB_WORKOUT:'mrp_job_workout_report_view',
    PRODUCTION:'mrp_production_report_view',
    QC_SUMMARY:'mrp_qc_summary_report_view',
    WORK_ORDER_WAREHOUSE_SHORTFALL_DETAILED:'mrp_work_ord_ware_short_dtl_report_view',
    WORK_ORDER_WAREHOUSE_SHORTFALL_SUMMARY:'mrp_work_ord_ware_short_sum_report_view',
    BIN_ALLOCATION:'mrp_bin_allocation_report_view',
    WORK_ORDER_WISE_OPERATION_DETAILS:'mrp_work_ord_wise_opr_dtl_report_view',
  },
  MATERIAL_MASTER: {
    VIEW: 'mrp_material_master_view',
    CREATE: 'mrp_material_master_create',
    EDIT: 'mrp_material_master_edit',
    DELETE: 'mrp_material_master_delete',
    IMPORT: 'mrp_material_master_import',
    EXPORT: 'mrp_material_master_export',
    BULK_REPLACE_DELETE: 'mrp_material_master_bom_bulk_update'
  },
  DEFAULT: {
    //only for UI (temporary purpose)
    IS_DEFAULT: 'erp_is_default'
  },
  GOODS_RECEIPT_MASTER: {
    VIEW: 'goods_receipt_master_view',
    DELETE: 'goods_receipt_master_delete',
    IMPORT: 'goods_receipt_master_import'
  },
  PPS: {
    READ: 'erp_pps_read',
    MANAGE: 'erp_pps_manage'
  },
  LOGS: {
    AUDIT_LOGS: 'erp_logs_audit_logs',
    IMPORT_LOGS: 'erp_logs_import_logs',
    AUTOMATION_LOGS: 'erp_logs_automation_logs',
    TRANSACTION_LOGS: 'erp_logs_transaction_logs'
  },
  AUTOMATION: {
    MANAGE: 'erp_automation_manage'
  },
  PRICE_LIST: {
    VIEW: 'erp_price_list_view',
    CREATE: 'erp_price_list_create',
    EDIT: 'erp_price_list_edit',
    DELETE: 'erp_price_list_delete',
    IMPORT: 'erp_price_list_import',
    EXPORT: 'erp_price_list_export'
  },
  SETTINGS: {
    AGENCIES: 'erp_settings_agencies',
    ACTIVE_DATE_RANGE_SETTINGS: 'erp_settings_active_date_range',
    ADVANCED: 'erp_settings_advanced',
    BACK_DATED_RESTRICTIONS_SETTINGS: 'erp_settings_back_date_restrictions',
    BARCODE_SETTINGS: 'erp_settings_barcode',
    CLASSES: 'erp_settings_classes',
    CLIENT_PORTAL: 'erp_settings_client_portal',
    CREDIT_LIMIT: 'erp_settings_credit_limit',
    CURRENCY: 'erp_settings_currency',
    CUSTOM_FIELD: 'erp_settings_custom_field',
    EMAIL: 'erp_settings_email',
    ITEM_LANDED_COST: 'erp_settings_item_landed_cost',
    LOCATIONS: 'erp_settings_locations',
    ORGANISATION: 'erp_settings_org',
    PAYMENT_TERMS: 'erp_settings_payment_terms',
    PRINT_TEMPLATE: 'erp_settings_print_template',
    QC_FLOW: 'erp_settings_qc_flow',
    QC_TEMPLATE: 'erp_settings_qc_template',
    SEQ_NUMBER: 'erp_settings_seq_number',
    TAX: 'erp_settings_tax',
    TCS_SETUP: 'erp_settings_tcs_setup',
    TDS_SETUP: 'erp_settings_tds_setup',
    UOM: 'erp_settings_uom',
    UOM_SCHEMA: 'erp_settings_uom_schema',
    USER_PERMISSION: 'erp_settings_user_perm',
    MANAGE_SALES_PERSON_MASTER: 'erp_settings_manage_sales_person'
  }
};

export const SYSTEM_DEFINED_ROLE_SHORT_CODES = {
  ACCOUNTING: 'accounting',
  ADMIN: 'admin',
  BUY_SELL: 'business',
  BUY_SELL_GUEST: 'nonpaid_business',
  AUDITOR: 'auditor',
  MACHINE_OPERATORS: 'machine_operators',
  WAREHOUSE_MANAGER: 'warehouse_manager',
  PROCUREMENT_MANAGER: 'procurement_manager',
  MRP_ADMIN: 'mrp_admin'
};

export const GROUP_NAMES_ARR = [
  {
    id: 'quote',
    hideFromUI: false,
    sequence: 0,
    appName: APP_NAME,
    isAvailableForAdminSuperset: true
  },
  {
    id: 'invoice',
    hideFromUI: false,
    sequence: 1,
    appName: APP_NAME,
    isAvailableForAdminSuperset: true
  },
  {
    id: 'purchaseorder',
    hideFromUI: false,
    sequence: 2,
    appName: APP_NAME,
    isAvailableForAdminSuperset: true
  },
  {
    id: 'rfq',
    hideFromUI: false,
    sequence: 0,
    appName: APP_NAME,
    isAvailableForAdminSuperset: true
  },
  {
    id: 'bill',
    hideFromUI: false,
    sequence: 3,
    appName: APP_NAME,
    isAvailableForAdminSuperset: true
  },
  {
    id: 'contact',
    hideFromUI: false,
    sequence: 4,
    appName: APP_NAME,
    isAvailableForAdminSuperset: true
  },
  {
    id: 'product',
    hideFromUI: false,
    sequence: 5,
    appName: APP_NAME,
    isAvailableForAdminSuperset: true
  },
  {
    id: 'bank',
    hideFromUI: false,
    sequence: 6,
    appName: APP_NAME,
    isAvailableForAdminSuperset: true
  },
  {
    id: 'chartofaccounts',
    hideFromUI: false,
    sequence: 7,
    appName: APP_NAME,
    isAvailableForAdminSuperset: true
  },
  {
    id: 'journalentry',
    hideFromUI: false,
    sequence: 8,
    appName: APP_NAME,
    isAvailableForAdminSuperset: true
  },
  {
    id: 'creditdebitnote',
    hideFromUI: false,
    sequence: 9,
    appName: APP_NAME,
    isAvailableForAdminSuperset: true
  },
  {
    id: 'expense',
    hideFromUI: false,
    sequence: 10,
    appName: APP_NAME,
    isAvailableForAdminSuperset: true
  },
  {
    id: 'deposit',
    hideFromUI: false,
    sequence: 11,
    appName: APP_NAME,
    isAvailableForAdminSuperset: true
  },
  {
    id: 'pursellreturn',
    hideFromUI: false,
    sequence: 12,
    appName: APP_NAME,
    isAvailableForAdminSuperset: true
  },
  {
    id: 'warehouse',
    hideFromUI: false,
    sequence: 13,
    appName: APP_NAME,
    isAvailableForAdminSuperset: true
  },
  {
    id: 'reports',
    hideFromUI: false,
    sequence: 14,
    appName: APP_NAME,
    isAvailableForAdminSuperset: true
  },
  {
    id: 'customfield',
    hideFromUI: false,
    sequence: 16,
    appName: APP_NAME,
    isAvailableForAdminSuperset: true
  },
  {
    id: 'erp_sell',
    hideFromUI: true,
    sequence: 17,
    appName: APP_NAME,
    isAvailableForAdminSuperset: true
  },
  {
    id: 'erp_buy',
    hideFromUI: true,
    sequence: 18,
    appName: APP_NAME,
    isAvailableForAdminSuperset: true
  },
  // {
  //   id: 'erp_product',
  //   hideFromUI: true,
  //   sequence: 19,
  //   appName: APP_NAME,
  //   isAvailableForAdminSuperset: true
  // },
  {
    id: 'erp_contact',
    hideFromUI: true,
    sequence: 20,
    appName: APP_NAME,
    isAvailableForAdminSuperset: true
  },
  // {
  //   id: 'erp_pps',
  //   hideFromUI: true,
  //   sequence: 21,
  //   appName: APP_NAME,
  //   isAvailableForAdminSuperset: true
  // },
  // {
  //   id: 'erp_inventory',
  //   hideFromUI: true,
  //   sequence: 22,
  //   appName: APP_NAME,
  //   isAvailableForAdminSuperset: true
  // },
  {
    id: 'erp_reports',
    hideFromUI: true,
    sequence: 23,
    appName: APP_NAME,
    isAvailableForAdminSuperset: true
  },
  {
    id: 'erp_bank',
    hideFromUI: true,
    sequence: 24,
    appName: APP_NAME,
    isAvailableForAdminSuperset: true
  },
  {
    id: 'erp_accounting',
    hideFromUI: true,
    sequence: 25,
    appName: APP_NAME,
    isAvailableForAdminSuperset: true
  },
  // {
  //   id: 'erp_account',
  //   hideFromUI: true,
  //   sequence: 26,
  //   appName: APP_NAME,
  //   isAvailableForAdminSuperset: true
  // },
  {
    id: 'erp_core',
    hideFromUI: true,
    sequence: 27,
    appName: APP_NAME,
    isAvailableForAdminSuperset: true
  },
  {
    id: 'erp_org_mgmt',
    hideFromUI: true,
    sequence: 28,
    appName: APP_NAME,
    isAvailableForAdminSuperset: false
  },
  {
    id: 'erp_user_mgmt',
    hideFromUI: true,
    sequence: 29,
    appName: APP_NAME,
    isAvailableForAdminSuperset: true
  },
  // {
  //   id: 'erp_assets',
  //   hideFromUI: true,
  //   sequence: 30,
  //   appName: APP_NAME,
  //   isAvailableForAdminSuperset: true
  // },
  {
    id: 'erp_export',
    hideFromUI: true,
    sequence: 31,
    appName: APP_NAME,
    isAvailableForAdminSuperset: true
  },
  {
    id: 'erp_import',
    hideFromUI: true,
    sequence: 32,
    appName: APP_NAME,
    isAvailableForAdminSuperset: true
  },
  {
    id: 'erp_tax',
    hideFromUI: true,
    sequence: 33,
    appName: APP_NAME,
    isAvailableForAdminSuperset: true
  },
  {
    id: 'requisition',
    hideFromUI: false,
    sequence: 34,
    appName: APP_NAME,
    isAvailableForAdminSuperset: true
  },
  {
    id: 'supplierquote',
    hideFromUI: false,
    sequence: 35,
    appName: APP_NAME,
    isAvailableForAdminSuperset: true
  },
  {
    id: 'budgeting',
    hideFromUI: false,
    sequence: 36,
    appName: APP_NAME,
    isAvailableForAdminSuperset: true
  },
  {
    id: 'salesorder',
    hideFromUI: false,
    sequence: 37,
    appName: APP_NAME,
    isAvailableForAdminSuperset: true
  },
  {
    id: 'stockadjustment',
    hideFromUI: false,
    sequence: 38,
    appName: APP_NAME,
    isAvailableForAdminSuperset: true
  },
  {
    id: 'forecasting',
    hideFromUI: false,
    sequence: 40,
    appName: MRP_APP_NAME,
    isAvailableForAdminSuperset: true
  },
  {
    id: 'qualitycheck',
    hideFromUI: false,
    sequence: 41,
    appName: MRP_APP_NAME,
    isAvailableForAdminSuperset: true
  },
  {
    id: 'workstation',
    hideFromUI: false,
    sequence: 42,
    appName: MRP_APP_NAME,
    isAvailableForAdminSuperset: true
  },
  {
    id: 'jobcards',
    hideFromUI: false,
    sequence: 43,
    appName: MRP_APP_NAME,
    isAvailableForAdminSuperset: true
  },
  {
    id: 'jobworkouts',
    hideFromUI: false,
    sequence: 45,
    appName: MRP_APP_NAME,
    isAvailableForAdminSuperset: true
  },
  {
    id: 'stockrequest',
    hideFromUI: false,
    sequence: 46,
    appName: MRP_APP_NAME,
    isAvailableForAdminSuperset: true
  },
  {
    id: 'operations',
    hideFromUI: false,
    sequence: 47,
    appName: MRP_APP_NAME,
    isAvailableForAdminSuperset: true
  },
  {
    id: 'mrpreports',
    hideFromUI: false,
    sequence: 48,
    appName: MRP_APP_NAME,
    isAvailableForAdminSuperset: true
  },
  {
    id: 'operators',
    hideFromUI: false,
    sequence: 49,
    appName: MRP_APP_NAME,
    isAvailableForAdminSuperset: true
  },
  {
    id: 'scheduling',
    hideFromUI: false,
    sequence: 50,
    appName: MRP_APP_NAME,
    isAvailableForAdminSuperset: true
  },
  {
    id: 'stockissue',
    hideFromUI: false,
    sequence: 51,
    appName: MRP_APP_NAME,
    isAvailableForAdminSuperset: true
  },
  {
    id: 'workorder',
    hideFromUI: false,
    sequence: 52,
    appName: MRP_APP_NAME,
    isAvailableForAdminSuperset: true
  },
  {
    id: 'stocktransfer',
    hideFromUI: false,
    sequence: 53,
    appName: APP_NAME,
    isAvailableForAdminSuperset: true
  },
  {
    id: 'materialmaster',
    hideFromUI: false,
    sequence: 53,
    appName: MRP_APP_NAME,
    isAvailableForAdminSuperset: true
  },
  {
    id: 'mrp_buy',
    hideFromUI: true,
    sequence: 54,
    appName: MRP_APP_NAME,
    isAvailableForAdminSuperset: true
  },
  {
    id: 'pps',
    hideFromUI: false,
    sequence: 55,
    appName: APP_NAME,
    isAvailableForAdminSuperset: true
  },
  {
    id: 'settings',
    hideFromUI: false,
    sequence: 56,
    appName: APP_NAME,
    isAvailableForAdminSuperset: false
  },
  {
    id: 'automation',
    hideFromUI: false,
    sequence: 57,
    appName: APP_NAME,
    isAvailableForAdminSuperset: false
  },
  {
    id: 'pricelist',
    hideFromUI: false,
    sequence: 58,
    appName: APP_NAME,
    isAvailableForAdminSuperset: true
  },
  {
    id: 'logs',
    hideFromUI: false,
    sequence: 59,
    appName: APP_NAME,
    isAvailableForAdminSuperset: true
  },
  {
    id: 'goods_Receipt',
    hideFromUI: false,
    sequence: 60,
    appName: APP_NAME,
    isAvailableForAdminSuperset: true
  },
  {
    id: 'materialmaster',
    hideFromUI: false,
    sequence: 53,
    isAvailableForAdminSuperset: true
  },
  {
    id: 'goods_Receipt',
    hideFromUI: false,
    sequence: 55,
    appName: APP_NAME,
    isAvailableForAdminSuperset: true
  }
];

export const FEATURE_PERMISSIONS = {
  MULTI_COMPANY_ADV: 'MULTI_COMPANY_ADV',
  FIXED_ASSET_ADV: 'FIXED_ASSET_ADV',
  REVENUE_RECOGNITION_ADV: 'REVENUE_RECOGNITION_ADV',
  AMORTIZATION: 'AMORTIZATION_ADV',
  EXPENSE_ALLOCATION: 'ALLOCATION_ADV',
  LABEL_PRINT: 'LABEL_PRINT_ADV'
};
/**
 * * These are inner submodule permission code that needs to be hidden in UI,
 * * they might not be present or not needed in system
 * *this code can be found in metadata API
 */
export const MODULE_CODES_TO_HIDE = ['m_mrp_stock_issue_edit'];
export const MRP_ROLES_TO_FILTER = ['admin', 'user'];

export const ROUTE_TO_PERMISSION_MODULE_MAPPING = {
  [PAGE_ROUTES.DASHBOARD]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.PRODUCTS]: PERMISSIONS_BY_MODULE.PRODUCTS.VIEW,
  [PAGE_ROUTES.CONTACTS]: PERMISSIONS_BY_MODULE.CONTACTS.VIEW,
  [PAGE_ROUTES.CONTACT_WITH_ID]: PERMISSIONS_BY_MODULE.CONTACTS.VIEW,
  [PAGE_ROUTES.INVOICES]: PERMISSIONS_BY_MODULE.INVOICE.VIEW,
  [PAGE_ROUTES.INVOICE_WITH_ID]: PERMISSIONS_BY_MODULE.INVOICE.VIEW,
  [PAGE_ROUTES.INVOICE_WITH_ID_AND_CONTACT]: PERMISSIONS_BY_MODULE.INVOICE.VIEW,
  [PAGE_ROUTES.BILLS]: PERMISSIONS_BY_MODULE.BILL.VIEW,
  [PAGE_ROUTES.BILL_WITH_ID]: PERMISSIONS_BY_MODULE.BILL.VIEW,
  [PAGE_ROUTES.BILL_WITH_ID_AND_CONTACT]: PERMISSIONS_BY_MODULE.BILL.VIEW,
  [PAGE_ROUTES.ORDERS]: PERMISSIONS_BY_MODULE.PURCHASE_ORDER.VIEW,
  [PAGE_ROUTES.REQUISITIONS]: PERMISSIONS_BY_MODULE.REQUISITION.VIEW,
  [PAGE_ROUTES.QUOTATION_FORM]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.PURCHASE_INWARD_QUOTATION]:
    PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.ASSET_GROUP]: PERMISSIONS_BY_MODULE.ASSET_MANAGEMENT.VIEW,
  [PAGE_ROUTES.ASSET_GROUP_WITH_ID]:
    PERMISSIONS_BY_MODULE.ASSET_MANAGEMENT.VIEW,
  [PAGE_ROUTES.FIXED_ASSET]: PERMISSIONS_BY_MODULE.ASSET_MANAGEMENT.VIEW,
  [PAGE_ROUTES.FIXED_ASSET_WITH_ID]:
    PERMISSIONS_BY_MODULE.ASSET_MANAGEMENT.VIEW,
  [PAGE_ROUTES.DEPRECIATION_SCHEDULE]:
    PERMISSIONS_BY_MODULE.ASSET_MANAGEMENT.VIEW,
  [PAGE_ROUTES.DEPRECIATION_SCHEDULE_WITH_ID]:
    PERMISSIONS_BY_MODULE.ASSET_MANAGEMENT.VIEW,
  [PAGE_ROUTES.ORDER_WITH_ID]: PERMISSIONS_BY_MODULE.PURCHASE_ORDER.VIEW,
  [PAGE_ROUTES.ORDER_WITH_ID_AND_CONTACT]:
    PERMISSIONS_BY_MODULE.PURCHASE_ORDER.VIEW,
  [PAGE_ROUTES.REQUISITION_WITH_ID]: PERMISSIONS_BY_MODULE.REQUISITION.VIEW,
  [PAGE_ROUTES.SALES_ORDERS]: PERMISSIONS_BY_MODULE.SALES_ORDER.VIEW,
  [PAGE_ROUTES.SALES_ORDER_WITH_ID]: PERMISSIONS_BY_MODULE.SALES_ORDER.VIEW,
  [PAGE_ROUTES.SALES_ORDER_WITH_ID_AND_CONTACT]:
    PERMISSIONS_BY_MODULE.SALES_ORDER.VIEW,
  [PAGE_ROUTES.RFQ]: PERMISSIONS_BY_MODULE.RFQ.VIEW,
  [PAGE_ROUTES.RFQ_WITH_ID]: PERMISSIONS_BY_MODULE.RFQ.VIEW,
  [PAGE_ROUTES.QUOTES]: PERMISSIONS_BY_MODULE.QUOTATION.VIEW,
  [PAGE_ROUTES.QUOTE_WITH_ID]: PERMISSIONS_BY_MODULE.QUOTATION.VIEW,
  [PAGE_ROUTES.QUOTE_WITH_ID_AND_CONTACT]: PERMISSIONS_BY_MODULE.QUOTATION.VIEW,
  [PAGE_ROUTES.QUOTE_WITH_ID_AND_ACTION]:
    PERMISSIONS_BY_MODULE.QUOTATION.CONVERT,
  [PAGE_ROUTES.MONEY]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.ACCOUNTING]: PERMISSIONS_BY_MODULE.COA.VIEW,
  [PAGE_ROUTES.JOURNAL_ENTRY]: PERMISSIONS_BY_MODULE.JOURNAL_ENTRY.VIEW,
  [PAGE_ROUTES.CHART_OF_ACCOUNTS]: PERMISSIONS_BY_MODULE.COA.VIEW,
  [PAGE_ROUTES.DEPOSIT]: PERMISSIONS_BY_MODULE.DEPOSIT.VIEW,
  [PAGE_ROUTES.EXPENSE]: PERMISSIONS_BY_MODULE.EXPENSE.VIEW,
  [PAGE_ROUTES.OPENING_BALANCE]: PERMISSIONS_BY_MODULE.COA.VIEW,
  [PAGE_ROUTES.WAREHOUSE]: PERMISSIONS_BY_MODULE.WAREHOUSE.CREATE,
  [PAGE_ROUTES.RULES]: PERMISSIONS_BY_MODULE.BANK.RECONCILE,
  [PAGE_ROUTES.RECONCILE_ACCOUNT]: PERMISSIONS_BY_MODULE.BANK.RECONCILE,
  [PAGE_ROUTES.BANK_DETAILS]: PERMISSIONS_BY_MODULE.BANK.RECONCILE,
  [PAGE_ROUTES.SETTINGS_AUDIT_LOG]: PERMISSIONS_BY_MODULE.LOGS.AUDIT_LOGS,
  [PAGE_ROUTES.SETTINGS_LOGS]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.SETTINGS_AUTOMATION_LOG]: PERMISSIONS_BY_MODULE.LOGS.AUDIT_LOGS,
  [PAGE_ROUTES.BANK_CREATE]: PERMISSIONS_BY_MODULE.BANK.BANK_CONNECT,
  [PAGE_ROUTES.SETTINGS_IMPORT_LOG]: PERMISSIONS_BY_MODULE.LOGS.IMPORT_LOGS,
  [PAGE_ROUTES.SETTINGS_NEGATIVE_INVENTORY]:
    PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.SETTINGS_CUSTOM_FIELDS_LIST]:
    PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.PRODUCT_DASHBOARD]: PERMISSIONS_BY_MODULE.PRODUCTS.VIEW,
  [PAGE_ROUTES.MRP_PRODUCT_DASHBOARD]:
    PERMISSIONS_BY_MODULE.MATERIAL_MASTER.VIEW,
  [PAGE_ROUTES.REPORTS]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.REPORT_PNL]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.REPORTS]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.REPORT_PNL]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.BR_REPORT_PNL]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.REPORT_BS_DETAILS]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.REPORT_BS]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.BR_REPORT_BS]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.REPORT_TB]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.REPORT_BANK_REC_SUMMARY]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.REPORT_SOA_CUSTOMER]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.REPORT_RECONCILIATION_LOG]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.REPORT_SOA_VENDOR]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.REPORT_GL]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.BR_REPORT_GL]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.REPORT_CASHFLOW]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.TALLY_DETAILED_PURCHASE_REPORT]:
    PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.TALLY_SALES_REGISTER_SUMMARY]:
    PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.REPORT_PURCHASE_DN]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.REPORT_PURCHASE_CN]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.REPORT_SALES_INVOICE]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.REPORT_PURCHASE_BILL_LIST]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.REPORT_PURCHASE_ORDER_LIST]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.STOCK_MOVEMENT_REPORT]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.QUOTATION_LIST_REPORT]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.OUTSTANDING_SALES_FULLFILLMENT_REPORT]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.SALES_BY_PRODUCT]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.REPORT_FULFILLMENT_REPORT]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.REPORT_SALES_ORDER_DETAILS]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.PURCHASE_BY_PRODUCT]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.SALES_BY_CONTACT]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.BOM_ASSEMBLY]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.PURCHASE_BY_CONTACT]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.RECEIPT_LIST]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.PAYMENT_LIST]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.REPORT_OPGR]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.STOCK_WAREHOUSE_REPORT]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.REORDER_LEVEL_REPORT]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.RESERVED_STOCK_REPORT]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.STOCK_LEDGER_REPORT]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.AGED_RECEIVABLES_REPORT]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.RATE_ANALYSIS_REPORT]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.REV_REC_JE]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.RECLASSIFICATION_JE]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.RECLASSIFICATION_JE_WITH_PROCESSED_ID]:
    PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.AGED_PAYABLES_REPORT]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.PAYMENT_MILESTONE_TRACKER]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.GOODS_RECEIVED_NOTES]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.PURCHASE_ORDER_DETAIL_REPORT]:
    PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.GSTR1]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.TALLY_RECEIVABLE]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.TALLY_RECEIPT_SUMMARY]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.TALLY_CASHFLOW]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.SALES_BY_CLASS]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.SALES_BY_CLASS_DETAIL]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.JOB_BRIEF_DASHBOARD]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.PRODUCTWISE_QC_REPORT]: PERMISSIONS_BY_MODULE.MRP_REPORTS.VIEW,
  [PAGE_ROUTES.VENDORWISE_QC_REPORT]: PERMISSIONS_BY_MODULE.MRP_REPORTS.VIEW,
  [PAGE_ROUTES.TALLY_STOCK_SUMMARY2]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.TALLY_STOCK_SUMMARY3]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.TALLY_VENDOR_SOA]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.TALLY_CUSTOMER_SOA]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.TALLY_DETAILED_SALES_REPORT]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.TALLY_DAY_BOOK]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.TALLY_STOCK_SUMMARY]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.GSTR_DETAILS]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.GSTR_DETAILS_CDNR]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.GSTR_EXEMPT]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.GSTR_B2CL]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.GSTR_B2CS]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.GSTR_CDNUR]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.GSTR_EXPORT_INVOICE]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.GSTR_AT]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.GSTR_HSN]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.PURCHASE_RETURN_REPORT]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.STOCK_VALUATION]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.SALES_RETURN_REPORT]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.STOCK_AGEING]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.TDS_CUSTOMER]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.FAILED_INVOICES]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.GST_REPORT_LOG]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.GST5FORM_EDIT]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.GST7FORM_EDIT]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.GST5FORM_VIEW]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.GST7FORM_VIEW]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.GSTR2B_REPORT]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.GSTR2B_DOWNLOAD_REPORT]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.GSTR2B_LINK_DOCUMENT]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.GSTR3B_REPORT]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.GST_THREE_ONE_A]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.GST_THREE_ONE_B]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.GST_THREE_ONE_D]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.GST_THREE_TWO_A]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.GST_THREE_TWO_B]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.GST_FOUR_A_ONE]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.GST_FOUR_A_TWO]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.GST_FOUR_A_THREE]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.GST_FOUR_A_FIVE]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.GST_FOUR_B_ONE]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.GST_FOUR_D_ONE]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.GST_FOUR_D_TWO]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.GST_FIVE_TWO]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.GST_FIVE_THREE]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.GST_FIVE_FOUR]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.GST_FOUR_B_TWO]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.TDS_DETAILS]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.TDS_NOT_DED]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.SALES_TAX_US]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.SALES_TAX_AGENCY]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.REPORT_PNL_DETAILS]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.CUSTOM_REPORT_BUILDER]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.BATCH_STATUS]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.GST_RETURN]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.SALES_TAX_RETURN]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.SERVICE_TAX_RETURN]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.GSTR1_RETURN_FILE]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.REPORT_TBD]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.REPORT_ITEM_LANDED_COST]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.REPORT_SALES_EWB_REPORT]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.SSTO2_REPORT_MY]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.BATCH_SERIAL_LADGER_REPORT]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.FINANCIAL_YEAR_CLOSING]:
    PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.PRICE_LIST]: PERMISSIONS_BY_MODULE.PRICE_LIST.VIEW,
  [PAGE_ROUTES.PRICE_BOOK]: PERMISSIONS_BY_MODULE.PRICE_LIST.VIEW,
  [PAGE_ROUTES.SETTINGS_MODULES]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.SETTINGS_ADVANCED_INVENTORY]:
    PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.BUDGETING]: PERMISSIONS_BY_MODULE.BUDGETING.VIEW,
  [PAGE_ROUTES.AUTOMATIONS]: PERMISSIONS_BY_MODULE.AUTOMATION.MANAGE,
  [PAGE_ROUTES.AUTOMATION_BUILDER]: PERMISSIONS_BY_MODULE.AUTOMATION.MANAGE,
  [PAGE_ROUTES.AUTOMATION_BUILDER + '/:id']:
    PERMISSIONS_BY_MODULE.AUTOMATION.MANAGE,
  [PAGE_ROUTES.BK_CLIENT]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.BK_TEAM]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.PREPARE_1099]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.SETTINGS_FILTER_ACCOUNTS]:
    PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.PICK_PACK_SHIP]: PERMISSIONS_BY_MODULE.PPS.READ,
  [PAGE_ROUTES.PRINT_CHECKS]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.IMPORT]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.BANKS]: PERMISSIONS_BY_MODULE.COA.VIEW,
  [PAGE_ROUTES.STOCK_ADJUSTMENT]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.STOCK_REQUEST]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.STOCK_TRANSFER]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.STOCK_ISSUE]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.QUALITY_CHECK]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.MRP_DASHBOARD]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.MRP_HOLIDAY_LIST]: PERMISSIONS_BY_MODULE.SETTINGS.ORGANISATION,
  [PAGE_ROUTES.MRP_PRODUCTS]: PERMISSIONS_BY_MODULE.MATERIAL_MASTER.VIEW,
  [PAGE_ROUTES.MRP_FINISHED_GOODS]: PERMISSIONS_BY_MODULE.MATERIAL_MASTER.VIEW,
  [PAGE_ROUTES.MRP_BILL_OF_MATERIAL]:
    PERMISSIONS_BY_MODULE.MATERIAL_MASTER.VIEW,
  [PAGE_ROUTES.MRP_RAW_MATERIAL]: PERMISSIONS_BY_MODULE.MATERIAL_MASTER.VIEW,
  [PAGE_ROUTES.MRP_PRODUCTION_PLAN]:
    PERMISSIONS_BY_MODULE.SETTINGS.ORGANISATION,
  [PAGE_ROUTES.MRP_LOCATIONS]: PERMISSIONS_BY_MODULE.SETTINGS.ORGANISATION,
  [PAGE_ROUTES.TABLET_DASHBOARD]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.TABLET_DASHBOARD]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.AMORTIZATION_TEMPLATES]:
    PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.AMORTIZATION_SCHEDULES]:
    PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.AMORTIZATION_JE]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.EXPENSE_ALLOCATION_TEMPLATES]:
    PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.AMORTIZATION_FORECAST_REPORT]:
    PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.DEFERRED_EXPENSE_WATERFALL_DETAILS_REPORT]:
    PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.DEFERRED_EXPENSE_ROLLFORWARD_SUMMARY_REPORT]:
    PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.SECURITY_GATE_ENTRY_LIST]:
    PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.REPORT_PNL_BY_DEPARTMENT]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.REPORT_PNL_BY_DEPARTMENT_DETAILS]:
    PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.REPORT_TRIAL_BALANCE_BY_DEPARTMENT]:
    PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.REPORT_TRIAL_BALANCE_BY_DEPARTMENT_DETAILS]:
    PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.SKU_WISE_ORDER_TRACKING]: PERMISSIONS_BY_MODULE.MRP_REPORTS.VIEW,
  [PAGE_ROUTES.BOM_REPORT]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.STOCK_ADJUSTMENT]: PERMISSIONS_BY_MODULE.STOCK_ADJUSTMENT.VIEW,
  [PAGE_ROUTES.STOCK_TRANSFER]: PERMISSIONS_BY_MODULE.STOCK_TRANSFER.VIEW,
  [PAGE_ROUTES.STOCK_REQUEST]: PERMISSIONS_BY_MODULE.STOCK_REQUEST.VIEW,
  [PAGE_ROUTES.STOCK_ISSUE]: PERMISSIONS_BY_MODULE.STOCK_ISSUE.VIEW,
  [PAGE_ROUTES.QUALITY_CHECK]: PERMISSIONS_BY_MODULE.QUALITY_CHECK.VIEW,
  [PAGE_ROUTES.MRP_JOB_CARD]: PERMISSIONS_BY_MODULE.JOB_CARD.VIEW,
  [PAGE_ROUTES.MRP_OPERATOR_LIST]: PERMISSIONS_BY_MODULE.OPERATORS.VIEW,
  [PAGE_ROUTES.MRP_OPERATIONS]: PERMISSIONS_BY_MODULE.OPERATIONS.VIEW,
  [PAGE_ROUTES.MRP_MACHINE_TOOL_MASTER]: PERMISSIONS_BY_MODULE.OPERATIONS.VIEW,
  [PAGE_ROUTES.MRP_MACHINE_SCHEDULING]: PERMISSIONS_BY_MODULE.OPERATIONS.VIEW,
  [PAGE_ROUTES.MRP_ASSET_MAINTENANCE_SCHEDULE]:
    PERMISSIONS_BY_MODULE.OPERATIONS.VIEW,
  [PAGE_ROUTES.MRP_WORK_STATIONS]: PERMISSIONS_BY_MODULE.WORKSTATIONS.VIEW,
  [PAGE_ROUTES.MRP_MASTER_FORECAST]: PERMISSIONS_BY_MODULE.FORECASTING.VIEW,
  [PAGE_ROUTES.MRP_FORECASTING]: PERMISSIONS_BY_MODULE.FORECASTING.VIEW,
  [PAGE_ROUTES.MRP_MASTER_FORECASTING_EDIT_BY_ID]:
    PERMISSIONS_BY_MODULE.FORECASTING.VIEW,
  [PAGE_ROUTES.MRP_MASTER_FORECASTING_DETAIL_BY_ID]:
    PERMISSIONS_BY_MODULE.FORECASTING.VIEW,
  [PAGE_ROUTES.MRP_WORK_ORDER]: PERMISSIONS_BY_MODULE.WORK_ORDER.VIEW,
  [PAGE_ROUTES.MRP_WORK_OUT]: PERMISSIONS_BY_MODULE.JOB_WORKOUTS.VIEW,
  [PAGE_ROUTES.MRP_SCHEDULING]: PERMISSIONS_BY_MODULE.SCHEDULING.VIEW,
  [PAGE_ROUTES.MRP_ROUTING_TEMPLATES]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.MRP_WORK_OUT_WITH_ID]: PERMISSIONS_BY_MODULE.JOB_WORKOUTS.VIEW,
  [PAGE_ROUTES.MRP_FORECASTING_DETAIL]: PERMISSIONS_BY_MODULE.FORECASTING.VIEW,
  [PAGE_ROUTES.MRP_FORECASTING_DETAIL_BY_ID]:
    PERMISSIONS_BY_MODULE.FORECASTING.VIEW,
  [PAGE_ROUTES.MRP_MASTER_FORECAST_CREATE]:
    PERMISSIONS_BY_MODULE.FORECASTING.VIEW,
  [PAGE_ROUTES.MRP_JOB_CARD_BY_WORK_ORDER_ID]:
    PERMISSIONS_BY_MODULE.JOB_CARD.VIEW,
  [PAGE_ROUTES.FINANCIAL_YEAL_CLOSING]:
    PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.GOODS_RECEIPT_MASTER_LIST]:
    PERMISSIONS_BY_MODULE.PURCHASE_ORDER.RECEIVE_GOODS,
  [PAGE_ROUTES.FULFILLMENT_MASTER_LIST]:
    PERMISSIONS_BY_MODULE.SALES_ORDER.FULFILL,
  [PAGE_ROUTES.GENERATE_BANK_FILE_INVOICE]:
    PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.GENERATE_BANK_FILE_BILL]:
    PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.REPORTS]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.MRP_REPORTS_LIST]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.DEBIT_NOTES]: PERMISSIONS_BY_MODULE.DEBIT_NOTES.VIEW,
  [PAGE_ROUTES.CREDIT_NOTES]: PERMISSIONS_BY_MODULE.CREDIT_NOTES.VIEW,
  [PAGE_ROUTES.QUOTATION_LIST_REPORT]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.OUTSTANDING_SALES_FULLFILLMENT_REPORT]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.REPORT_SALES_INVOICE]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.RECEIPT_LIST]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.REPORT_PURCHASE_CN]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.REPORT_PURCHASE_BILL_LIST]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.REPORT_PURCHASE_ORDER_LIST]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.PAYMENT_LIST]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.REPORT_PURCHASE_DN]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.STOCK_MOVEMENT_REPORT]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.CUSTOM_REPORT_BUILDER]: PERMISSIONS_BY_MODULE.DEFAULT.IS_DEFAULT,
  [PAGE_ROUTES.REVALUATION]: PERMISSIONS_BY_MODULE.REPORTS.VIEW,

};

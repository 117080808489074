import ComponentManager from "../../Manager/ComponentManager"
import CustomFieldManager from "../../Manager/CustomFieldManager"
import TemplateSettingsManager from "../../Manager/TemplateSettingsManager"
import { convertDateFromERPFormatToDocFormat, getCapitalized, getConvertedDate, getIsBill, getIsExpenseBill, getIsOrder } from "../../Utilities/Utility"
import AdditionalChargeParser from "../AdditionalChargeParser"
import Address from "../Address"
import AddressParser, { AddressType, OwnerType } from "../AddressParser"
import DataParser from "../DataParser"
import LineItemsParser from "../LineItemsParser"
import TenantParser from "../TenantParser"

export default class BillAndOrderParser {
    static parseData(dataFromAPI, templateDataToReturn, isGeneratedData, isReadOnlyMode) {

        //custom field
        templateDataToReturn.customFields = dataFromAPI.customFields
        CustomFieldManager.setCustomFields(dataFromAPI.customFields)
        templateDataToReturn = DataParser.assignCustomFieldValue(templateDataToReturn)

        //company name
        templateDataToReturn.companyName = getCapitalized(dataFromAPI.billToName)
        templateDataToReturn.tenantInfo = dataFromAPI.tenantInfo;

        //company address
        var fromAddressObj = new Address(undefined, dataFromAPI.shipFromAddress, dataFromAPI.contactInfo.pan, dataFromAPI.contactInfo.gstin, dataFromAPI.contactInfo.contactNumber, dataFromAPI.contactInfo.documentSequenceCode, OwnerType.contact)
        let shipFromEmail = dataFromAPI.shipFromAddress?.emailId ?? ''
        fromAddressObj.emailAddress = shipFromEmail
        if(shipFromEmail === '') {
            fromAddressObj.emailAddress = dataFromAPI.contactInfo?.emailId ?? ''
        }
        let shipFromContactNumber = dataFromAPI.shipFromAddress?.mobileNumber ?? ''
        fromAddressObj.contactNumber = shipFromContactNumber
        if (shipFromContactNumber === '') {
            fromAddressObj.contactNumber = dataFromAPI.contactInfo?.contactNumber ?? ''
        }
        fromAddressObj.pan = dataFromAPI.contactInfo?.pan ?? ''
        fromAddressObj.cin = dataFromAPI.contactInfo?.cin ?? ''
        templateDataToReturn.fromObj = fromAddressObj
        templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.from, templateDataToReturn)

        //billing to address
        var clientBillToAddressObj = new Address(dataFromAPI.companyName, dataFromAPI.billToAddress, dataFromAPI.pan, dataFromAPI.gstin, dataFromAPI.tenantInfo.contactNumber, undefined, OwnerType.tenant)
        templateDataToReturn.clientBillToAddressObj = clientBillToAddressObj
        templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.billTo, templateDataToReturn)

        // shipping to address
        var shipToName = dataFromAPI.companyName
        var shipToPan = dataFromAPI.pan
        var shipToGstin = dataFromAPI.gstin
        var shipToContactName = dataFromAPI.tenantInfo.contactNumber
        var shipToType = OwnerType.tenant
        if (dataFromAPI.dropShipContactName !== undefined && dataFromAPI.dropShipContactName !== null) {
            if (dataFromAPI.dropShipContactName !== '') {
                //fix dropship bill to value different from normal PO
                templateDataToReturn.companyName = getCapitalized(dataFromAPI.clientName)
                shipToName = dataFromAPI.dropShipContactName
                shipToPan = undefined
                shipToGstin = undefined
                shipToContactName = undefined
                shipToType = OwnerType.contact
            }
        }

        var clientShipToAddressObj = new Address(shipToName, dataFromAPI.shipToAddress, shipToPan, shipToGstin, shipToContactName, undefined, shipToType)
        templateDataToReturn.clientShipToAddressObj = clientShipToAddressObj
        templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.shipTo, templateDataToReturn)

        var clientShipFromAddressObj = new Address(templateDataToReturn.companyName, dataFromAPI.shipFromAddress,dataFromAPI.contactInfo.pan, dataFromAPI.contactInfo.gstin, dataFromAPI.contactInfo.contactNumber, dataFromAPI.contactInfo.documentSequenceCode, OwnerType.contact)
        if (clientShipFromAddressObj.emailId === undefined || clientShipFromAddressObj.emailId === null || clientShipFromAddressObj.emailId === '') {
            clientShipFromAddressObj.emailAddress = dataFromAPI.contactInfo?.emailId ?? ''
        }
        if (clientShipFromAddressObj.mobileNumber === undefined || clientShipFromAddressObj.mobileNumber === null || clientShipFromAddressObj.mobileNumber === '') {
            clientShipFromAddressObj.contactNumber = dataFromAPI.contactInfo?.contactNumber ?? ''
        }
        templateDataToReturn.clientShipFromAddressObj = clientShipFromAddressObj
        templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.shipFrom, templateDataToReturn)

        templateDataToReturn.taxRegistrationNumber = ""
        templateDataToReturn.taxPayerId = ""
        if (dataFromAPI.contactInfo && dataFromAPI.contactInfo !== null) {
            templateDataToReturn.taxRegistrationNumber = dataFromAPI.contactInfo.taxNumber ?? ''
            templateDataToReturn.taxPayerId = dataFromAPI.contactInfo.taxPayerIdIsrael ?? ''
        }

        //SA Address
        templateDataToReturn = this.getSaudiArabiaAddress(dataFromAPI, templateDataToReturn)

        var tenantAddressObj = new Address(undefined, AddressParser.getTenantAddressFromApi(dataFromAPI.shipFromAddress, dataFromAPI.tenantInfo), dataFromAPI.pan, dataFromAPI.gstin, dataFromAPI.tenantInfo.contactNumber, undefined, OwnerType.tenant)
        templateDataToReturn.tenantAddressObj = tenantAddressObj
        templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.tenantAddress, templateDataToReturn)

        //ref number
        templateDataToReturn.refNumber = dataFromAPI.refNumber

        //start date
        templateDataToReturn.date = getConvertedDate(convertDateFromERPFormatToDocFormat(dataFromAPI.date), templateDataToReturn.dateFormat)

        //end date
        templateDataToReturn.dueDate = getConvertedDate(convertDateFromERPFormatToDocFormat(dataFromAPI.dueDate), templateDataToReturn.dateFormat)
        templateDataToReturn.showDueDate = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.date.dueDate.isVisible

        if (dataFromAPI?.tenantInfo?.gstin && dataFromAPI?.tenantInfo?.country==='IN') {
            templateDataToReturn.sourceOfSupply = dataFromAPI.shipFromAddress?.placeOfSupply ? dataFromAPI.shipFromAddress?.placeOfSupply  : dataFromAPI.shipFromAddress.state ;
            templateDataToReturn.destinationOfSupply = dataFromAPI.shipToAddress?.destinationOfSupply ? dataFromAPI.shipToAddress?.destinationOfSupply  : dataFromAPI.shipToAddress.state ;
        }
        //line items
        templateDataToReturn.lineItems = LineItemsParser.getProductLineItem(dataFromAPI, isGeneratedData, templateDataToReturn.dateFormat)

        templateDataToReturn.totalTaxAmount = dataFromAPI.totalTaxAmount ?? 0
        
        //stock items
        if (isReadOnlyMode) {
            var isContainStockItem = false
            if (templateDataToReturn.stockItems !== undefined && templateDataToReturn.stockItems !== null) {
                isContainStockItem = templateDataToReturn.stockItems.length > 0
            }
            if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.stockTable === undefined) {
                TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails['stockTable'] = { 'isVisible': true }
            }
            TemplateSettingsManager.updateStockTableVisibility(isContainStockItem)
            templateDataToReturn.showStockTable = isContainStockItem
        }
        else {
            templateDataToReturn.showStockTable = true
            TemplateSettingsManager.updateStockTableVisibility(true)
        }

        //payment link
        if (dataFromAPI.paymentLink === null || dataFromAPI.paymentLink === undefined) {
            templateDataToReturn.paymentItems = []
        }
        else if (dataFromAPI.paymentLink === "") {
            templateDataToReturn.paymentItems = []
        } else {
            templateDataToReturn.paymentItems = [dataFromAPI.paymentLink]
        }

        //payment items
        if (isReadOnlyMode) {
            var isContainPaymentItem = templateDataToReturn.paymentItems.length > 0
            if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.paymentTable === undefined) {
                TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails['paymentTable'] = { 'isVisible': true }
            }

            TemplateSettingsManager.updatePaymentTableVisibility(isContainPaymentItem)
            templateDataToReturn.showPaymentTable = isContainPaymentItem
        }
        else {
            if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.paymentTable === undefined) {
                TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails['paymentTable'] = { 'isVisible': true }
            }
            else {
                templateDataToReturn.showPaymentTable = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.paymentTable.isVisible
            }
        }


        //additional date
        var showAdditionalDate = undefined
        if (TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.date.additionalDate !== undefined) {
            showAdditionalDate = TemplateSettingsManager.defaultTemplateSettings.documentInfo.documentDetails.date.additionalDate.isVisible
        }

        if (getIsOrder(templateDataToReturn.type)) {
            if (dataFromAPI.receiveByDate !== undefined && dataFromAPI.receiveByDate !== null) {
                templateDataToReturn.additionalDate = getConvertedDate(convertDateFromERPFormatToDocFormat(dataFromAPI.receiveByDate), templateDataToReturn.dateFormat)
            }
            templateDataToReturn.showAdditionalDate = showAdditionalDate
        }
        else if (getIsBill(templateDataToReturn.type) && !getIsExpenseBill(templateDataToReturn.type, templateDataToReturn.lineItems)) {
            if (dataFromAPI.receiveByDate !== undefined && dataFromAPI.receiveByDate !== null) {
                templateDataToReturn.additionalDate = getConvertedDate(convertDateFromERPFormatToDocFormat(dataFromAPI.receiveByDate), templateDataToReturn.dateFormat)
            }
            templateDataToReturn.showAdditionalDate = showAdditionalDate
        }

        //location
        var locationDetails = TenantParser.getLocationDetail(dataFromAPI)
        var locationName = dataFromAPI.companyName
        if (locationDetails.name !== undefined && locationDetails.name !== null) {
            if(locationDetails.name !== '') {
                locationName = locationDetails.name
            }
        }
        if (locationDetails.address !== undefined) {
            clientBillToAddressObj = new Address(locationName, locationDetails.address, dataFromAPI.pan, dataFromAPI.gstin, dataFromAPI.tenantInfo.contactNumber, undefined, OwnerType.tenant)
            templateDataToReturn.clientBillToAddressObj = clientBillToAddressObj
            templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.billTo, templateDataToReturn)

            clientShipToAddressObj = new Address(locationName, locationDetails.address, dataFromAPI.pan, dataFromAPI.gstin, dataFromAPI.tenantInfo.contactNumber, undefined, OwnerType.tenant)
            templateDataToReturn.clientShipToAddressObj = clientShipToAddressObj
            templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.shipTo, templateDataToReturn)
        }

        //Additional charge and global
        templateDataToReturn = AdditionalChargeParser.getAdditionalCharge(dataFromAPI, templateDataToReturn)

        templateDataToReturn.paymentsFooter = dataFromAPI.payments
        if (templateDataToReturn.showPaymentFooter) {
            templateDataToReturn.payments = templateDataToReturn.paymentsFooter
        }

        if (dataFromAPI.youOweThem !== undefined && dataFromAPI.youOweThem !== null) {
            if (dataFromAPI.total !== undefined && dataFromAPI.total !== null) {
                templateDataToReturn.outstandingBalance = dataFromAPI.youOweThem - dataFromAPI.total
            }
            else {
                templateDataToReturn.outstandingBalance = dataFromAPI.youOweThem - ComponentManager.getTotalOfLineItems(templateDataToReturn.lineItems, false, false, 0)
            }
            templateDataToReturn.outstandingBalanceIncludesCurrent = dataFromAPI.youOweThem
        }

        templateDataToReturn.receiptStatus = dataFromAPI.receiptStatus

        templateDataToReturn.gstNo = dataFromAPI.tenantInfo.gstin
        templateDataToReturn.panNo = dataFromAPI.tenantInfo.panIndia
        templateDataToReturn.cinNo = dataFromAPI.tenantInfo.cinIndia

        templateDataToReturn.supplierInvoiceNo = dataFromAPI.supplierInvoiceNo

        templateDataToReturn.createdBy = dataFromAPI.createdBy;
        templateDataToReturn.createdDate = dataFromAPI.createdDate;

        templateDataToReturn.preparedDateTime = new Date().toLocaleString()

        // authorised by
        templateDataToReturn.approvedBy = dataFromAPI.approvedBy

        return templateDataToReturn
    }

    static getSaudiArabiaAddress(dataFromAPI, templateDataToReturn) {
        if (dataFromAPI.country === 'SA') {
            //Company Name
            var name = templateDataToReturn.companyName
            if (dataFromAPI.contactNameInArabic !== undefined && dataFromAPI.contactNameInArabic !== null) {
                name = dataFromAPI.contactNameInArabic
            }

            templateDataToReturn.companyNameInArabic = templateDataToReturn.companyName
            if (name !== templateDataToReturn.companyName) {
                if (name.length > 0) {
                    templateDataToReturn.companyNameInArabic = name
                }
            }

            //contact address
            var contactNameInArabic = ''
            if (dataFromAPI.companyNameInArabic !== undefined && dataFromAPI.companyNameInArabic !== null) {
                contactNameInArabic = dataFromAPI.companyNameInArabic
            }

            if (contactNameInArabic !== '') {
                templateDataToReturn.contactNameInArabic = contactNameInArabic
            }

            //Company Address
            var fromAddressInArabicObj = new Address(undefined, dataFromAPI.shipFromAddressInArabic, dataFromAPI.contactInfo.pan, dataFromAPI.contactInfo.gstin, dataFromAPI.contactInfo.contactNumber, dataFromAPI.contactInfo.documentSequenceCode, OwnerType.contact)

            templateDataToReturn.fromInArabicObj = fromAddressInArabicObj
            if (fromAddressInArabicObj !== undefined && fromAddressInArabicObj !== null) {
                templateDataToReturn = AddressParser.assignAddressObjToStringForArabic(undefined, AddressType.from, templateDataToReturn)
            }
            else {
                templateDataToReturn.fromInArabicObj = templateDataToReturn.fromObj
                templateDataToReturn.fromInArabic = templateDataToReturn.from
            }

            //billing address
            var clientBillToAddressObj = new Address(templateDataToReturn.contactNameInArabic, dataFromAPI.billToAddressInArabic, dataFromAPI.pan, dataFromAPI.gstin, dataFromAPI.tenantInfo.contactNumber, undefined, OwnerType.tenant)
            templateDataToReturn.clientBillToAddressInArabicObj = clientBillToAddressObj
            if (clientBillToAddressObj !== undefined && clientBillToAddressObj !== null) {
                templateDataToReturn = AddressParser.assignAddressObjToStringForArabic(undefined, AddressType.billTo, templateDataToReturn)
            }
            else {
                templateDataToReturn.billToInArabic = templateDataToReturn.billTo
                templateDataToReturn.clientBillToAddressInArabic = templateDataToReturn.clientBillToAddress
            }

            //Ship To Address
            var clientShipToAddressObj = new Address(templateDataToReturn.contactNameInArabic, dataFromAPI.shipToAddressInArabic, dataFromAPI.pan, dataFromAPI.gstin, dataFromAPI.tenantInfo.contactNumber, undefined, OwnerType.tenant)


            templateDataToReturn.clientShipToAddressInArabicObj = clientShipToAddressObj
            if (clientShipToAddressObj !== undefined && clientShipToAddressObj !== null) {
                templateDataToReturn = AddressParser.assignAddressObjToStringForArabic(undefined, AddressType.shipTo, templateDataToReturn)
            }
            else {
                templateDataToReturn.shipToInArabic = templateDataToReturn.shipTo
                templateDataToReturn.clientShipToAddressInArabic = templateDataToReturn.clientShipToAddress
            }
        }
        return templateDataToReturn
    }

}

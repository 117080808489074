import {
  DKButton,
  DKCalendar,
  DKCheckMark,
  DKDataGrid,
  DKIcon,
  DKIcons,
  TOAST_TYPE,
  showToast,
  showLoader,
  removeLoader,
  DKLabel,
  DKListPicker2,
  INPUT_TYPE,
  showAlert
} from 'deskera-ui-library';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TAX_ADJUSTMENT_TYPES_INDIA } from '../../../Configs/JEConfig';
import ApiConstants from '../../../Constants/ApiConstants';
import {
  BOOKS_DATE_FORMAT,
  CLASS_ASSIGNMENT,
  CURRENCY_HISTORY_CONSTANTS,
  CUSTOM_FIELD_TYPE,
  CUSTOM_NUMBER_INPUT_MODULES,
  DATE_FORMAT,
  DOCUMENT_MODE,
  DOC_TYPE,
  GENERATE_CN_DN_LIST,
  LOCATION_CLASS_ENUM,
  MODULES_NAME,
  POPUP_CALLBACKS_TYPE,
  POPUP_CLICK_TYPE,
  POPUP_TYPE,
  STATUS_TYPE
} from '../../../Constants/Constant';
import { PERMISSIONS_BY_MODULE } from '../../../Constants/Permission';
import AppManager from '../../../Managers/AppManager';
import { PartyJEConfigManager } from '../../../Managers/PartyJEConfigManager';
import {
  CallBackPayloadType,
  PopupClickActionType,
  UpdateCallBacksRefType
} from '../../../Models/Interfaces';
import { CD_TYPE, PartyJEInitialState } from '../../../Models/JournalEntry';
import { useAppDispatch, useAppSelector } from '../../../Redux/Hooks';
import {
  fetchAccoutnsList,
  selectedAccounts
} from '../../../Redux/Slices/AccountsSlice';
import { activeTenantInfo } from '../../../Redux/Slices/AuthSlice';
import {
  fetchClassesByDimensionId,
  selectCurrencyListWithExchangeRate,
  selectCustomFields
} from '../../../Redux/Slices/CommonDataSlice';
import { selectContacts } from '../../../Redux/Slices/ContactsSlice';
import {
  fetchJournal,
  selectJournalColumnConfig,
  selectJournalColumnConfigTableId
} from '../../../Redux/Slices/Journal';
import {
  fetchCategoryDimensions,
  selectDimensions
} from '../../../Redux/Slices/LocationSlice';
import { Store } from '../../../Redux/Store';
import AccountsService, { AccountAPIConfig } from '../../../Services/Accounts';
import AttachmentService from '../../../Services/Attachment';
import ContactService, { ContactAPIConfig } from '../../../Services/Contact';
import DateFormatService from '../../../Services/DateFormat';
import JournalService from '../../../Services/Journal';
import NumberFormatService from '../../../Services/NumberFormat';
import { CustomFieldsHolder } from '../../../SharedComponents/CustomFieldsHolder/CustomFieldsHolder';
import CustomNumberFormatInput from '../../../SharedComponents/CustomNumberFormat/CustomNumberFormatInput';
import {
  customFieldsContainsErrors,
  updateColumnConfigOnRowClick,
  updateRowDataWithParentCFValues,
  validateBudgetForAccounts
} from '../../../SharedComponents/DocumentForm/NewDocumentHelper';
import PopupWrapper from '../../../SharedComponents/PopupWrapper';
import { ConfigUtility } from '../../../Utility/ConfigUtility';
import Utility from '../../../Utility/Utility';
import { isViewportLarge } from '../../../Utility/ViewportSizeUtils';
import AddContact from '../../Contacts/AddContact';
import { triggerDownload } from '../../ImportExport/utility/ExportData';
import AddClass from '../../Settings/Classes/AddClass';
import { GranularPermissionsHelper } from '../../Settings/GranularPermissions/GranularPermissionsHelper';
import AddCoaPopup from '../COA/AddCoaPopup';
import { getNewColumn, getNewItem } from './JEHelper';
import { getEmptyRowType, jeTypesArray } from './JournalHelper';
import CustomFieldSettings from '../../../SharedComponents/CustomFieldsHolder/CustomFieldSettings';
import CustomFieldService from '../../../Services/CustomField';
import useScreenResize from '../../../Hooks/useScreenResize';
import CurrencyService, { RealtimeCurrencyAPIConfig } from '../../../Services/Currency';
import { fetchCurrencyHistroy, selectCurrencyHistory } from '../../../Redux/Slices/CurrencySlice';

export default function AddPartyJournal(props: any) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const jeState = props.je ? props.je : PartyJEInitialState;
  const refInitialState: UpdateCallBacksRefType = {
    pushDataToParent: { type: POPUP_CALLBACKS_TYPE.NONE },
    storeCallbacksRef: { createDebit: 'click', updateContact: 'click' }
  };

  //states
  const [je, setJE] = useState(jeState);

  let d;
  if (props.je) {
    d = props.je.jeDate.split('-');
  }

  const initialDate = props.je
    ? new Date(`${d[2]}, ${d[1]}, ${d[0]}`)
    : new Date();
  const [journalDate, setJournalDate] = useState(initialDate);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [attachments, setAttachments] = useState<any>([]);
  const [columnConfig, setColumnConfig] = useState(
    PartyJEConfigManager.docConfigs
  );
  const dimensionData = useAppSelector(selectDimensions);
  const [showAccountPopup, setShowAccountPopup] = useState(false);
  const [submitButtonTapped, setSubmitButtonTapped] = useState(false);
  const [customFieldsData, setCustomFieldsData] = useState<any[]>([]);
  const isReadOnlyMode = props.readOnly ? true : false;
  const isEditMode = Utility.isEmpty(props.je) ? false : true;

  //multi-currency states
  const [showMultiCurrencyList, setShowMultiCurrencyList] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState<any>();
  const [conversionRate, setConversionRate] = useState<any>();

  //selectors
  const currencyExchangeRates = useAppSelector(
    selectCurrencyListWithExchangeRate
  );
  const [currencyHistoryData, setCurrencyHistoryData] = useState<any[]>([]);
  const tenantInfo = useAppSelector(activeTenantInfo); //bookBeginningStartDate
  const journalColumnConfig = useAppSelector(selectJournalColumnConfig);
  const journalConfigTableId = useAppSelector(selectJournalColumnConfigTableId);
  // const selectCustomFieldsData: any = useAppSelector(selectCustomFields);
  const accountsData = useAppSelector(selectedAccounts);
  const systemDimensions = useAppSelector(selectDimensions);
  const [showAddClassPopup, setShowAddClassPopup] = useState(false);
  const [selectedNumberFormat, setSelectedNumberFormat] = useState<any>({});
  const contactsList = useAppSelector(selectContacts);
  const [showAddContactPopup, setShowAddContactPopup] = useState(false);
  const partyJEFormRef = useRef<UpdateCallBacksRefType>(refInitialState);
  const [isDesktop, setIsDesktop] = useState(isViewportLarge());
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [openAccountCFSettings, setOpenAccountCFSettings] = useState(false);
  const [accountsCFData, setAccountsCFData] = useState<any[]>([]);
  const cfUpdatedTimeMap = useRef<any>({});
  const gridContainerRef = useRef<HTMLDivElement | null>(null);
  const [gridWidth] = useScreenResize(gridContainerRef);

  const cdTypesArray = [jeTypesArray?.[0], jeTypesArray?.[1]];

  useEffect(() => {
    getAccountsCFData();
    AppManager.handleWindowResizeListener(onWindowResize, true);
    return () => {
      AppManager.handleWindowResizeListener(onWindowResize, false);
    };
  }, []);

  const onWindowResize = () => {
    setIsDesktop(isViewportLarge);
  };

  useEffect(() => {
    registerInteractions();
  }, [attachments, je]);

  // effects
  useEffect(() => {
    const updatedState = { ...je };

    let oldProps: any;
    if (props.je) {
      oldProps = props.je;
    }

    if (!accountsData?.content) {
      dispatch(fetchAccoutnsList());
    }

    // attachment API call
    if (props.je?.id) {
      AttachmentService.attachmentConfig = {
        ...AttachmentService.attachmentConfig,
        Module: DOC_TYPE.JOURNAL_ENTRY,
        EntityId: props.je?.id
      };
      AttachmentService.getAllAttachments().then((attachmentList: any) => {
        setAttachments(attachmentList);
      });
    }

    const filteredCurr = currencyExchangeRates.find((curr) => {
      if (props.je) {
        return curr.currencyCode === updatedState.currency;
      } else {
        return curr.currencyCode === tenantInfo.currency;
      }
    });

    let rate = getRate(filteredCurr);
    if (props.je) {
      rate = props.je?.currencyExchangeRate;
    }
    setSelectedCurrency(filteredCurr);
    setConversionRate(rate);
    setJE({ ...updatedState });
  }, []);

  useEffect(() => {
    if (
      Utility.isNotEmpty(selectedCurrency) &&
      Utility.isNotEmpty(currencyHistoryData)
    ) {
      if (journalDate !== null && journalDate !== undefined) {
        let jeDateFilteredCurr = currencyHistoryData.find((curr: any) => {
          if (
            Utility.isNotEmpty(curr?.exchangeRateDate) &&
            Utility.compareDateWithoutTime(
              new Date(curr?.exchangeRateDate),
              journalDate
            ) <= 0
          ) {
            return curr.currencyCode === selectedCurrency.currencyCode;
          }
          return false;
        });
        const filteredCurr = currencyHistoryData
          .slice()
          .reverse()
          .find((curr: any) => {
            return curr.currencyCode === selectedCurrency.currencyCode;
          });
        if (jeDateFilteredCurr === undefined || jeDateFilteredCurr === null) {
          jeDateFilteredCurr = filteredCurr;
        }
        if (Utility.isNotEmpty(jeDateFilteredCurr)) {
          setConversionRate(getRate(jeDateFilteredCurr));
        }
      }
    }
  }, [currencyHistoryData]);

  useEffect(() => {
    updateConfig();
  }, [accountsData?.content]);

  useEffect(() => {
    registerInteractions();
    updateConfig();
  }, [selectedCurrency, columnConfig]);

  useEffect(() => {
    showLoader();
    getCurrencyHistoryViaCode(selectedCurrency?.currencyCode)
      .then((res) => {
        setCurrencyHistoryData(res);
      })
      .finally(() => {
        removeLoader();
      });
  }, [selectedCurrency]);

  const getRealTimeCurrencyHistoryViaCode = async (code: string) => {
    if (
      Utility.isNotEmpty(code) &&
      Utility.isNotEmpty(tenantInfo?.currency) &&
      code !== tenantInfo?.currency &&
      journalDate !== null &&
      journalDate !== undefined
    ) {
      let payload: RealtimeCurrencyAPIConfig = {
        startDate: Utility.formatDate(journalDate, DATE_FORMAT.DD_MM_YYYY),
        endDate: Utility.formatDate(journalDate, DATE_FORMAT.DD_MM_YYYY),
        baseCurrency: tenantInfo.currency,
        primaryCurrency: code
      };
      showLoader();
      try{
        const response = await CurrencyService.getRealtimeCurrencyHistory(payload)
        removeLoader()
        return Utility.mapToCurrencyHistoryList(response) ?? [];
      }catch{
        removeLoader()
        return [];
      }
    }else{
      return [];
    }
  };

  const getCurrencyHistoryViaCode = async (code: string) => {
    if (Utility.isNotEmpty(code)) {
      const prevConfig = CurrencyService.currencyHistoryApiConfig;
      CurrencyService.currencyHistoryApiConfig = {
        Limit: 1000,
        Page: 0,
        SortDir: CURRENCY_HISTORY_CONSTANTS.SortDir,
        Sort: CURRENCY_HISTORY_CONSTANTS.Sort,
        Dir: CURRENCY_HISTORY_CONSTANTS.Dir,
        Start: 0,
        Query: `currencyCode=${code}`
      };
      try {
        const response: any = await CurrencyService.getCurrencyHistrory();
        CurrencyService.currencyHistoryApiConfig = prevConfig;
        return response?.content ?? [];
      } catch {
        return [];
      }
    }
    return [];
  };

  useEffect(() => {
    let date = DateFormatService.getDateStrFromDate(
      journalDate,
      BOOKS_DATE_FORMAT['DD-MM-YYYY']
    );
    if (Utility.isNotEmpty(currencyHistoryData) && Utility.isNotEmpty(date)) {
      let jeDateFilteredCurr = currencyHistoryData.find((curr: any) => {
        if (
          Utility.compareDateWithoutTime(
            new Date(curr?.exchangeRateDate),
            journalDate
          ) <= 0
        ) {
          if (selectedCurrency) {
            return curr.currencyCode === selectedCurrency?.currencyCode;
          } else {
            return curr.currencyCode === tenantInfo.currency;
          }
        }
        return false;
      });
      const filteredCurr = currencyHistoryData
        .slice()
        .reverse()
        .find((curr: any) => {
          if (selectedCurrency) {
            return curr.currencyCode === selectedCurrency?.currencyCode;
          } else {
            return curr.currencyCode === tenantInfo.currency;
          }
        });
      if (jeDateFilteredCurr === undefined || jeDateFilteredCurr === null) {
        getRealTimeCurrencyHistoryViaCode(selectedCurrency?.currencyCode).then(
          (realtimeExchangeRateData) => {
            if (Utility.isNotEmpty(realtimeExchangeRateData)) {
              jeDateFilteredCurr = realtimeExchangeRateData?.[0];
            } else {
              jeDateFilteredCurr = filteredCurr;
            }
            setConversionRate(getRate(jeDateFilteredCurr));
          }
        );
      }else{
        setConversionRate(getRate(jeDateFilteredCurr));
      }
    }
    setJE({ ...je, jeDate: date });
  }, [journalDate]);

  useEffect(() => {
    if (!Utility.isEmpty(accountsCFData)) {
      let oldColConfigs = [...columnConfig];

      const accCustomFields = accountsCFData;

      accCustomFields?.forEach((accCF: any) => {
        const newItem = getNewColumn(accCF);
        oldColConfigs.push(newItem);
      });

      setCustomFieldsData(accCustomFields);
      setColumnConfig(oldColConfigs);
    }
  }, [accountsCFData, systemDimensions]);

  useEffect(() => {
    const updatedState = { ...props.je };
    let oldColConfigs = [...columnConfig];

    if (props.je?.id && !Utility.isEmpty(customFieldsData)) {
      props?.je?.lineItems?.forEach((item1: any, index1: any) => {
        item1?.customField?.forEach((item: any, index: any) => {
          let filteredCF: any = customFieldsData?.find(
            (cf: any) => cf.id === item.id
          );
          if (!Utility.isEmpty(filteredCF)) {
            const newItem = getNewItem(item, filteredCF);
            let cfValue;
            if (
              filteredCF.fieldType.toLowerCase() ===
              INPUT_TYPE.DATE.toLowerCase()
            ) {
              cfValue = DateFormatService.getDateFromStr(
                item.value,
                BOOKS_DATE_FORMAT['MM/DD/YYYY']
              );
            } else if (filteredCF.fieldType.toLowerCase() === 'user') {
              const tempCF = filteredCF?.attributes?.find(
                (attr: any) => attr.code === item.value
              );
              if (tempCF) {
                cfValue = tempCF ? tempCF : '';
              }
            } else if (
              filteredCF.fieldType.toLowerCase() ===
              CUSTOM_FIELD_TYPE.DROPDOWN.toLowerCase()
            ) {
              cfValue = item ? item : '';
            } else {
              cfValue = item.value ? item.value : '';
            }

            let alreadyPresentConfig = oldColConfigs?.find(
              (col: any) => col.id === item.id
            );

            if (Utility.isEmpty(alreadyPresentConfig)) {
              oldColConfigs.push(newItem);
            }
            updatedState.lineItems[index1][item.id] = cfValue;
          }
        });
      });

      setColumnConfig([...oldColConfigs]);
      setJE({ ...updatedState });
    }
  }, [customFieldsData]);

  //////////////////////////////////// custom fields - start ///////////////////////////////

  const getCustomFieldView = () => {
    return (
      <div className="column parent-width ">
        <CustomFieldsHolder
          documentMode={isReadOnlyMode ? DOCUMENT_MODE.VIEW : DOCUMENT_MODE.NEW}
          moduleName={MODULES_NAME.JOURNAL}
          customFieldsList={props.je ? je.customField : []}
          columnConfig={journalColumnConfig}
          columnConfigTableId={journalConfigTableId ? journalConfigTableId : ''}
          onUpdate={(list: any) => {
            setJE({
              ...je,
              customField: list
            });
          }}
        />
      </div>
    );
  };

  /////////////////////////////////// custom fields - end //////////////////////////////////

  const closeCreateView = (data: any = {}) => {
    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.CLOSE_POPUP,
        data: data
        // show variantform
      });
    }
  };

  const getRate = (currObj: any) => {
    return (1 / Number(currObj?.currencyExchangeRate)).toFixed(4);
  };

  const validateAccounts = () => {
    let result = true;

    //dd-mm-yyyy
    const jeDate = DateFormatService.getDateFromStr(
      je.jeDate,
      BOOKS_DATE_FORMAT['DD-MM-YYYY']
    );
    //yyyy-mm-dd
    const bbDate = DateFormatService.getDateFromStr(
      tenantInfo.bookBeginningStartDate,
      BOOKS_DATE_FORMAT['YYYY-MM-DD']
    );

    if (jeDate < bbDate) {
      let buttons = [
        {
          title: 'Ok',
          className: 'bg-button text-white',
          onClick: () => {}
        }
      ];
      showAlert(
        'Please Note!',
        `Journal date should be greater than books beginning date <div class="fw-b">(${DateFormatService.getFormattedDateString(
          tenantInfo.bookBeginningStartDate,
          BOOKS_DATE_FORMAT['YYYY-MM-DD'],
          BOOKS_DATE_FORMAT['DD/MM/YYYY']
        )}).</div>`,
        buttons
      );
      result = false;
    }

    if (je.lineItems.length === 0) {
      result = false;
    }

    for (let i = 0; i < je.lineItems.length; i++) {
      const element = je.lineItems[i];
      if (
        element.contact === null ||
        element.contact === undefined ||
        element.contact.length === 0
      ) {
        result = false;
      }
      if (
        element.account === null ||
        element.account === undefined ||
        element.account.length === 0
      ) {
        result = false;
      }
      if (
        element.amount === null ||
        element.amount === undefined ||
        element.amount.length === 0 ||
        !element.amount
      ) {
        result = false;
      }
      if (
        element.type === null ||
        element.type === undefined ||
        element.type.length === 0
      ) {
        result = false;
      }
    }

    // Custom fields validation
    const customFieldHasErrors = customFieldsContainsErrors(je.customField);
    if (customFieldHasErrors) {
      return false;
    }
    // Custom fields validation ends

    return result;
  };

  const saveApiCall = (currentJE: any) => {
    if (
      !currentJE.hasOwnProperty('sequenceFormat') &&
      !currentJE.hasOwnProperty('jeDocumentSequenceCode')
    ) {
      currentJE = { ...currentJE, ...selectedNumberFormat };
    }
    JournalService.saveJournal(currentJE)
      .then(
        (res: any) => {
          closeCreateView();
          dispatch(fetchJournal());
        },
        (error: any) => {
          props.passingInteraction({ type: POPUP_CLICK_TYPE.CLOSE_POPUP });
        }
      )
      .catch((error: any) => {
        props.passingInteraction({ type: POPUP_CLICK_TYPE.CLOSE_POPUP });
      });
  };

  const updateApiCall = (currentJE: any) => {
    JournalService.updateJournal(currentJE)
      .then(
        (res) => {
          closeCreateView();
          dispatch(fetchJournal());
        },
        (error: any) => {
          props.passingInteraction({ type: POPUP_CLICK_TYPE.CLOSE_POPUP });
        }
      )
      .catch((error: any) => {
        props.passingInteraction({ type: POPUP_CLICK_TYPE.CLOSE_POPUP });
      });
  };

  const validateAccountsBudget = (doc: any, isUpdate: boolean) => {
    let allAccountsData = [];
    const lineItems = doc.lineItems.filter(
      (item: any) => item.cdType === 'DEBIT'
    );
    for (let i = 0; i < lineItems?.length; i++) {
      const item = lineItems[i];
      const accountCode = item.accountCode;
      const amount = Utility.roundOffToTenantDecimalScale(
        item.amount / (doc.exchangeRate ? doc.exchangeRate : 1)
      );
      allAccountsData.push({
        accountCode,
        amount
      });
    }

    let customField: any = {};
    const allDropDownCustomFields = doc.customField?.filter(
      (field: any) => field.fieldType === 'DROPDOWN' && field.value
    );
    for (let i = 0; i < allDropDownCustomFields?.length; i++) {
      customField = {
        ...customField,
        [allDropDownCustomFields[i].code]: allDropDownCustomFields[i].value
      };
    }

    if (allAccountsData.length) {
      if (props.passingInteraction) {
        props.passingInteraction({
          type: POPUP_CALLBACKS_TYPE.API_CALL_IN_PROGRESS
        });
      }
      validateBudgetForAccounts(
        doc.jeDate,
        allAccountsData,
        customField,
        je?.jeCode ? je?.jeCode : null
      ).then(
        (budgetResp: { data: any[]; limitCrossed: boolean }) => {
          if (budgetResp.limitCrossed) {
            let buttons = [
              {
                title: 'Cancel',
                className: 'bg-gray2 border-m ',
                onClick: () => {
                  if (props.passingInteraction) {
                    props.passingInteraction({
                      type: POPUP_CALLBACKS_TYPE.API_CALL_STOP_PROGRESS
                    });
                  }
                }
              },
              {
                title: 'Proceed',
                className: 'bg-button text-white ml-r',
                onClick: () => {
                  isUpdate ? updateApiCall(doc) : saveApiCall(doc);
                }
              }
            ];
            let message =
              '<ul class="text-align-left" style="list-style-type: disc; margin-left: 5px;">';
            budgetResp.data?.forEach((alertData: any) => {
              message += `<li>${alertData.message}</li>`;
            });
            message +=
              '<div class="text-align-center mt-l">Do you want to continue?</div>';
            message += '</ul>';
            showAlert('Warning!', message, buttons);
          } else {
            isUpdate ? updateApiCall(doc) : saveApiCall(doc);
          }
        },
        (err) => {
          console.error('Error validating accounts budget: ', err);
          if (props.passingInteraction) {
            props.passingInteraction({
              type: POPUP_CALLBACKS_TYPE.API_CALL_STOP_PROGRESS
            });
          }
        }
      );
    }
  };

  const getLineItemCFs = (lineItem: any) => {
    let oldColConfigs = [...columnConfig];
    let colConfigsWithOnlyCF = oldColConfigs.filter(
      (item: any) => item.isCustomField
    );
    let newCfs: any[] = [];
    if (!Utility.isEmpty(accountsCFData)) {
      colConfigsWithOnlyCF.forEach((colConfigItem: any) => {
        const cf: any = accountsCFData.find(
          (cfItem: any) => colConfigItem.id === cfItem.id
        );
        if (typeof cf !== 'undefined' && cf !== null) {
          let cfValue;
          if (cf.fieldType.toLowerCase() === INPUT_TYPE.DATE.toLowerCase()) {
            cfValue = DateFormatService.getDateStrFromDate(
              lineItem[cf.id],
              BOOKS_DATE_FORMAT['MM/DD/YYYY']
            );
          } else if (cf.fieldType.toLowerCase() === 'user') {
            const tempCF = cf?.attributes?.find(
              (attr: any) => attr.code === lineItem[cf.id]?.code
            );
            if (tempCF) {
              cfValue = tempCF.code;
            }
          } else if (
            cf.fieldType.toLowerCase() === INPUT_TYPE.DROPDOWN.toLowerCase()
          ) {
            cfValue = lineItem[cf.id] ? lineItem[cf.id].value : '';
          } else {
            cfValue = lineItem[cf.id] ? lineItem[cf.id] : '';
          }

          newCfs.push({
            id: cf.id,
            code: cf.code,
            label: cf.label,
            module: 'ACCOUNT',
            shortName: cf.shortName,
            value: cfValue
          });
        }
      });
    }

    return newCfs;
  };

  const saveJE = () => {
    if (
      !Utility.checkActiveDateRangeValidation(
        journalDate,
        tenantInfo,
        'Document date',
        'JOURNAL_ENTRY'
      )
    ) {
      return;
    }
    if (!Utility.checkClosingDate(journalDate, 'Journal Date')) {
      return;
    }
    let currentJE = { ...je };
    const conRate = 1 / Number(conversionRate);

    currentJE.jeDate = DateFormatService.getDateStrFromDate(
      journalDate,
      BOOKS_DATE_FORMAT['DD-MM-YYYY']
    );

    currentJE.currency = selectedCurrency?.currencyCode;

    const attachmentIds =
      attachments && attachments.length > 0
        ? attachments.map((attachment: any) => attachment.attachmentId)
        : [];
    currentJE.attachmentIds = attachmentIds;

    currentJE.currencyExchangeRate = conRate;
    currentJE.exchangeRate = conRate;

    currentJE.lineItems &&
      currentJE.lineItems.length > 0 &&
      currentJE.lineItems.map((item: any, index: number) => {
        item.accountCode = item['account']?.code;
        item.cdType = item['type'].toUpperCase();
        item.amount = Number(item.amountInDocumentCurrency);
        item.customField = getLineItemCFs(item);
        item.contactCode = item.contact?.code;
        item.generateCN = GENERATE_CN_DN_LIST.GENERATE_CN === item.generateCNDN;
        item.generateDN = GENERATE_CN_DN_LIST.GENERATE_DN === item.generateCNDN;
      });

    const tDetails = je?.taxAdjustmentDetails?.taxDetails;
    if (
      (!Utility.isEmpty(
        je?.taxAdjustmentDetails?.taxAdjustmentType?.selectedValue
      ) &&
        je?.taxAdjustmentDetails?.taxAdjustmentType?.selectedValue !==
          TAX_ADJUSTMENT_TYPES_INDIA[3].value) ||
      !Utility.isEmpty(
        je?.taxAdjustmentDetails?.taxAdjustmentSubType?.selectedValue
      )
    ) {
      currentJE.journalTaxDetailsIN = {
        taxRate: tDetails?.taxRate,
        taxableAmount: tDetails?.taxableAmount,
        cgstAmount: tDetails?.cgstAmount,
        sgstAmount: tDetails?.sgstAmount,
        igstAmount: tDetails?.igstAmount,
        cessAmount: tDetails?.cessAmount,
        contactCode: tDetails?.contactCode,
        taxAdjustmentType:
          je?.taxAdjustmentDetails?.taxAdjustmentType?.selectedValue,
        taxAdjustmentSubtype:
          je?.taxAdjustmentDetails?.taxAdjustmentSubType?.selectedValue
      };
    }

    if (validateAccounts()) {
      let amt1: any = getTotalAmountFor(CD_TYPE.DEBIT);
      let amt2: any = getTotalAmountFor(CD_TYPE.CREDIT);

      let toFixedVal = Store.getState()?.authInfo?.currentTenantInfo?.data
        ?.decimalScale
        ? Store.getState()?.authInfo?.currentTenantInfo?.data.decimalScale
        : 2;

      if (amt1.toFixed(toFixedVal) - amt2.toFixed(toFixedVal) === 0) {
        if (currentJE.currency) {
          currentJE = {
            ...currentJE,
            lineItems: currentJE?.lineItems?.map((item: any) => {
              delete item.cfs;
              return item;
            })
          };
          validateAccountsBudget(currentJE, props.isCopy);
        }
      } else {
        let buttons = [
          {
            title: 'Ok',
            className: 'bg-app text-white ml-r',
            onClick: () => {}
          }
        ];
        showAlert(
          'Error!',
          'Total Debit amount should match total Credit amount.',
          buttons
        );
      }
    }
  };

  const registerInteractions = () => {
    /*
     * register parents calls to child methods
     */
    if (props.passingInteraction)
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.CREATE_JE,
        data: () => {
          setSubmitButtonTapped(true);
          if (je?.lineItems?.length === 0) {
            addEmptyLineItem();
          }
          saveJE();
        }
      });
  };

  const addEmptyLineItem = () => {
    let newLineItem = {
      contact: '',
      account: '',
      amount: '',
      type: getEmptyRowType(je?.lineItems),
      description: '',
      invalidFields: PartyJEConfigManager.getRequiredFields(),
      lineNumber: je?.lineItems?.length + 1,
      generateCNDN: GENERATE_CN_DN_LIST.NONE
    };

    if (je.lineItems?.length === 1) {
      if (je.lineItems[0]['generateCNDN'] === GENERATE_CN_DN_LIST.GENERATE_DN) {
        newLineItem.generateCNDN = GENERATE_CN_DN_LIST.GENERATE_CN;
      } else if (
        je.lineItems[0]['generateCNDN'] === GENERATE_CN_DN_LIST.GENERATE_CN
      ) {
        newLineItem.generateCNDN = GENERATE_CN_DN_LIST.GENERATE_DN;
      }
    }

    setJE({
      ...je,
      lineItems: [...je.lineItems, newLineItem]
    });
  };

  const getUnbalancedAmount = () => {
    let lhs = getTotalAmountFor(CD_TYPE.DEBIT);
    let rhs = getTotalAmountFor(CD_TYPE.CREDIT);

    const ans = Number(lhs) - Number(rhs);
    return NumberFormatService.getNumber(ans);
  };

  const getTotalAmountFor = (type: any) => {
    if (je.lineItems && je.lineItems.length > 0) {
      let filtered = je.lineItems.filter(
        (item: any) => item?.type?.toUpperCase() === type
      );
      if (filtered.length > 0) {
        let sum: any = filtered.reduce(function (add: any, current: any) {
          if (current.amountInDocumentCurrency) {
            let ans: any =
              parseFloat(add) + parseFloat(current.amountInDocumentCurrency);
            return ans;
          } else {
            return 0;
          }
        }, 0);
        return sum;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  };

  const selectedFormat = (selected: any) => {
    /*
     * Custom Numbering Format
     * RECEIVE Selected format {id: "", text: ""}
     */
    const updatedState: any = {};

    if (selected.manualMode) {
      updatedState.jeDocumentSequenceCode = selected.text;
      updatedState.sequenceFormat = selected.id;
      updatedState.manualMode = selected.manualMode;
      setSelectedNumberFormat({
        jeDocumentSequenceCode: selected.text,
        sequenceFormat: selected.id,
        manualMode: selected.manualMode
      });
    } else if (selected.id) {
      updatedState.sequenceFormat = selected.id;
      updatedState.manualMode = selected.manualMode;
      setSelectedNumberFormat({
        sequenceFormat: selected.id,
        manualMode: selected.manualMode
      });
    } else {
      updatedState.sequenceFormat = null;
      updatedState.manualMode = null;
      updatedState.jeDocumentSequenceCode = null;
    }

    setJE((prevState: any) => {
      return { ...prevState, ...updatedState };
    });
  };

  /////////////////////////attach files - start ////////////////////////////////////////////////
  const triggerAttachmentUpload = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('multiple', 'true');
    input.addEventListener('change', (e) => {
      const target = e.target as HTMLInputElement;
      if (target?.files) {
        Promise.all(
          target?.files &&
          Array.from(target.files).map((file: File) =>
            uploadAttachmentToAWS(file)
          )
        ).then(resList => {
          resList = resList.filter((x: any) => x !== undefined)
          const newAttachments = [...attachments, ...resList];
          setAttachments(newAttachments);
        }).catch((err) => {
          console.log(err);
          showToast(
            'Something went wrong while uploading the attachment, please try again.'
          );
        });
      }
    });
    input.click();
  };

  const uploadAttachmentToAWS = async (file: File) => {
    AttachmentService.attachmentConfig = {
      ...AttachmentService.attachmentConfig,
      Module: DOC_TYPE.JOURNAL_ENTRY
    };
    return AttachmentService.uploadAttachment(file);
  };

  const triggerAttachmentDownload = (
    attachmentId: any,
    attachmentName: string
  ) => {
    AttachmentService.downloadAttachment(attachmentId)
      .then((absolutePath) => {
        triggerDownload(null, attachmentName, absolutePath);
      })
      .catch(() => {});
  };

  const removeAttachment = (attachmentId: any) => {
    AttachmentService.deleteAttachment(attachmentId)
      .then((res) => {
        const newAttachments = attachments.filter(
          (attachment: any) => attachmentId !== attachment.attachmentId
        );
        setAttachments(newAttachments);
      })
      .catch(() => {});
  };

  const getAttachments = () => {
    return (
      <div className="col justify-content-start flex-wrap">
        {attachments.map((attachment: any) => (
          <div
            className="row width-auto border-m border-radius-s p-h-r p-v-s mt-r bg-gray0"
            key={attachment.attachmentId}
          >
            <DKIcon
              src={DKIcons.ic_document}
              className="ic-s cursor-pointer"
              onClick={() => {
                triggerAttachmentDownload(
                  attachment.attachmentId,
                  attachment.attachmentFileName
                );
              }}
            />
            <DKButton
              title={attachment.attachmentFileName}
              className="ml-s cursor-pointer border-none"
              onClick={() => {
                triggerAttachmentDownload(
                  attachment.attachmentId,
                  attachment.attachmentFileName
                );
              }}
            />
            <DKIcon
              src={DKIcons.ic_delete}
              className="ic-s ml-l cursor-pointer"
              onClick={() => {
                removeAttachment(attachment.attachmentId);
              }}
            />
          </div>
        ))}
      </div>
    );
  };
  /////////////////////////attach files - end ////////////////////////////////////////////////

  //////////////////////right panel - start/////////////////////////////////////////////////////////

  const getCalendarView = (
    selectedDate: any,
    onSelect: any,
    toggleView: any
  ) => {
    return (
      <DKCalendar
        className="position-absolute bg-white border-m z-index-3 p-s border-radius-s shadow-m border-box"
        style={{ right: 0, top: 150 }}
        selectedDate={selectedDate}
        onSelectDate={(newDate: Date) => {
          onSelect(newDate);
          toggleView(false);
        }}
        onClose={() => setTimeout(() => toggleView(false))}
      />
    );
  };

  const getRightInfoPanel = () => {
    return (
      <div className="column parent-width align-items-end">
        {/* auto-numbering */}
        <div className="position-relative">
          <div
            className="row width-auto mb-m justify-content-between"
            style={{
              width: 200
            }}
          >
            <div className="row width-auto">
              <DKIcon
                src={DKIcons.data_type.ic_number}
                className="ic-s"
                style={{ opacity: 0.6 }}
              />
              <DKLabel text={'No.'} className={'fw-m ml-r'} />
            </div>
            {isEditMode && (
              <DKLabel
                text={je.jeCode}
                className={'ml-r w-9/12 text-align-right'}
              />
            )}
            {/* currently custom number format is not editable in old books */}
            {!props.isCopy && !isEditMode && (
              <div className="w-9/12 -mr-1">
                {
                  <CustomNumberFormatInput
                    module={CUSTOM_NUMBER_INPUT_MODULES.JOURNAL_ENTRY}
                    selectedFormat={selectedFormat}
                    showCompact={true}
                    extraClass={'top-12 right-0'}
                  />
                }
              </div>
            )}
          </div>
        </div>
        {/* currency */}
        <div
          className="row width-auto mb-m justify-content-between"
          style={{
            width: 200
          }}
        >
          <div
            className="row width-auto cursor-hand"
            onClick={() => {
              setShowMultiCurrencyList(!showMultiCurrencyList);
            }}
          >
            <DKIcon
              src={DKIcons.ic_currency}
              className="ic-s"
              style={{ opacity: 0.6 }}
            />
            <DKLabel text={'Currency'} className={'fw-m ml-r'} />
          </div>
          <div className="position-relative">
            <DKButton
              title={selectedCurrency?.currencyCode}
              className="bg-transparent text-black align-items-start"
              style={{
                paddingLeft: 0,
                paddingRight: 0,
                paddingTop: 0,
                paddingBottom: 0
              }}
              onClick={() => {
                setShowMultiCurrencyList(!showMultiCurrencyList);
              }}
              icon={tenantInfo.multicurrencyEnabled && DKIcons.ic_arrow_down2}
              isReverse
            />
            {tenantInfo.multicurrencyEnabled && showMultiCurrencyList && (
              <DKListPicker2
                title="Currencies"
                data={currencyExchangeRates.filter((item: any) => {
                  return item.currencyStatus === 'ACTIVE';
                })}
                style={{
                  width: 280
                }}
                allowSearch={true}
                searchableKey="currencyName"
                className="position-absolute z-index-3 right-0 shadow-m"
                onSelect={(index: number, value: any) => {
                  updateConfig();
                  setSelectedCurrency(value);
                  setShowMultiCurrencyList(false);
                }}
                onClose={() => {
                  setShowMultiCurrencyList(!showMultiCurrencyList);
                }}
                renderer={(index: number, obj: any) => {
                  return (
                    <div className="row parent-width justify-content-between">
                      <div>{obj.currencyName}</div>
                      <div className="ml-s">{`(${obj.currencyCode})`}</div>
                    </div>
                  );
                }}
              />
            )}
          </div>
        </div>
        {/* currency rate */}
        {tenantInfo.multicurrencyEnabled && (
          <div
            className={'row mb-m justify-content-between'}
            style={{
              width: 200
            }}
          >
            <div className="row">
              <DKIcon
                src={DKIcons.ic_sort}
                style={{
                  transform: 'rotate(90deg)',
                  opacity: 0.6
                }}
              />
              <DKLabel text="1" className="mr-s" />
              <DKLabel text={selectedCurrency?.currencyCode} />
            </div>
            <input
              className="outline-none text-align-right"
              placeholder="0.00"
              value={conversionRate}
              onChange={(e: any) => {
                setConversionRate(e.target.value);
              }}
              style={{
                width: 100
              }}
            />
            <DKLabel text={tenantInfo.currency} className="ml-s" />
          </div>
        )}
        {/* date */}
        <div
          className="row width-auto justify-content-between cursor-pointer"
          style={{
            width: 200
          }}
        >
          <div
            className="row width-auto"
            onClick={() => {
              setShowDatePicker(!showDatePicker);
            }}
          >
            <DKIcon
              src={DKIcons.data_type.ic_date}
              className="ic-s"
              style={{ opacity: 0.6 }}
            />
            <DKLabel text={'Journal Date'} className={'fw-m ml-r'} />
          </div>
          <div
            onClick={() => {
              setShowDatePicker(!showDatePicker);
            }}
          >
            <DKLabel
              text={
                journalDate
                  ? DateFormatService.getDateStrFromDate(journalDate)
                  : ''
              }
              className={'ml-r '}
            />
          </div>
          {showDatePicker &&
            getCalendarView(
              journalDate,
              (date: any) => {
                if (
                  Utility.checkActiveDateRangeValidation(
                    date,
                    tenantInfo,
                    'Document date',
                    'JOURNAL_ENTRY'
                  ) &&
                  Utility.checkClosingDate(date, 'Journal Date')
                ) {
                  setJournalDate(date);
                } else {
                  setJournalDate(new Date());
                }
              },
              () => {
                setShowDatePicker(!showDatePicker);
              }
            )}
        </div>
      </div>
    );
  };
  //////////////////////right panel - end///////////////////////////////////////////////////////////

  ///////////////////////////////// data grid - start ////////////////////////////////////////////////

  const getRemoveInvalidFields = (rowIndex: number, key: any) => {
    const updatedState = { ...je };
    let filtered = updatedState.lineItems[rowIndex].invalidFields;

    if (
      updatedState.lineItems[rowIndex].invalidFields &&
      updatedState.lineItems[rowIndex].invalidFields.length > 0
    ) {
      filtered = updatedState.lineItems[rowIndex].invalidFields.filter(
        (item: any) => item !== key
      );
    }

    return filtered;
  };
  const checkForDpl = (contact: any) => {
    const DplCustomFieldId = dimensionData?.content?.filter(
      (customField: any) =>
        customField?.system &&
        customField?.shortName === 'denied_parties_custom_field'
    )?.[0]?.id;
    const dplContactCustomField = contact?.customField?.filter(
      (cField: any) => cField?.id == DplCustomFieldId
    )?.[0];
    if (!dplContactCustomField) return;
    if (dplContactCustomField?.value === 'Yes') {
      showToast(
        'The contact you are using is under denied party list',
        TOAST_TYPE.SUCCESS
      );
    }
  };
  const updateLineItemList = async (
    updates: any,
    rowIndex: any,
    columnKey: any
  ) => {
    const updatedState = { ...je };
    let oldColConfigs = [...columnConfig];

    if (columnKey === 'contact') {
      let contact = updates?.contact;
      if (
        !Utility.isEmpty(contact) &&
        Utility.isUSorg() &&
        Utility.isDPLSettingEnabled()
      ) {
        checkForDpl(contact);
      }
    }

    if (columnKey === 'amountInDocumentCurrency') {
      let toFixedVal = Store.getState()?.authInfo?.currentTenantInfo?.data
        ?.decimalScale
        ? Store.getState()?.authInfo?.currentTenantInfo?.data.decimalScale
        : 2;
      updatedState.lineItems[rowIndex][columnKey] = Number(
        updates[columnKey]
      )?.toFixed(toFixedVal);
    } else if (
      columnKey === 'amountInDocumentCurrency' ||
      columnKey === 'account' ||
      columnKey === 'description' ||
      columnKey === 'type' ||
      columnKey === 'contact'
    ) {
      updatedState.lineItems[rowIndex][columnKey] = updates[columnKey];
    } else if (columnKey === 'generateCNDN') {
      updatedState.lineItems[rowIndex][columnKey] = updates[columnKey];

      if (updatedState.lineItems?.length === 2) {
        if (updates[columnKey] === GENERATE_CN_DN_LIST.NONE) {
          updatedState.lineItems[rowIndex == 0 ? 1 : 0][columnKey] =
            updates[columnKey];
        } else if (updates[columnKey] === GENERATE_CN_DN_LIST.GENERATE_CN) {
          updatedState.lineItems[rowIndex == 0 ? 1 : 0][columnKey] =
            GENERATE_CN_DN_LIST.GENERATE_DN;
        } else {
          updatedState.lineItems[rowIndex == 0 ? 1 : 0][columnKey] =
            GENERATE_CN_DN_LIST.GENERATE_CN;
        }
      }
    } else {
      //update column values for custom fields directly here
      const configForColumnKey = columnConfig.find(
        (config: any) => config.key === columnKey
      );
      if (configForColumnKey.isCustomField) {
        const cfInfo: any = customFieldsData.find(
          (cf: any) => cf.id === columnKey
        );
        if (cfInfo) {
          if (
            cfInfo.fieldType.toLowerCase() === INPUT_TYPE.DATE.toLowerCase()
          ) {
            updatedState.lineItems[rowIndex][columnKey] = new Date(
              updates[columnKey]
            );
          } else if (
            cfInfo.fieldType.toLowerCase() ===
            CUSTOM_FIELD_TYPE.USER.toLowerCase()
          ) {
            // Use updates[columnKey].value for User or Dropdown type
            updatedState.lineItems[rowIndex][columnKey] = updates[columnKey];
          } else if (
            cfInfo.fieldType.toLowerCase() ===
            CUSTOM_FIELD_TYPE.DROPDOWN.toLowerCase()
          ) {
            // Use updates[columnKey].value for User or Dropdown type
            updatedState.lineItems[rowIndex][columnKey] =
              updates[columnKey].value === 'None' ? null : updates[columnKey];
            cfUpdatedTimeMap.current = {
              ...cfUpdatedTimeMap.current,
              [cfInfo.id]: new Date().getTime()
            };
            const availableAccountCFs: any[] = accountsCFData;
            const { rowData } = updateRowDataWithParentCFValues(
              updates[columnKey].value === 'None' ? null : updates[columnKey],
              { ...updatedState.lineItems[rowIndex] },
              cfInfo,
              availableAccountCFs
            );
            updatedState.lineItems[rowIndex] = rowData;
          } else {
            updatedState.lineItems[rowIndex][columnKey] = updates[columnKey];
          }
        }
      }
    }

    const onAccountUpdate = () => {
      // invalid fields
      if (updatedState.lineItems[rowIndex]['account']) {
        updatedState.lineItems[rowIndex]['description'] =
          updatedState.lineItems[rowIndex]['account'].description;
        updatedState.lineItems[rowIndex].invalidFields = getRemoveInvalidFields(
          rowIndex,
          'account'
        );
      }

      //custom fields
      if (updates.account?.customField?.length > 0) {
        // Handle custom fields when account is selected
        const availableCFs: any[] = accountsCFData;
        let cfs: any[] = [];
        availableCFs?.forEach((availableCF: any) => {
          let cfToUpdate = {
            id: availableCF.id,
            shortName: availableCF.shortName,
            module: MODULES_NAME.ACCOUNT,
            code: availableCF.code,
            label: availableCF.label,
            value: ''
          };
          let valueOfCF = '';
          const existingCF = updates.account?.customField?.find(
            (cf: any) => cf.id === availableCF.id
          );
          if (existingCF) {
            if (
              typeof existingCF.value !== 'undefined' &&
              existingCF.value !== null &&
              existingCF.value !== ''
            ) {
              if (
                availableCF.fieldType.toLowerCase() ===
                INPUT_TYPE.DATE.toLowerCase()
              ) {
                updatedState.lineItems[rowIndex][availableCF.id] =
                  DateFormatService.getDateFromStr(
                    existingCF.value,
                    BOOKS_DATE_FORMAT['MM/DD/YYYY']
                  );
              } else if (
                availableCF.fieldType.toLowerCase() ===
                CUSTOM_FIELD_TYPE.USER.toLowerCase()
              ) {
                const cfValue = availableCF?.attributes?.find(
                  (attr: any) => attr.code === existingCF.value
                );
                updatedState.lineItems[rowIndex][availableCF.id] = cfValue
                  ? cfValue
                  : '';
              } else if (
                availableCF.fieldType.toLowerCase() ===
                CUSTOM_FIELD_TYPE.DROPDOWN.toLowerCase()
              ) {
                const cfValue = availableCF?.attributes?.find(
                  (attr: any) => attr.value === existingCF.value
                );
                updatedState.lineItems[rowIndex][availableCF.id] = cfValue
                  ? cfValue
                  : '';
              } else {
                updatedState.lineItems[rowIndex][availableCF.id] =
                  existingCF.value;
              }
            } else {
              updatedState.lineItems[rowIndex][availableCF.id] = '';
            }
            valueOfCF = existingCF.value;
          } else {
            updatedState.lineItems[rowIndex][availableCF.id] = '';
          }
          cfToUpdate.value = valueOfCF;
          cfs.push(cfToUpdate);
        });
        updatedState.lineItems[rowIndex] = {
          ...updatedState.lineItems[rowIndex],
          customField: cfs
        };
      } else {
        //this is when custom fields are empty and same account is changed
        let allStaticKeys = [
          'amountInDocumentCurrency',
          'account',
          'type',
          'description'
        ];
        const filtered = oldColConfigs?.filter((item1: any) => {
          return !allStaticKeys.includes(item1.key);
        });
        filtered?.forEach((item2: any) => {
          updatedState.lineItems[rowIndex][item2.name] = '';
        });
      }
    };

    const onContactUpdate = async () => {
      if (updatedState.lineItems[rowIndex].contact) {
        updatedState.lineItems[rowIndex].invalidFields = getRemoveInvalidFields(
          rowIndex,
          'contact'
        );
        let accountCode: string = '';
        if (updatedState.lineItems[rowIndex].type === CD_TYPE.CREDIT) {
          accountCode =
            updatedState.lineItems[rowIndex].contact.payableAccountCode;
        } else if (updatedState.lineItems[rowIndex].type === CD_TYPE.DEBIT) {
          accountCode =
            updatedState.lineItems[rowIndex].contact.receivableAccountCode;
        }
        if (accountCode) {
          let account = accountsData?.content?.find(
            (account: any) => account.code === accountCode
          );
          if (account) {
            updatedState.lineItems[rowIndex].account = account;
            onAccountUpdate();
          } else {
            account = await AccountsService.getAccountbyCode(accountCode);
            if (account) {
              updatedState.lineItems[rowIndex].account = account;
              onAccountUpdate();
            }
          }
        }
      }
    };

    if (columnKey === 'account') {
      onAccountUpdate();
    } else if (columnKey === 'contact') {
      console.log(updatedState);
      await onContactUpdate();
    } else if (columnKey === 'type') {
      updatedState.lineItems[rowIndex].invalidFields = getRemoveInvalidFields(
        rowIndex,
        'type'
      );
      await onContactUpdate();
    } else if (columnKey === 'amountInDocumentCurrency') {
      updatedState.lineItems[rowIndex].invalidFields = getRemoveInvalidFields(
        rowIndex,
        'amountInDocumentCurrency'
      );
    }

    setJE({ ...updatedState });
    setColumnConfig([...oldColConfigs]);
  };

  const onRowUpdate = ({ columnKey, rowData, rowIndex }: any) => {
    updateLineItemList(rowData, rowIndex, columnKey);
  };

  const onDelete = ({ rowIndex }: any) => {
    const updatedState = { ...je };
    updatedState.lineItems.splice(rowIndex, 1);

    setJE({
      ...updatedState
    });
  };

  const hideClassColumn = () => {
    let hideClassCol = false;
    const classSettings = tenantInfo.additionalSettings?.CLASS;
    if (
      !classSettings?.trackClasses ||
      classSettings?.assignClasses === CLASS_ASSIGNMENT.TRANSACTION
    ) {
      hideClassCol = true;
    }
    return hideClassCol;
  };

  const getAddClassForm = () => (
    <AddClass
      data={null}
      onSuccess={() => {
        dispatch(fetchCategoryDimensions());
        dispatch(fetchClassesByDimensionId());
      }}
      onCancel={() => {
        setShowAddClassPopup(false);
      }}
    />
  );

  const getAccountsCFData = async () => {
    try {
      const cfData = await CustomFieldService.getCustomFields({
        status: 'ACTIVE',
        limit: '1000',
        module: 'ACCOUNT'
      });
      let sortedCF = cfData?.content?.length ? cfData?.content : [];
      sortedCF.sort(
        (field1: any, field2: any) =>
          field1.customFieldIndex - field2.customFieldIndex
      );
      setAccountsCFData(sortedCF);
      return sortedCF;
    } catch (err: any) {
      console.log('Error while fetching account CFs: ', err);
    }
  };

  const updateLineLevelCForder = (updatedCFs: any[]) => {
    let accountCFs: any[] = updatedCFs;
    accountCFs.sort(
      (field1: any, field2: any) =>
        field1.customFieldIndex - field2.customFieldIndex
    );

    const existingCFConfigs = columnConfig.filter(
      (config: any) => config.isCustomField
    );
    const existingConfigsWithoutCFs = columnConfig.filter(
      (config: any) => !!!config.isCustomField
    );
    let updatedCFConfigs: any[] = [];
    accountCFs.forEach((accCF: any) => {
      const existingCFConfig = existingCFConfigs.find(
        (cf: any) => cf.id === accCF.id
      );
      if (existingCFConfig) {
        updatedCFConfigs.push({
          ...existingCFConfig
        });
      }
    });

    const classData = systemDimensions?.content?.find(
      (data: any) => data.label === LOCATION_CLASS_ENUM.CLASS
    );
    updatedCFConfigs = updatedCFConfigs.map((cf: any) => {
      if (cf?.id === classData?.id) {
        cf = {
          ...cf,
          hidden: hideClassColumn()
        };
      }
      return cf;
    });
    setColumnConfig(
      [...existingConfigsWithoutCFs, ...updatedCFConfigs].filter(
        (col: any) => !col.hidden
      )
    );
  };

  const getAccountCustomFieldSettings = () => {
    const accountCFs: any[] = accountsCFData;
    return (
      <CustomFieldSettings
        fields={accountCFs}
        moduleName={MODULES_NAME.ACCOUNT}
        onSave={async () => {
          try {
            const updatedOrderedCFs = await getAccountsCFData();
            setOpenAccountCFSettings(false);
            updateLineLevelCForder(updatedOrderedCFs);
          } catch (err: any) {
            console.error('Error fetching product CFs: ', err);
          }
        }}
        onClose={() => setOpenAccountCFSettings(false)}
      />
    );
  };

  const updateConfig = () => {
    let config = columnConfig;

    config.forEach((conf: any) => {
      switch (conf.key) {
        case 'account':
          conf.formatter = (obj: any) => {
            return obj?.value?.name;
          };
          let newData = Utility.getAccountsStructured(accountsData?.content);
          ConfigUtility.fillStructuredAccountArray(newData);
          let newStructuredData = ConfigUtility.structuredAccountsArr;
          conf.dropdownConfig.data = newStructuredData;
          conf.dropdownConfig.renderer = (index: any, obj: any) => {
            return ConfigUtility.getAccountRow(obj);
          };
          conf.dropdownConfig.button =
            GranularPermissionsHelper.hasPermissionFor(
              PERMISSIONS_BY_MODULE.COA.CREATE
            )
              ? {
                  title: '+ Add New',
                  className: 'bg-button text-white',
                  onClick: () => {}
                }
              : null;
          conf.dropdownConfig.searchApiConfig.getUrl = (search: any) => {
            const config: AccountAPIConfig = {
              ...AccountsService.apiConfig,
              SearchTerm: search,
              Query: 'status=active',
              Page: 0,
              Limit: 10
            };
            AccountsService.apiConfig = config;
            return ApiConstants.URL.BASE + AccountsService.getAccountEndPoint();
          };
          conf.dropdownConfig.onSelect = (response: any) => {};
          if (conf.dropdownConfig.button) {
            conf.dropdownConfig.button.onClick = () => {
              setShowAccountPopup(true);
            };
          }

          break;
        case 'contact':
          conf.formatter = (obj: any) => {
            return obj?.value?.name;
          };
          const contactList =
            contactsList?.content?.filter(
              (contact: any) =>
                contact.status.toLowerCase() === STATUS_TYPE.active
            ) || null;
          conf.dropdownConfig.data = contactList;
          conf.dropdownConfig.renderer = (index: any, obj: any) => {
            return <DKLabel text={`${obj.name}`} />;
          };
          conf.dropdownConfig.button =
            GranularPermissionsHelper.hasPermissionFor(
              PERMISSIONS_BY_MODULE.CONTACTS.CREATE
            )
              ? {
                  title: '+ Add Contact',
                  className: 'bg-button text-white',
                  onClick: () => {
                    setShowAddContactPopup(true);
                  }
                }
              : null;
          conf.dropdownConfig.searchApiConfig.getUrl = (
            searchValue: string
          ) => {
            const config: ContactAPIConfig = {
              ...ContactService.apiConfig,
              Page: 0,
              SearchTerm: searchValue,
              Limit: 20,
              IncludeOpeningAmounts: false,
              IncludeOweAmounts: false,
              Query: 'status=active'
            };
            ContactService.apiConfig = config;
            return ContactService.getContactsApiUrl();
          };
          conf.dropdownConfig.searchApiConfig.dataParser = (response: any) => {
            return response?.content || [];
          };
          conf.dropdownConfig.onSelect = (response: any) => {};

          if (conf.dropdownConfig.button) {
            conf.dropdownConfig.button.onClick = () => {
              setShowAddContactPopup(true);
            };
          }
          break;
        case 'type':
          conf.dropdownConfig.data = cdTypesArray;
          conf.dropdownConfig.renderer = (index: any, name: any) => {
            return name;
          };
          conf.dropdownConfig.onSelect = (response: any) => {};
          break;

        case 'amountInDocumentCurrency':
          conf.formatter = (data: any) => {
            const amount = data.value;
            return `${Utility.getCurrencySymbolFromCode(
              selectedCurrency?.currencyCode
            )} ${amount < 0 ? '(' : ''}${NumberFormatService.getNumber(
              Math.abs(amount)
            )}${amount < 0 ? ')' : ''}`;
          };
          break;
        case 'generateCNDN':
          conf.dropdownConfig.data = [
            GENERATE_CN_DN_LIST.NONE,
            GENERATE_CN_DN_LIST.GENERATE_CN,
            GENERATE_CN_DN_LIST.GENERATE_DN
          ];
          break;
        default:
          const classData = systemDimensions?.content?.find(
            (data: any) => data.label === LOCATION_CLASS_ENUM.CLASS
          );
          if (classData && classData.id === conf.id) {
            conf.hidden = hideClassColumn();
            conf.dropdownConfig.button = {
              title: '+ Add New',
              className: 'bg-button text-white',
              onClick: () => setShowAddClassPopup(true)
            };
          }
          if (conf.type === INPUT_TYPE.DROPDOWN) {
            conf.dropdownConfig.allowSearch =
              conf.dropdownConfig?.data?.length > 5;
            conf.dropdownConfig.searchableKey = 'value';
          }
          break;
      }
    });
  };

  const onRowClick = ({ columnData, rowData, rowIndex }: any) => {
    setSelectedIndex(rowIndex);
    const availableCFs: any[] = accountsCFData;
    updateColumnConfigOnRowClick(
      columnData,
      rowData,
      columnConfig,
      availableCFs,
      cfUpdatedTimeMap.current
    );
  };

  const AddCopyofRow = (data: any) => {
    const { rowData, rowIndex } = data;
    let tempProductRows = [...je?.lineItems];
    let copiedRow: any = { ...tempProductRows[rowIndex] };
    copiedRow.id = null;
    tempProductRows.splice(rowIndex + 1, 0, copiedRow);
    tempProductRows.forEach((item, index) => {
      if (item) {
        item.lineNumber = index + 1;
      }
    });

    let tempJe = { ...je };
    tempJe.lineItems = tempProductRows;
    setJE(tempJe);
  };

  const getRowData = (lineItems: any[]) => {
    let contextMenu: any[] = [];
    contextMenu.push({
      title: 'Arrange Custom Fields',
      icon: DKIcons.ic_settings,
      className: ' p-0',
      onClick: (data: any) => setOpenAccountCFSettings(true)
    });

    contextMenu.push({
      title: 'Copy',
      icon: DKIcons.ic_copy,
      className: ' p-0',
      onClick: (data: any, index: any) => AddCopyofRow(data)
    });

    let rowData: any[] = [];
    if (lineItems?.length) {
      rowData = [...lineItems].map((item: any) => {
        return {
          ...item,
          rowContextMenu: contextMenu
        };
      });
    }

    return rowData;
  };

  const getDataGrid = () => {
    return (
      <DKDataGrid
        needShadow={false}
        needColumnIcons={false}
        needBorder={true}
        needTrailingColumn={true}
        allowBulkOperation={false}
        allowColumnSort={false}
        allowColumnShift={false}
        filterData={[]}
        allowColumnDelete={false}
        allowRowEdit={!isReadOnlyMode}
        allowColumnEdit={false}
        allowFilter={false}
        allowColumnAdd={false}
        allowBottomRowAdd={false}
        allowSearch={false}
        allowShare={false}
        rows={getRowData(je?.lineItems)}
        columns={[
          ...columnConfig,
          {
            id: 'action',
            key: 'action',
            name: '',
            type: INPUT_TYPE.BUTTON,
            width: 150,
            options: isReadOnlyMode
              ? []
              : [
                  {
                    icon: DKIcons.ic_delete,
                    className: ' p-0',
                    onClick: onDelete
                  }
                ]
          }
        ]}
        onRowUpdate={onRowUpdate}
        onRowClick={onRowClick}
        width={gridWidth}
      />
    );
  };
  //////////////////////////////// data grid - end ///////////////////////////////////////////////////

  /////////////////////////////// add account popup - start /////////////////////////////////////////
  const getAddAccountPopup = () => {
    return showAccountPopup ? (
      <AddCoaPopup
        populateFormData={null}
        onCancel={() => {
          setShowAccountPopup(false);
        }}
      />
    ) : null;
  };
  /////////////////////////////// add account popup - end ///////////////////////////////////////////

  ////////////////////////////// tax adjustment view - start ///////////////////////////////////////

  const catchClicks = (data: PopupClickActionType) => {
    switch (data.type) {
      case POPUP_CLICK_TYPE.CLOSE_POPUP:
        showAddContactPopup && setShowAddContactPopup(false);
        break;
      case POPUP_CLICK_TYPE.CREATE_CONTACT:
        partyJEFormRef.current?.storeCallbacksRef.copyContact();
        break;
    }
  };

  const parentChildInteraction = (passingData: CallBackPayloadType) => {
    switch (passingData.type) {
      case POPUP_CALLBACKS_TYPE.CREATE_CONTACT:
        partyJEFormRef.current.storeCallbacksRef.copyContact = passingData.data;
        break;
      case POPUP_CALLBACKS_TYPE.CLOSE_POPUP:
        showAddContactPopup && setShowAddContactPopup(false);
        break;
      case POPUP_CALLBACKS_TYPE.CREATE_CONTACT_SUCCESS:
        setShowAddContactPopup(false);
        setDetailedContact(passingData.data.id);
        break;
    }
  };

  const getAddContactPopup = () => {
    return showAddContactPopup ? (
      <PopupWrapper
        clickAction={catchClicks}
        type={POPUP_TYPE.POPUP}
        title={'Create Contact'}
        btnList={[
          {
            title: t(`DOCUMENT.BUTTON.CANCEL`),
            class: 'border-m mr-s',
            clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
          },
          {
            title: `Create`,
            class: 'bg-button text-white mr-ss',
            clickAction: POPUP_CLICK_TYPE.CREATE_CONTACT
          }
        ]}
        disableClickOutside={true}
        width={!isDesktop ? '95%' : '40%'}
        minWidth={!isDesktop ? '' : '550px'}
        height={'95%'}
      >
        <AddContact
          contactMode={DOCUMENT_MODE.NEW}
          populateFormData={null}
          passingInteraction={(callback: CallBackPayloadType) => {
            parentChildInteraction(callback);
          }}
        />
      </PopupWrapper>
    ) : null;
  };

  const setDetailedContact = async (id: number) => {
    try {
      const detailedContact = await ContactService.getContactDetailsById(id);
      var newJE = { ...je };
      if (newJE.lineItems) {
        if (newJE.lineItems.length > selectedIndex) {
          newJE.lineItems[selectedIndex].contact = detailedContact;
          newJE.lineItems[selectedIndex].invalidFields = getRemoveInvalidFields(
            selectedIndex,
            'contact'
          );
          let accountCode: string = '';
          if (newJE.lineItems[selectedIndex].type === CD_TYPE.CREDIT) {
            accountCode =
              newJE.lineItems[selectedIndex].contact.payableAccountCode;
          } else if (newJE.lineItems[selectedIndex].type === CD_TYPE.DEBIT) {
            accountCode =
              newJE.lineItems[selectedIndex].contact.receivableAccountCode;
          }
          if (accountCode) {
            let account = accountsData?.content?.find(
              (account: any) => account.code === accountCode
            );
            if (account) {
              newJE.lineItems[selectedIndex].account = account;
              if (newJE.lineItems[selectedIndex]['account']) {
                newJE.lineItems[selectedIndex].invalidFields =
                  getRemoveInvalidFields(selectedIndex, 'account');
              }
              //description
              newJE.lineItems[selectedIndex]['description'] =
                account?.description;
            } else {
              account = await AccountsService.getAccountbyCode(accountCode);
              if (account) {
                newJE.lineItems[selectedIndex].account = account;
                if (newJE.lineItems[selectedIndex]['account']) {
                  newJE.lineItems[selectedIndex].invalidFields =
                    getRemoveInvalidFields(selectedIndex, 'account');
                }
                //description
                newJE.lineItems[selectedIndex]['description'] =
                  account?.description;
              }
            }
          }
          setJE(newJE);
        }
      }
    } catch (err) {
      console.error('Error loading detailed contact: ', err);
    }
  };

  const intercompanyTxnCheckbox = (
    <div className="w-full mt-3 mb-2">
      <DKCheckMark
        color="bg-button"
        isSelected={je.interCompany}
        onClick={() => {
          setJE((value: any) => ({
            ...value,
            interCompany: !value.interCompany
          }));
        }}
        className="text-black z-index-1"
        title={`Is Intercompany Transaction`}
      />
    </div>
  );

  return (
    <div className="column parent-width p-l">
      <div className="row align-items-start">
        <div>
          {!!tenantInfo.additionalSettings?.MULTI_COMPANY &&
            intercompanyTxnCheckbox}
          {getCustomFieldView()}
        </div>
        {getRightInfoPanel()}
      </div>
      <div ref={gridContainerRef} className="column parent-width mt-m">
        {getDataGrid()}
        {getAddAccountPopup()}
        {getAddContactPopup()}
        {!isReadOnlyMode && je.lineItems?.length < 2 && (
          <DKButton
            title={`+ ${t('DOCUMENT.ADD_ITEM')}`}
            onClick={() => {
              addEmptyLineItem();
            }}
            className={`fw-m ${
              je?.lineItems?.length === 0 && submitButtonTapped
                ? 'text-red'
                : 'text-blue'
            } `}
            style={{ zIndex: 1 }}
          />
        )}
      </div>
      <div
        className="row parent-width justify-content-between px-2 py-5 align-items-start"
        style={{ gap: 20, borderTop: '1px dashed #cacaca' }}
      >
        {/* memo */}
        <textarea
          className="resize-none p-2 border rounded outline-none border-gray-200 hover:border-gray-300 focus:border-gray-400"
          style={{
            width: 800,
            height: 100,
            backgroundColor: 'rgb(250, 250, 250)',
            border: '1px dashed rgb(200, 200, 200)'
          }}
          placeholder={'Memo'}
          onChange={(e) => {
            setJE({ ...je, memo: e.target.value });
          }}
          value={je.memo}
        />
        {/* summarry */}
        <div className="column w-3/6">
          {/* debit amount */}
          <div className="row justify-content-between ">
            <DKLabel
              style={{ textAlign: 'right' }}
              className="text-right parent-width fw-m"
              text="Debit Amount"
            />
            <DKLabel
              style={{ textAlign: 'right' }}
              className="text-right parent-width"
              text={`${Utility.getCurrencySymbolFromCode(
                selectedCurrency?.currencyCode
              )} ${NumberFormatService.getNumber(
                getTotalAmountFor(CD_TYPE.DEBIT)
              )}`}
            />
          </div>
          {/* credit amount */}
          <div className="row justify-content-between">
            <DKLabel
              style={{ textAlign: 'right' }}
              className="text-right parent-width fw-m"
              text="Credit Amount"
            />
            <DKLabel
              style={{ textAlign: 'right' }}
              className="text-right parent-width"
              text={`${Utility.getCurrencySymbolFromCode(
                selectedCurrency?.currencyCode
              )} ${NumberFormatService.getNumber(
                getTotalAmountFor(CD_TYPE.CREDIT)
              )}`}
            />
          </div>
          {/* unbalanced amount */}
          <div className="row justify-content-between">
            <DKLabel
              style={{ textAlign: 'right' }}
              className="text-right parent-width fw-m"
              text="Unbalanced Amount"
            />
            <DKLabel
              style={{ textAlign: 'right' }}
              className="text-right parent-width"
              text={`${Utility.getCurrencySymbolFromCode(
                selectedCurrency?.currencyCode
              )} ${getUnbalancedAmount()}`}
            />
          </div>
        </div>
      </div>
      {!isReadOnlyMode && (
        <DKButton
          title="+ Attach Files"
          icon={DKIcons.ic_attachment}
          className="text-blue fw-m"
          onClick={triggerAttachmentUpload}
        />
      )}
      <div className="row ">{getAttachments()}</div>
      {showAddClassPopup && getAddClassForm()}
      {openAccountCFSettings && getAccountCustomFieldSettings()}
    </div>
  );
}
